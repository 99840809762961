import dayjs from "dayjs";
import dayjsLocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import dayjsUTC from "dayjs/plugin/utc";

// TypeScript has difficulty inferring the extension of dayjs in entry point files,
// so we do it in one place here
dayjs.extend(dayjsRelativeTime);
dayjs.extend(dayjsLocalizedFormat);
dayjs.extend(dayjsUTC);

export default dayjs;
