import { __rest } from '../../node_modules/tslib/tslib.es6.js';
import { jsx } from 'react/jsx-runtime';
import React__default, { useState, useEffect } from 'react';
import Editor from '@monaco-editor/react';
export { DiffEditor, loader, useMonaco } from '@monaco-editor/react';
import rgbHex from '../../node_modules/rgb-hex/index.js';
import { size, fontFamily, space } from '../constants.js';
import { useColorScheme } from '../hooks/useColorScheme.js';
import { palette } from '../../packages/tokens/dist/palette.js';

/**
 * Please note that the `@monaco-editor/react` package automatically disposes of the editor model as well as the editor itself.
 * https://github.com/suren-atoyan/monaco-react/blob/master/src/Editor/Editor.js#L196
 */

const MonacoEditor = props => {
  const editorRef = React__default.useRef();
  const instanceRef = React__default.useRef();
  const [isMounted, setMounted] = useState(false);
  const {
    colorScheme,
    ref: colorSchemeRef
  } = useColorScheme();

  const {
    onMount = () => null,
    beforeMount = () => null,
    options = {},
    theme = defaultTheme
  } = props,
        restOfProps = __rest(props, ["onMount", "beforeMount", "options", "theme"]);

  const handleOnMount = (editorInstance, monaco) => {
    editorRef.current = editorInstance;
    colorSchemeRef(editorInstance.getDomNode());
    /**
     * We have noticed that on some rare occasions, the theme might not be applied correctly.
     * This call ensures that the theme is applied.
     */

    editorInstance.updateOptions({
      theme
    });
    setMounted(true);
    onMount(editorInstance, monaco);
  };

  const handleBeforeMount = monaco => {
    instanceRef.current = monaco;
    beforeMount(monaco);
  }; // Update theme on color scheme availability or change


  useEffect(() => {
    if (!instanceRef.current || !isMounted || !colorScheme || !editorRef.current) return;

    if (theme === defaultTheme) {
      instanceRef.current.editor.defineTheme(theme, buildMonacoTheme(colorScheme, editorRef.current.getDomNode()));
    }

    instanceRef.current.editor.setTheme(theme);
  }, [theme, isMounted, colorScheme, editorRef, colorSchemeRef]);
  return jsx(Editor, Object.assign({}, restOfProps, {
    beforeMount: handleBeforeMount,
    onMount: handleOnMount,
    options: Object.assign(Object.assign({}, monacoBaseOptions), options),
    theme: theme
  }));
};
const buildMonacoTheme = (colorScheme, editorElement) => {
  const isDarkMode = colorScheme === "dark";
  const computedStyle = window.getComputedStyle(editorElement);

  const colorVarToHex = (value, includeHash) => {
    const propertyValue = computedStyle.getPropertyValue(value); // Some properties already resolve to a hex value

    const hexValue = propertyValue.startsWith("#") ? propertyValue : `#${rgbHex(propertyValue)}`;
    let hashlessValue = hexValue.substring(1); // convert fff to ffffff

    if (hashlessValue.length === 3) {
      hashlessValue += hashlessValue;
    }

    if (hashlessValue.length === 4) {
      /// 4 digit hex values need to be duplicated like this:
      // RGBA -> RRGGBBAA
      hashlessValue = hashlessValue[0] + hashlessValue[0] + hashlessValue[1] + hashlessValue[1] + hashlessValue[2] + hashlessValue[2] + hashlessValue[3] + hashlessValue[3];
    }

    return includeHash ? `#${hashlessValue}` : hashlessValue;
  };

  return {
    base: isDarkMode ? "vs-dark" : "vs",
    inherit: true,
    rules: [{
      foreground: colorVarToHex("--gray13"),
      token: ""
    }, {
      foreground: colorVarToHex("--gray10"),
      token: "comment"
    }, {
      foreground: colorVarToHex("--gray10"),
      token: "string"
    }, {
      foreground: colorVarToHex("--green10"),
      token: "number"
    }, {
      foreground: colorVarToHex("--magenta10"),
      token: "constant.numeric"
    }, {
      foreground: colorVarToHex("--blue10"),
      token: "constant.language"
    }, {
      foreground: colorVarToHex("--green10"),
      token: "constant.character"
    }, {
      foreground: colorVarToHex("--magenta10"),
      token: "constant.other"
    }, {
      foreground: colorVarToHex("--blue10"),
      token: "keyword"
    }, {
      foreground: colorVarToHex("--red10"),
      token: "storage"
    }, {
      foreground: colorVarToHex("--blue8"),
      fontStyle: "italic",
      token: "storage.type"
    }, {
      foreground: colorVarToHex("--green10"),
      fontStyle: "underline",
      token: "entity.name.class"
    }, {
      foreground: colorVarToHex("--green10"),
      fontStyle: "italic underline",
      token: "entity.other.inherited-class"
    }, {
      foreground: colorVarToHex("--green10"),
      token: "entity.name.function"
    }, {
      foreground: colorVarToHex("--orange10"),
      fontStyle: "italic",
      token: "variable.parameter"
    }, {
      foreground: colorVarToHex("--red10"),
      token: "entity.name.tag"
    }, {
      foreground: colorVarToHex("--green10"),
      token: "entity.other.attribute-name"
    }, {
      foreground: colorVarToHex("--blue10"),
      token: "support.function"
    }, {
      foreground: colorVarToHex("--blue10"),
      token: "support.constant"
    }, {
      foreground: colorVarToHex("--blue10"),
      fontStyle: "italic",
      token: "support.type"
    }, {
      foreground: colorVarToHex("--blue10"),
      fontStyle: "italic",
      token: "support.class"
    }, {
      foreground: colorVarToHex("--gray13"),
      background: colorVarToHex("--red10"),
      token: "invalid"
    }, {
      foreground: colorVarToHex("--gray13"),
      background: colorVarToHex("--magenta10"),
      token: "invalid.deprecated"
    }, {
      foreground: colorVarToHex("--gray10"),
      token: "meta.structure.dictionary.json string.quoted.double.json"
    }, {
      foreground: colorVarToHex("--gray7"),
      token: "meta.diff"
    }, {
      foreground: colorVarToHex("--gray7"),
      token: "meta.diff.header"
    }, {
      foreground: colorVarToHex("--red10"),
      token: "markup.deleted"
    }, {
      foreground: colorVarToHex("--green10"),
      token: "markup.inserted"
    }, {
      foreground: colorVarToHex("--yellow10"),
      token: "markup.changed"
    }, {
      foreground: colorVarToHex("--magenta8"),
      token: "constant.numeric.line-number.find-in-files - match"
    }, {
      foreground: colorVarToHex("--yellow10"),
      token: "entity.name.filename.find-in-files"
    }, {
      token: "x12-delimiter",
      foreground: isDarkMode ? "#666666" : "#aaaaaa"
    }, {
      token: "x12-segment",
      foreground: isDarkMode ? "#46c69a" : "#32a17b",
      fontStyle: "bold"
    },
    /**
     * Even though JSONata is used exclusively in Mappings Editor,
     * we ended up adding those tokens to the "global" theme due to the fact
     * that we're displaying more than one Monaco editor at once.
     * What ended up happening is that the "newest" theme was overriding JSONata specific one
     * Context: https://github.com/Stedi/platform-terminal/pull/4924
     */
    {
      token: "jsonata-string",
      foreground: colorVarToHex("--green10")
    }, {
      token: "jsonata-comment",
      foreground: colorVarToHex("--gray10")
    }, {
      token: "jsonata-variable",
      foreground: colorVarToHex("--red10")
    }, {
      token: "jsonata-names",
      foreground: colorVarToHex("--blue10")
    }, // JSON tokens
    {
      token: "string.value.json",
      foreground: colorVarToHex("--magenta10")
    }, {
      token: "string.key.json",
      foreground: colorVarToHex("--blue10")
    }, {
      token: "delimiter.bracket.json",
      foreground: colorVarToHex("--gray13")
    }, {
      token: "delimiter.array.json",
      foreground: colorVarToHex("--gray13")
    }, {
      token: "delimiter.colon.json",
      foreground: colorVarToHex("--gray13")
    }, {
      token: "delimiter.comma.json",
      foreground: colorVarToHex("--gray13")
    }, // named color tokens
    ...Object.keys(palette).map(colorName => ({
      token: `color.${colorName}`,
      foreground: colorVarToHex(`--${colorName}`)
    }))],
    colors: {
      "editor.foreground": colorVarToHex("--gray11", true),
      "editor.background": colorVarToHex("--gray1", true),
      "editor.string": colorVarToHex("--blue", true),
      "editor.selectionBackground": colorVarToHex("--yellow6", true),
      "editor.lineHighlightBackground": colorVarToHex("--gray4", true),
      "editorCursor.foreground": colorVarToHex("--gray10", true),
      "editor.selectionHighlightBorder": colorVarToHex("--yellow9", true),
      "editorLineNumber.foreground": colorVarToHex("--gray10", true),
      "editorLineNumber.activeForeground": colorVarToHex("--gray13", true)
    }
  };
};
const monacoBaseOptions = {
  theme: "dls",
  fontSize: size.base,
  fontFamily: fontFamily.mono,
  fontLigatures: false,
  padding: {
    top: space.s3,
    bottom: space.s3
  },
  overviewRulerBorder: false,
  minimap: {
    enabled: false
  },
  renderControlCharacters: false,
  guides: {
    indentation: false
  },

  /**
   * If the autocomplete item is a snippet, the autocomplete within that snippet will not work.
   * Setting `snippetsPreventQuickSuggestions` preserves the autocomplete functionality within snippets body.
   *
   * Reference: https://stackoverflow.com/questions/62325624/how-to-allow-completion-suggestions-to-appear-while-inside-a-snippet-in-monaco-e
   */
  suggest: {
    snippetsPreventQuickSuggestions: false
  },
  renderWhitespace: "none",
  automaticLayout: true,
  showDeprecated: true,
  scrollBeyondLastLine: false,
  scrollbar: {
    useShadows: false,
    verticalScrollbarSize: 6,
    verticalSliderSize: 6,
    horizontalScrollbarSize: 6,
    horizontalSliderSize: 6
  }
};
const defaultTheme = "dls";

export { MonacoEditor, buildMonacoTheme, monacoBaseOptions };
