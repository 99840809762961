/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * functions
 * OpenAPI spec version: 2021-11-16
 */
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { functionsClient } from "../../../api/client/custom-instances";
import type {
  CreateFunctionAccessRequestRequestContent,
  CreateFunctionRequestContent,
  CreateFunctionResponseContent,
  GatewayTimeoutExceptionResponseContent,
  InternalFailureExceptionResponseContent,
  InvokeFunctionInputPayload,
  InvokeFunctionOutputPayload,
  ListFunctionOperationStatusParams,
  ListFunctionOperationStatusResponseContent,
  ListFunctionsParams,
  ListFunctionsResponseContent,
  NotAcceptableExceptionResponseContent,
  NotImplementedExceptionResponseContent,
  ReadFunctionOperationStatusResponseContent,
  ReadFunctionResponseContent,
  RequestTooLargeExceptionResponseContent,
  ResourceConflictExceptionResponseContent,
  ServiceUnavailableExceptionResponseContent,
  ThrottlingExceptionResponseContent,
  UnauthorizedExceptionResponseContent,
  UnknownOperationExceptionResponseContent,
  UnmappedExceptionResponseContent,
  UnsupportedMediaTypeExceptionResponseContent,
  UpdateFunctionRequestContent,
  UpdateFunctionResponseContent,
  ValidationExceptionResponseContent,
} from ".././models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * Retrieve a list containing every function.
 */
export const listFunctions = (params?: ListFunctionsParams) => {
  return functionsClient<ListFunctionsResponseContent>({ url: `/functions`, method: "get", params });
};

export const getListFunctionsQueryKey = (params?: ListFunctionsParams) => [`/functions`, ...(params ? [params] : [])];

export const useListFunctionsInfinite = <
  TData = AsyncReturnType<typeof listFunctions>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  params?: ListFunctionsParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listFunctions>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListFunctionsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listFunctions>> = ({ pageParam }) =>
    listFunctions({ pageToken: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listFunctions>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListFunctions = <
  TData = AsyncReturnType<typeof listFunctions>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  params?: ListFunctionsParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listFunctions>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListFunctionsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listFunctions>> = () => listFunctions(params);

  const query = useQuery<AsyncReturnType<typeof listFunctions>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new function.
 */
export const createFunction = (createFunctionRequestContent: CreateFunctionRequestContent) => {
  return functionsClient<CreateFunctionResponseContent>({
    url: `/functions`,
    method: "post",
    data: createFunctionRequestContent,
  });
};

export const useCreateFunction = <
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createFunction>,
    TError,
    { data: CreateFunctionRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof createFunction>, { data: CreateFunctionRequestContent }> = (
    props,
  ) => {
    const { data } = props || {};

    return createFunction(data);
  };

  return useMutation<AsyncReturnType<typeof createFunction>, TError, { data: CreateFunctionRequestContent }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Delete an existing function.
 */
export const deleteFunction = (functionName: string) => {
  return functionsClient<void>({ url: `/functions/${functionName}`, method: "delete" });
};

export const useDeleteFunction = <
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof deleteFunction>, TError, { functionName: string }, TContext>;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteFunction>, { functionName: string }> = (props) => {
    const { functionName } = props || {};

    return deleteFunction(functionName);
  };

  return useMutation<AsyncReturnType<typeof deleteFunction>, TError, { functionName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve an existing function.
 */
export const readFunction = (functionName: string) => {
  return functionsClient<ReadFunctionResponseContent>({ url: `/functions/${functionName}`, method: "get" });
};

export const getReadFunctionQueryKey = (functionName: string) => [`/functions/${functionName}`];

export const useReadFunctionInfinite = <
  TData = AsyncReturnType<typeof readFunction>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  functionName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readFunction>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadFunctionQueryKey(functionName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readFunction>> = () => readFunction(functionName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readFunction>, TError, TData>(queryKey, queryFn, {
    enabled: !!functionName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadFunction = <
  TData = AsyncReturnType<typeof readFunction>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  functionName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readFunction>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadFunctionQueryKey(functionName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readFunction>> = () => readFunction(functionName);

  const query = useQuery<AsyncReturnType<typeof readFunction>, TError, TData>(queryKey, queryFn, {
    enabled: !!functionName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing function.
 */
export const updateFunction = (functionName: string, updateFunctionRequestContent: UpdateFunctionRequestContent) => {
  return functionsClient<UpdateFunctionResponseContent>({
    url: `/functions/${functionName}`,
    method: "put",
    data: updateFunctionRequestContent,
  });
};

export const useUpdateFunction = <
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateFunction>,
    TError,
    { functionName: string; data: UpdateFunctionRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateFunction>,
    { functionName: string; data: UpdateFunctionRequestContent }
  > = (props) => {
    const { data, functionName } = props || {};

    return updateFunction(functionName, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateFunction>,
    TError,
    { functionName: string; data: UpdateFunctionRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Invoke function.
 */
export const invokeFunction = (functionName: string, invokeFunctionInputPayload: InvokeFunctionInputPayload) => {
  return functionsClient<InvokeFunctionOutputPayload>({
    url: `/functions/${functionName}/invocations`,
    method: "post",
    data: invokeFunctionInputPayload,
  });
};

export const useInvokeFunction = <
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof invokeFunction>,
    TError,
    { functionName: string; data: InvokeFunctionInputPayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof invokeFunction>,
    { functionName: string; data: InvokeFunctionInputPayload }
  > = (props) => {
    const { data, functionName } = props || {};

    return invokeFunction(functionName, data);
  };

  return useMutation<
    AsyncReturnType<typeof invokeFunction>,
    TError,
    { functionName: string; data: InvokeFunctionInputPayload },
    TContext
  >(mutationFn, mutationOptions);
};
export const listFunctionOperationStatus = (params?: ListFunctionOperationStatusParams) => {
  return functionsClient<ListFunctionOperationStatusResponseContent>({ url: `/operations`, method: "get", params });
};

export const getListFunctionOperationStatusQueryKey = (params?: ListFunctionOperationStatusParams) => [
  `/operations`,
  ...(params ? [params] : []),
];

export const useListFunctionOperationStatusInfinite = <
  TData = AsyncReturnType<typeof listFunctionOperationStatus>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | NotImplementedExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  params?: ListFunctionOperationStatusParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listFunctionOperationStatus>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListFunctionOperationStatusQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listFunctionOperationStatus>> = ({ pageParam }) =>
    listFunctionOperationStatus({ pageToken: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listFunctionOperationStatus>, TError, TData>(
    queryKey,
    queryFn,
    { staleTime: 10000, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const useListFunctionOperationStatus = <
  TData = AsyncReturnType<typeof listFunctionOperationStatus>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | NotImplementedExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  params?: ListFunctionOperationStatusParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listFunctionOperationStatus>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListFunctionOperationStatusQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listFunctionOperationStatus>> = () =>
    listFunctionOperationStatus(params);

  const query = useQuery<AsyncReturnType<typeof listFunctionOperationStatus>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const readFunctionOperationStatus = (operationToken: string) => {
  return functionsClient<ReadFunctionOperationStatusResponseContent>({
    url: `/operations/${operationToken}`,
    method: "get",
  });
};

export const getReadFunctionOperationStatusQueryKey = (operationToken: string) => [`/operations/${operationToken}`];

export const useReadFunctionOperationStatusInfinite = <
  TData = AsyncReturnType<typeof readFunctionOperationStatus>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | NotImplementedExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  operationToken: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readFunctionOperationStatus>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadFunctionOperationStatusQueryKey(operationToken);

  const queryFn: QueryFunction<AsyncReturnType<typeof readFunctionOperationStatus>> = () =>
    readFunctionOperationStatus(operationToken);

  const query = useInfiniteQuery<AsyncReturnType<typeof readFunctionOperationStatus>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!operationToken, staleTime: 10000, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

export const useReadFunctionOperationStatus = <
  TData = AsyncReturnType<typeof readFunctionOperationStatus>,
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | NotImplementedExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
>(
  operationToken: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readFunctionOperationStatus>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadFunctionOperationStatusQueryKey(operationToken);

  const queryFn: QueryFunction<AsyncReturnType<typeof readFunctionOperationStatus>> = () =>
    readFunctionOperationStatus(operationToken);

  const query = useQuery<AsyncReturnType<typeof readFunctionOperationStatus>, TError, TData>(queryKey, queryFn, {
    enabled: !!operationToken,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Request Access to functions.
 */
export const createFunctionAccessRequest = (
  createFunctionAccessRequestRequestContent: CreateFunctionAccessRequestRequestContent,
) => {
  return functionsClient<void>({
    url: `/request-access`,
    method: "post",
    data: createFunctionAccessRequestRequestContent,
  });
};

export const useCreateFunctionAccessRequest = <
  TError =
    | ValidationExceptionResponseContent
    | UnauthorizedExceptionResponseContent
    | UnmappedExceptionResponseContent
    | UnknownOperationExceptionResponseContent
    | NotAcceptableExceptionResponseContent
    | ResourceConflictExceptionResponseContent
    | RequestTooLargeExceptionResponseContent
    | UnsupportedMediaTypeExceptionResponseContent
    | ThrottlingExceptionResponseContent
    | InternalFailureExceptionResponseContent
    | ServiceUnavailableExceptionResponseContent
    | GatewayTimeoutExceptionResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createFunctionAccessRequest>,
    TError,
    { data: CreateFunctionAccessRequestRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createFunctionAccessRequest>,
    { data: CreateFunctionAccessRequestRequestContent }
  > = (props) => {
    const { data } = props || {};

    return createFunctionAccessRequest(data);
  };

  return useMutation<
    AsyncReturnType<typeof createFunctionAccessRequest>,
    TError,
    { data: CreateFunctionAccessRequestRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
