import { getTenantToken, getUserToken } from "./authentication";

export type AuthorizationKey = "Authorization";
export type AuthorizationHeader = Record<AuthorizationKey | "stedi-agent", string>;
export interface AuthenticateResponse {
  jwt: string;
}

export interface TenantToken {
  exp: number;
}

export const forUser = async (): Promise<AuthorizationHeader> => {
  const token = await getUserToken();
  return { Authorization: `Bearer ${token}`, "stedi-agent": "terminal" };
};

export const forTenant = async (accountId: string): Promise<AuthorizationHeader> => {
  const tenantToken = await getTenantToken(accountId);
  return { Authorization: `Bearer ${tenantToken}`, "stedi-agent": "terminal" };
};
