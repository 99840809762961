import { useQuery } from "react-query";
import { BUILT_AT } from "../../api/config";
import { basePath } from "../utils/url";

interface Version {
  version: string;
  builtAt: number;
}

export const MILLIS_IN_A_MONTH = 2592000000;

type State = "unknown" | "current" | "new-version" | "should-update";

const calculateUpdatedState = (version?: Version): State => {
  if (!version) return "unknown";

  const difference = version.builtAt - BUILT_AT;
  if (difference <= 0) {
    return "current";
  }

  if (difference >= MILLIS_IN_A_MONTH) {
    return "should-update";
  }

  return "new-version";
};

const getVersion = async (): Promise<Version> => {
  const result = await fetch(`${basePath}/version.json`);
  return result.json();
};

export const useVersion = () =>
  useQuery<State>("stedi:version", async () => {
    try {
      const version = await getVersion();
      return calculateUpdatedState(version);
    } catch (e) {
      return "unknown";
    }
  });
