import { History } from "history";
import qs from "query-string";
import { ArgRoute, ArgsNavigateOptions, getRouteWithAccount } from "../../shared/utils/routing";

export const workflowsRoutes = {
  executionDetail: {
    get: ({ accountId, attempt, executionId }) =>
      qs.stringifyUrl({
        url: getRouteWithAccount(accountId, `/workflows/executions/${executionId}`),
        query: { attempt },
      }),
    route: "/workflows/executions/:executionId",
    public: false,
  } as ArgRoute<{ attempt?: number; executionId: string; accountId?: string }>,
  executionsList: {
    get: ({ accountId, query }) =>
      qs.stringifyUrl(
        { url: getRouteWithAccount(accountId, `/workflows/executions`), query },
        { skipEmptyString: true },
      ),
    route: "/workflows/executions",
    public: false,
  } as ArgRoute<{ accountId?: string; query?: Record<string, string> }>,
  executionErrorDetail: {
    get: ({ accountId, executionErrorId, version }) =>
      qs.stringifyUrl({
        url: getRouteWithAccount(accountId, `/workflows/errors/${executionErrorId}`),
        query: { version },
      }),
    route: "/workflows/errors/:executionErrorId",
    public: false,
  } as ArgRoute<{ executionErrorId: string; accountId?: string; version?: string }>,
  executionErrorsList: {
    get: ({ accountId, query = {} }) =>
      qs.stringifyUrl({ url: getRouteWithAccount(accountId, `/workflows/errors`), query }),
    route: "/workflows/errors",
    public: false,
  } as ArgRoute<{ accountId?: string; query?: Record<string, string> }>,
  workflowsList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/workflows"),
    route: "/workflows",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  workflowDetail: {
    get: ({ accountId, version, workflowId }) =>
      getRouteWithAccount(accountId, `/workflows/${workflowId}${version ? `?version=${version}` : ""}`),
    route: "/workflows/:workflowId",
    public: false,
  } as ArgRoute<{ accountId?: string; version?: number; workflowId: string }>,
};

export const getWorkflowsRoutesForStediRouter = (accountId: string | undefined) => {
  return {
    executionDetail: ({ attempt, executionId }: { attempt?: number; executionId: string }) =>
      workflowsRoutes.executionDetail.get({ attempt, executionId, accountId }),
    executionsList: ({ query }: { query?: Record<string, string> } = {}) =>
      workflowsRoutes.executionsList.get({ query, accountId }),
    executionErrorDetail: ({ executionErrorId, version }: { executionErrorId: string; version?: string }) =>
      workflowsRoutes.executionErrorDetail.get({ executionErrorId, accountId, version }),
    executionErrorsList: ({ query }: { query?: Record<string, string> } = {}) =>
      workflowsRoutes.executionErrorsList.get({ query, accountId }),
    workflowsList: () => workflowsRoutes.workflowsList.get({ accountId }),
    workflowDetail: ({ version, workflowId }: { version?: number; workflowId: string }) =>
      workflowsRoutes.workflowDetail.get({ accountId, version, workflowId }),
  };
};

export const getWorkflowsRoutesForStediNavigate = (
  history: History,
  router: ReturnType<typeof getWorkflowsRoutesForStediRouter>,
) => {
  return {
    executionDetail: ({
      args: { attempt, executionId },
      ...options
    }: ArgsNavigateOptions<{ attempt?: number; executionId: string }>) =>
      history.push(router.executionDetail({ attempt, executionId }), options),
    executionsList: ({ args: { query }, ...options }: ArgsNavigateOptions<{ query?: Record<string, string> }>) =>
      history.push(router.executionsList({ query }), options),
    executionErrorDetail: ({
      args: { executionErrorId, version },
      ...options
    }: ArgsNavigateOptions<{ executionErrorId: string; version: string }>) =>
      history.push(router.executionErrorDetail({ executionErrorId, version }), options),
    executionErrorsList: (
      { args: { query }, ...options }: ArgsNavigateOptions<{ query?: Record<string, string> }> = { args: {} },
    ) => history.push(router.executionErrorsList({ query }), options),
    workflowsList: (options?: unknown) => history.push(router.workflowsList(), options),
    workflowDetail: ({
      args: { version, workflowId },
      ...options
    }: ArgsNavigateOptions<{
      version?: number;
      workflowId: string;
    }>) => history.push(router.workflowDetail({ version, workflowId }), options),
  };
};
