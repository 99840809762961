import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useHistory } from "react-router-dom";
import short from "short-uuid";
import * as accounts from "../../api/accounts";
import {
  MEMBERSHIPS_ACCOUNTS_INFINITE_QUERY_KEY,
  MEMBERSHIPS_ACCOUNTS_QUERY_KEY,
  MEMBERSHIPS_QUERY_KEY,
  MembershipsWithAccountsListCacheType,
} from "../../hooks/memberships";

const translator = short();

export const getAccountIdFromCurrentUrl = (location: { search: string } = window.location) => {
  const { search } = location;
  const accountId = new URLSearchParams(search).get("account");

  // TODO: remove support for short uuids a while after migration to account query param is complete
  if (accountId && accountId.length === 22) {
    return translator.toUUID(accountId);
  }

  return accountId;
};

export type UseGetCurrentAccountId = typeof useGetCurrentAccountId;

export function useGetCurrentAccountId(redirect?: true): string;
export function useGetCurrentAccountId(redirect: false): string | undefined;
export function useGetCurrentAccountId(redirect = true) {
  const nav = useHistory();
  const { pathname } = nav.location;

  let accountId = getAccountIdFromCurrentUrl(nav.location);

  if (!pathname || pathname.startsWith("/create")) {
    return undefined;
  }

  if (!accountId && redirect) {
    nav.push("/");
  }

  return accountId;
}

const ACCOUNTS_QUERY_KEY = "accounts";
const ACCOUNT_QUERY_KEY = "account";

export type UseGetAccount = typeof useGetAccount;

export const useGetAccount = (
  accountId: string,
  options: Partial<UseQueryOptions<accounts.Account | undefined, Error>> = {},
) => {
  return useQuery<accounts.Account | undefined, Error>(
    [ACCOUNT_QUERY_KEY, { accountId }],
    async () => accounts.getAccount(accountId),
    options,
  );
};

export const useCreateAccount = (
  options: Partial<UseMutationOptions<accounts.Account, Error, accounts.CreateAccount>> = {},
) => {
  const queryClient = useQueryClient();

  return useMutation<accounts.Account, Error, accounts.CreateAccount>(async (args) => accounts.createAccount(args), {
    ...options,
    onSuccess: async (account, variables, context) => {
      if (account) {
        queryClient.setQueryData<accounts.Account[]>([ACCOUNTS_QUERY_KEY], (old) => {
          return [
            ...(old ?? []),
            {
              ...account,
            } as const,
          ];
        });
        void queryClient.invalidateQueries([MEMBERSHIPS_ACCOUNTS_QUERY_KEY]);
        void queryClient.invalidateQueries([MEMBERSHIPS_ACCOUNTS_INFINITE_QUERY_KEY]);
        await queryClient.refetchQueries([MEMBERSHIPS_QUERY_KEY]);
      }

      if (options.onSuccess) void options.onSuccess(account, variables, context);
    },
  });
};

export const useUpdateAccount = (
  options: Partial<UseMutationOptions<accounts.Account, Error, accounts.UpdateAccount>> = {},
) => {
  const queryClient = useQueryClient();

  return useMutation<accounts.Account, Error, accounts.UpdateAccount>((data) => accounts.updateAccount(data), {
    ...options,
    onSuccess: (account, variables, context) => {
      if (options.onSuccess) void options.onSuccess(account, variables, context);

      if (account) {
        queryClient.setQueryData<accounts.Account>([ACCOUNT_QUERY_KEY, { accountId: account.id }], (_old) => account);
        queryClient.setQueryData<accounts.Account[]>([ACCOUNTS_QUERY_KEY], (old) => {
          return (old ?? []).map((a) => {
            if (a.id === account.id) return account;
            return a;
          });
        });
        queryClient.setQueryData<MembershipsWithAccountsListCacheType>([MEMBERSHIPS_ACCOUNTS_QUERY_KEY], (old) => {
          const updatedMemberships = (old ?? []).map((a) => {
            if (a.account_id === account.id) return { ...a, account };
            return a;
          });
          return updatedMemberships;
        });
      }
    },
  });
};
