import { color } from '../../constants.js';

const createBoxShadow = focusRingColor => `0 0 0 2px ${focusRingColor}`;

const transition = "box-shadow 67ms ease-out";
const blue = {
  boxShadow: createBoxShadow(color.blue8)
};
const desert = {
  boxShadow: createBoxShadow(color.orange8)
};
const gray = {
  boxShadow: createBoxShadow(color.gray8)
};
const green = {
  boxShadow: createBoxShadow(color.green8)
};
const magenta = {
  boxShadow: createBoxShadow(color.magenta8)
};
const red = {
  boxShadow: createBoxShadow(color.red8)
};
const yellow = {
  boxShadow: createBoxShadow(color.yellow8)
};
const c0 = {
  boxShadow: createBoxShadow(color.gray1)
};
var FocusRingColor;

(function (FocusRingColor) {
  FocusRingColor["Default"] = "default";
  FocusRingColor["Error"] = "error";
  FocusRingColor["Reversed"] = "reversed";
  FocusRingColor["Blue"] = "blue";
  FocusRingColor["Desert"] = "desert";
  FocusRingColor["Gray"] = "gray";
  FocusRingColor["Green"] = "green";
  FocusRingColor["Magenta"] = "magenta";
  FocusRingColor["Red"] = "red";
  FocusRingColor["Yellow"] = "yellow";
  FocusRingColor["C0"] = "c0";
})(FocusRingColor || (FocusRingColor = {}));

const colorMapping = {
  default: green,
  error: red,
  reversed: c0,
  blue,
  desert,
  gray,
  green,
  magenta,
  red,
  yellow,
  c0
};
const focusStyles = function () {
  let color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : FocusRingColor.Default;
  return {
    ring: colorMapping[color],
    transition
  };
};

export { FocusRingColor, focusStyles };
