import { __rest } from '../../../node_modules/tslib/tslib.es6.js';
import { jsx } from 'react/jsx-runtime';
import { FormProvider } from 'react-hook-form';
export { useForm } from 'react-hook-form';

function Form(props) {
  const {
    children,
    methods
  } = props,
        formProps = __rest(props, ["children", "methods"]);

  return jsx(FormProvider, Object.assign({}, methods, {
    children: jsx("form", Object.assign({}, formProps, {
      children: children
    }))
  }));
}

export { Form };
