import * as rum from "../../shared/utils/rum";
import { FUNCTIONS_HOST } from "../config";
import { HTTPResponseError } from "../errors";
import { FunctionsValidationError } from "../errors/functions_validation_error";
import { mfetch } from "../monitored-fetch";

export interface RequestOptions {
  host: string;
  accountId?: string;
  headers?: Record<string, string>;
  method?: "POST" | "GET" | "PUT" | "DELETE" | "PATCH" | "HEAD";
  body?: unknown;
  raw?: boolean;
  rawResponse?: boolean;
  shouldParseDates?: boolean;
}

export const parseDates = (key: string, value: unknown): unknown => {
  if (key.includes("date") || key.endsWith("_at")) {
    return new Date(value as string);
  }
  return value;
};

export function shouldThrowFunctionsValidationError(host: string, status: number) {
  return host.includes(FUNCTIONS_HOST) && status === 400;
}

export async function request<Resp>(
  path: string,
  {
    host,
    headers = {},
    body,
    method = body ? "POST" : "GET",
    raw = false,
    rawResponse = false,
    shouldParseDates = true,
  }: RequestOptions,
): Promise<Resp> {
  const options: RequestInit = {
    method,
    headers: {
      "content-type": "application/json",
      ...headers,
    },
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  try {
    const response = await mfetch(`${host}${path}`, options);
    if (rawResponse) {
      return response as unknown as Resp;
    }
    if (response.ok) {
      const respBody = await response.text();

      if (raw || response.status === 204) {
        return respBody as unknown as Resp;
      }
      return JSON.parse(respBody, shouldParseDates ? parseDates : undefined) as Resp;
    }

    // Test and Save Function actions return a `400` error whenever user tries
    // to save a function with linter/compilation issues.
    // This is an example of an "error by design" and as such shouldn't be reported to DataDog etc.
    if (shouldThrowFunctionsValidationError(host, response.status)) {
      throw await FunctionsValidationError.create(response);
    }
    throw await HTTPResponseError.create(response);
  } catch (e) {
    rum.captureError(e);
    throw e;
  }
}
