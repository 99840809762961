import { History } from "history";
import { ArgRoute } from "../shared/utils/routing";
import * as events from "./events/generated/routes";
import * as functions from "./functions/generated/routes";
import * as logs from "./logs/generated/routes";
import * as storage from "./storage/generated/routes";
import * as transports from "./transports/generated/routes";
import * as workflows from "./workflows/generated/routes";

type PathType = {
  [_ in keyof typeof routes]: ArgRoute["route"];
};

export const routes = {
  ...storage.routes,
  ...workflows.routes,
  ...functions.routes,
  ...transports.routes,
  ...events.routes,
  ...logs.routes,
};

export const routesForStediRouter = (accountId?: string) => ({
  ...storage.routesForStediRouter(accountId),
  ...workflows.routesForStediRouter(accountId),
  ...functions.routesForStediRouter(accountId),
  ...transports.routesForStediRouter(accountId),
  ...events.routesForStediRouter(accountId),
  ...logs.routesForStediRouter(accountId),
});

export const routesForStediNavigate = (history: History, router: ReturnType<typeof routesForStediRouter>) => {
  return {
    ...storage.routesForStediNavigate(history, router),
    ...workflows.routesForStediNavigate(history, router),
    ...functions.routesForStediNavigate(history, router),
    ...transports.routesForStediNavigate(history, router),
    ...events.routesForStediNavigate(history, router),
    ...logs.routesForStediNavigate(history, router),
  };
};

export const routers = [
  ...storage.routers,
  ...workflows.routers,
  ...functions.routers,
  ...transports.routers,
  ...events.routers,
  ...logs.routers,
];

export const paths = Object.fromEntries(Object.entries(routes).map(([k, v]) => [k, v.route])) as PathType;
