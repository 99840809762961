/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * logs
 * Stedi Logs
 * OpenAPI spec version: 2021-11-16
 */
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { logsClient } from "../../../api/client/custom-instances";
import type {
  AccessDeniedResponseContent,
  BadRequestResponseContent,
  GatewayTimeoutResponseContent,
  InternalFailureResponseContent,
  ListLogEntriesParams,
  ListLogEntriesResponseContent,
  ListLogGroupsParams,
  ListLogGroupsResponseContent,
  NotAcceptableResponseContent,
  QuotaExceededResponseContent,
  ReadLogGroupParams,
  ReadLogGroupResponseContent,
  RequestTooLargeResponseContent,
  ResourceConflictResponseContent,
  ResourceNotFoundResponseContent,
  ServiceUnavailableResponseContent,
  UnauthorizedResponseContent,
  UnsupportedMediaTypeResponseContent,
} from ".././models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

export const listLogGroups = (params?: ListLogGroupsParams) => {
  return logsClient<ListLogGroupsResponseContent>({ url: `/log_groups`, method: "get", params });
};

export const getListLogGroupsQueryKey = (params?: ListLogGroupsParams) => [`/log_groups`, ...(params ? [params] : [])];

export const useListLogGroupsInfinite = <
  TData = AsyncReturnType<typeof listLogGroups>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListLogGroupsParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listLogGroups>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListLogGroupsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listLogGroups>> = ({ pageParam }) =>
    listLogGroups({ page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listLogGroups>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListLogGroups = <
  TData = AsyncReturnType<typeof listLogGroups>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListLogGroupsParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listLogGroups>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListLogGroupsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listLogGroups>> = () => listLogGroups(params);

  const query = useQuery<AsyncReturnType<typeof listLogGroups>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Retrieve an existing log group.
 */
export const readLogGroup = (logGroupName: string, params?: ReadLogGroupParams) => {
  return logsClient<ReadLogGroupResponseContent>({ url: `/log_groups/${logGroupName}`, method: "get", params });
};

export const getReadLogGroupQueryKey = (logGroupName: string, params?: ReadLogGroupParams) => [
  `/log_groups/${logGroupName}`,
  ...(params ? [params] : []),
];

export const useReadLogGroupInfinite = <
  TData = AsyncReturnType<typeof readLogGroup>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  logGroupName: string,
  params?: ReadLogGroupParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readLogGroup>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadLogGroupQueryKey(logGroupName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof readLogGroup>> = ({ pageParam }) =>
    readLogGroup(logGroupName, { ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof readLogGroup>, TError, TData>(queryKey, queryFn, {
    enabled: !!logGroupName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadLogGroup = <
  TData = AsyncReturnType<typeof readLogGroup>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  logGroupName: string,
  params?: ReadLogGroupParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readLogGroup>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadLogGroupQueryKey(logGroupName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof readLogGroup>> = () => readLogGroup(logGroupName, params);

  const query = useQuery<AsyncReturnType<typeof readLogGroup>, TError, TData>(queryKey, queryFn, {
    enabled: !!logGroupName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const listLogEntries = (logGroupName: string, params?: ListLogEntriesParams) => {
  return logsClient<ListLogEntriesResponseContent>({
    url: `/log_groups/${logGroupName}/entries`,
    method: "get",
    params,
  });
};

export const getListLogEntriesQueryKey = (logGroupName: string, params?: ListLogEntriesParams) => [
  `/log_groups/${logGroupName}/entries`,
  ...(params ? [params] : []),
];

export const useListLogEntriesInfinite = <
  TData = AsyncReturnType<typeof listLogEntries>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  logGroupName: string,
  params?: ListLogEntriesParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listLogEntries>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListLogEntriesQueryKey(logGroupName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listLogEntries>> = ({ pageParam }) =>
    listLogEntries(logGroupName, { page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listLogEntries>, TError, TData>(queryKey, queryFn, {
    enabled: !!logGroupName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListLogEntries = <
  TData = AsyncReturnType<typeof listLogEntries>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  logGroupName: string,
  params?: ListLogEntriesParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listLogEntries>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListLogEntriesQueryKey(logGroupName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listLogEntries>> = () => listLogEntries(logGroupName, params);

  const query = useQuery<AsyncReturnType<typeof listLogEntries>, TError, TData>(queryKey, queryFn, {
    enabled: !!logGroupName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
