import * as React from "react";
import styled from "@emotion/styled";
import {
  color,
  flex,
  fontWeight,
  navigationHeight,
  PageBanner,
  PageBannerSecondaryText,
  size,
  space,
  Tooltip,
  withMediaQueries,
} from "@stedi/dls";
import { capitalCase } from "change-case";
import PreviewIcon from "remixicon-react/FlagFillIcon";

const SPreviewElementWithIcon = styled.div({
  position: "relative",
});
const STooltip = styled.div({
  position: "absolute",
  top: space.s2,
  right: space.s2,
  width: 18,
  height: 18,
  borderRadius: 3,
  ...flex("center", "center"),
  backgroundColor: color.green,
});
const SIconContainer = styled.div({
  pointerEvents: "all",
  cursor: "pointer",
  position: "relative",
  top: 2,
});

export type Stage = "deprecated" | "internalPreview" | "developerPreview" | "customerPreview" | "generalAvailability";

export const isGenerallyAvailableOrCustomerPreview = (stage: Stage) =>
  stage === "generalAvailability" || stage === "customerPreview";

const SStrong = styled.strong({
  ...fontWeight("bold"),
  fontSize: size.s1,
  textTransform: "uppercase",
  letterSpacing: 1,
});
const SPageBanner = styled.div(
  withMediaQueries({
    position: "fixed",
    top: navigationHeight,
    right: 0,
    left: 0,
    zIndex: 1,
    span: {
      fontSize: size.s1,
    },
    'div[role="banner"]': {
      backgroundColor: color.c5Alpha80,
    },
  }),
);

const SPreviewTagDiv = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "5px",
});

const SPreviewTagSpan = styled.span({
  alignItems: "end",
  color: color.blue,
  display: "inline-flex",
  gap: "5px",
});

export const PreviewTag = () => {
  return (
    <SPreviewTagDiv>
      <SPreviewTagSpan>
        <PreviewIcon size={14} /> <SStrong>Preview</SStrong>
      </SPreviewTagSpan>
      <span>This feature is in active development</span>
    </SPreviewTagDiv>
  );
};

interface PreviewPageFeatureFlagProps {
  children: React.ReactNode;
  stage: Stage;
}

export function PreviewPageFeatureFlag({ children, stage }: PreviewPageFeatureFlagProps) {
  if (isGenerallyAvailableOrCustomerPreview(stage) || stage === "deprecated") {
    return <>{children}</>;
  }

  return (
    <>
      <SPageBanner>
        <PageBanner>
          <div role="presentation">
            <PreviewIcon size={10} />
          </div>
          <SStrong>{capitalCase(stage)}</SStrong>
          <PageBannerSecondaryText as="span">This feature is in active development</PageBannerSecondaryText>
        </PageBanner>
      </SPageBanner>
      {children}
    </>
  );
}

interface PreviewElementFeatureFlagProps {
  children: React.ReactNode;
  stage: Stage;
  showIcon?: boolean;
  isInverse?: boolean;
}

export function PreviewElementFeatureFlag({
  children,
  isInverse,
  showIcon,
  stage,
}: PreviewElementFeatureFlagProps): JSX.Element {
  if (isGenerallyAvailableOrCustomerPreview(stage)) {
    return <>{children}</>;
  }

  if (showIcon)
    return (
      <SPreviewElementWithIcon>
        <STooltip>
          <Tooltip
            render={
              <>
                <PreviewIcon size={14} style={{ position: "relative", top: 2 }} /> <SStrong>Preview</SStrong>
                <span>This feature is in active development</span>
              </>
            }
            zIndex={10000}
          >
            <SIconContainer className="preview">
              <PreviewIcon color={color.base} size={14} />
            </SIconContainer>
          </Tooltip>
        </STooltip>
        <div>{children}</div>
      </SPreviewElementWithIcon>
    );

  return <>{children}</>;
}
