import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import styled from "@emotion/styled";
import { color, flex, fontFamily, size, space } from "@stedi/dls";

interface GlobalDropzoneOptions {
  dropzoneMessage?: JSX.Element;
  onDrop?: (file: File[]) => void;
}

type SetGlobalDropzoneOptions = Dispatch<SetStateAction<GlobalDropzoneOptions>>;

interface DropzoneState {
  open: VoidFunction;
}

type TGlobalDropzoneContext = [SetGlobalDropzoneOptions, DropzoneState];

const GlobalDropzoneContext = createContext<TGlobalDropzoneContext | undefined>(undefined);

const SGlobalDropzoneOverlay = styled.div(
  {
    ...flex("center", "center"),
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: color.c0Alpha60,
    transition: "opacity .3s ease-in-out",
    "> div": {
      fontSize: size.s6,
      fontFamily: fontFamily.display,
      color: color.c50,
      background: color.c0,
      borderRadius: 4,
      padding: space.s4,
    },
  },
  (props: { isDragActive: boolean }) => ({
    /**
     * Transformers team found that the hardcoded `zIndex` interferences with `MappingDocumentJSONTree` scrolling on Chrome (https://github.com/Stedi/mappings/issues/923).
     * To ensure that is not the case, the high `zIndex` is only applied when the element should be visible.
     *
     * I've manually tested the behavior of the application on Chrome, Brave, Safari and Firefox and saw no visual regression on **all** pages that utilize the Global Dropzone.
     */
    zIndex: props.isDragActive ? 10000 : "revert",
    opacity: props.isDragActive ? 1 : 0,
    pointerEvents: props.isDragActive ? "all" : "none",
  }),
);

export function useGlobalDropzone(): TGlobalDropzoneContext {
  const context = useContext(GlobalDropzoneContext);
  if (context === undefined) {
    throw new Error(`useGlobalDropzone must be used within a GlobalDropzoneProvider`);
  }
  return context;
}

export const GlobalDropzoneProvider: React.FunctionComponent = ({ children }) => {
  const [options, setGlobalDropzoneOptions] = useState<GlobalDropzoneOptions>({});

  // item will have no type, it is a lie, but a necessary one to satisfy useDrop TS types
  const [{ isDragActive }, drop] = useDrop<DataTransfer & { type: string }, void, { isDragActive: boolean }>({
    accept: [NativeTypes.FILE],
    canDrop: () => options.onDrop !== undefined,
    drop: (item) => {
      options.onDrop?.(Array.from(item.files));
    },
    collect: (monitor) => ({
      isDragActive: monitor.isOver() && monitor.canDrop(),
    }),
  });

  const input = useRef<HTMLInputElement | null>(null);
  const onChange = useCallback(
    ({ target }: { target: HTMLInputElement }) => {
      if (target.files) {
        options.onDrop?.(Array.from(target.files));
      }
    },
    [options],
  );

  const dropzoneState = useMemo(
    () => ({
      open: () => input?.current?.click(),
    }),
    [input],
  );

  return (
    <GlobalDropzoneContext.Provider value={[setGlobalDropzoneOptions, dropzoneState]}>
      <div ref={drop} data-testid="global-dropzone">
        <SGlobalDropzoneOverlay isDragActive={isDragActive}>
          <div>{options.dropzoneMessage ? options.dropzoneMessage : "Drop files to upload"}</div>
        </SGlobalDropzoneOverlay>
        <input
          ref={input}
          autoComplete="off"
          hidden={true}
          multiple={true}
          onChange={onChange}
          tabIndex={-1}
          type="file"
        />
        {children}
      </div>
    </GlobalDropzoneContext.Provider>
  );
};
