import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isProd } from "./api/environment";
import * as cloudPlatformRoutes from "./repatriation/routes";
import { services, useServices } from "./shared/context/ServicesProvider";
import { UseGetCurrentAccountId } from "./shared/hooks/accounts";
import { ArgRoute, ArgsNavigateOptions, getRouteWithAccount, Route } from "./shared/utils/routing";
import {
  getWorkflowsRoutesForStediNavigate,
  getWorkflowsRoutesForStediRouter,
  workflowsRoutes,
} from "./workflows/routes";

export const isMemberInvite = (url: string | null): boolean => {
  if (url) {
    return /(org|account)-invite\/.+/.test(url);
  }
  return false;
};
export const isPartnerInvite = (url: string | null): boolean => (url ? /partner-invite\/.+/.test(url) : false);

const routes = {
  root: {
    get: () => "/",
    route: "/",
    public: true,
  } as Route,
  signUp: {
    get: () => "/sign-up",
    route: "/sign-up",
    public: true,
  } as Route,
  signIn: {
    get: () => "/sign-in",
    route: "/sign-in",
    public: true,
  } as Route,
  forgotPassword: {
    get: () => "/forgot-password",
    route: "/forgot-password",
    public: true,
  } as Route,
  resetPassword: {
    get: () => "/reset-password",
    route: "/reset-password",
    public: true,
  } as Route,
  confirmEmail: {
    get: () => "/confirm-email",
    route: "/confirm-email",
    public: true,
  } as Route,
  createAccount: {
    get: () => "/create",
    route: "/create",
    public: false,
  } as Route,
  user: {
    get: () => "/user",
    route: "/user",
    public: false,
  } as Route,
  acceptPartnerInvite: {
    get: ({ id }) => `/partner-invite/${id}`,
    route: "/partner-invite/:invitationId",
    public: false,
  } as ArgRoute<{ id: string }>,
  acceptMemberInvite: {
    get: ({ id }) => `/org-invite/${id}`,
    route: "/(org|account)-invite/:invitationId",
    public: false,
  } as ArgRoute<{ id: string }>,
  accountDashboard: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/"),
    route: "/",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  membersList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/members"),
    route: "/members",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  apiKeys: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/apikeys"),
    route: "/apikeys",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  accountProfile: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/profile"),
    route: "/profile",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  partnersList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/partners"),
    route: "/partners",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  partnerDetail: {
    get: ({ accountId, partnerAccountId }) => getRouteWithAccount(accountId, `/partners/${partnerAccountId}`),
    route: "/partners/:partnerAccountId",
    public: false,
  } as ArgRoute<{ accountId?: string; partnerAccountId: string }>,
  transactionsList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/transactions"),
    route: "/transactions",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  transactionDetail: {
    get: ({ accountId, id }) => getRouteWithAccount(accountId, `/transactions/${id}`),
    route: "/transactions/:txId",
    public: false,
  } as ArgRoute<{ id: string; accountId?: string }>,
  eventDetail: {
    get: ({ accountId, eventId, eventSourceId }) =>
      getRouteWithAccount(accountId, `/events/${eventSourceId}/${eventId}`),
    route: "/events/:eventSourceId/:eventId",
    public: false,
  } as ArgRoute<{ eventId: string; eventSourceId: string; accountId?: string }>,
  eventsList: {
    get: ({ accountId, eventSourceId }) => getRouteWithAccount(accountId, `/events/${eventSourceId}`),
    route: "/events/:eventSourceId",
    public: false,
  } as ArgRoute<{ accountId?: string; eventSourceId?: string }>,
  integrationList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/integrations"),
    route: "/integrations",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  integrationDetail: {
    get: ({ accountId, id, tab }) => getRouteWithAccount(accountId, `/integrations/${id}${tab ? `?tab=${tab}` : ""}`),
    route: "/integrations/:integrationId",
    public: false,
  } as ArgRoute<{ id: string; accountId?: string; tab?: string }>,
  integrationCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/integrations/create"),
    route: "/integrations/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  functionsList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/functions"),
    route: "/functions",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  functionRead: {
    get: ({ accountId, functionName }) => getRouteWithAccount(accountId, `/functions/${functionName}/read`),
    route: `/functions/:functionName/read`,
    public: false,
  } as ArgRoute<{ accountId?: string; functionName: string }>,
  functionEditor: {
    get: ({ accountId, functionName }) => getRouteWithAccount(accountId, `/functions/${functionName}/edit`),
    route: `/functions/:functionName/edit`,
    public: false,
  } as ArgRoute<{ accountId?: string; functionName: string }>,
  functionCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/functions/create"),
    route: "/functions/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  functionLogs: {
    get: ({ accountId, functionName }) => {
      const url = `https://${isProd() ? "stedi.com" : "preview.stedi.com"}/app/logs`;
      const routeWithAccount = accountId ? getRouteWithAccount(accountId, url) : url;
      const tabs = ["tab=logs"].concat(functionName ? `function=${functionName}` : []);
      return `${routeWithAccount}#${tabs.join(",")}`;
    },
    route: "/app/logs",
    public: false,
  } as ArgRoute<{ accountId?: string; functionName?: string }>,
  functionMetrics: {
    get: ({ accountId, functionName }) => {
      const url = `https://${isProd() ? "stedi.com" : "preview.stedi.com"}/app/logs`;
      const routeWithAccount = accountId ? getRouteWithAccount(accountId, url) : url;
      const tabs = ["tab=metrics"].concat(functionName ? `function=${functionName}` : []);
      return `${routeWithAccount}#${tabs.join(",")}`;
    },
    route: "/app/logs",
    public: false,
  } as ArgRoute<{ accountId?: string; functionName: string }>,
  schemasList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/schemas"),
    route: "/schemas",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  schemaCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/schemas/create"),
    route: "/schemas/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  schemaValidateData: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/schemas/validate-data"),
    route: "/schemas/validate-data",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  schemaPreview: {
    get: ({ accountId, schemaId }) => getRouteWithAccount(accountId, `/schemas/${schemaId}`),
    route: `/schemas/:schemaId`,
    public: false,
  } as ArgRoute<{ accountId?: string; schemaId: string }>,
  parameterSetCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/parameter-sets/create"),
    route: "/parameter-sets/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  parameterSetsList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/parameter-sets"),
    route: "/parameter-sets",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  parameterSetEditor: {
    get: ({ accountId, parameterId, schemaId }) =>
      getRouteWithAccount(accountId, `/parameter-sets/${parameterId}${schemaId ? `/schema/${schemaId}` : ""}`),
    route: `/parameter-sets/:parameterId/(schema)?/:schemaId?`,
    public: false,
  } as ArgRoute<{ accountId?: string; parameterId: string; schemaId?: string }>,
  parameterCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/parameters/create"),
    route: "/parameters/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  parameterEdit: {
    get: ({ accountId, parameterId }) => getRouteWithAccount(accountId, `/parameters/id/${parameterId}`),
    route: "/parameters/id/:parameterId",
    public: false,
  } as ArgRoute<{ accountId?: string; parameterId?: string }>,
  parametersList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/parameters"),
    route: "/parameters",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  formationResourceSets: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/formation/resource-sets"),
    route: "/formation/resource-sets",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  formationResourceSetDeploy: {
    get: ({ accountId, resourceSetName }) =>
      getRouteWithAccount(accountId, `/formation/resource-sets/deploy/${resourceSetName ? `${resourceSetName}/` : ""}`),
    route: "/formation/resource-sets/deploy/:resourceSetName?",
    public: false,
  } as ArgRoute<{ accountId?: string; resourceSetName?: string }>,
  formationResourceSetByName: {
    get: ({ accountId, resourceSetName }) =>
      getRouteWithAccount(accountId, `/formation/resource-sets/name/${resourceSetName}`),
    route: "/formation/resource-sets/name/:resourceSetName",
    public: false,
  } as ArgRoute<{ accountId?: string; resourceSetName: string }>,
  formationResourceSet: {
    get: ({ accountId, resourceSetId }) => getRouteWithAccount(accountId, `/formation/resource-sets/${resourceSetId}`),
    route: "/formation/resource-sets/:resourceSetId",
    public: false,
  } as ArgRoute<{ accountId?: string; resourceSetId: string }>,
  ledgerCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, `/ledgers/create`),
    route: "/ledgers/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  ledgersList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, `/ledgers`),
    route: "/ledgers",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  ledgerDetail: {
    get: ({ accountId, ledgerId }) => getRouteWithAccount(accountId, `/ledgers/${ledgerId}`),
    route: "/ledgers/:ledgerId",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string }>,
  ledgerConfiguration: {
    get: ({ accountId, ledgerId }) => getRouteWithAccount(accountId, `/ledgers/${ledgerId}/configure`),
    route: "/ledgers/:ledgerId/configure",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string }>,
  ledgerTransactionCreate: {
    get: ({ accountId, ledgerId }) => getRouteWithAccount(accountId, `/ledgers/${ledgerId}/createTransaction`),
    route: "/ledgers/:ledgerId/createTransaction",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string }>,
  ledgerTransactionDetail: {
    get: ({ accountId, ledgerId, transactionId }) =>
      getRouteWithAccount(accountId, `/ledgers/${ledgerId}/transaction/${transactionId}`),
    route: "/ledgers/:ledgerId/transaction/:transactionId",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string; transactionId?: string }>,
  ledgerTransactionEdit: {
    get: ({ accountId, ledgerId, transactionId }) =>
      getRouteWithAccount(accountId, `/ledgers/${ledgerId}/transaction/${transactionId}/edit`),
    route: "/ledgers/:ledgerId/transaction/:transactionId/edit",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string; transactionId?: string }>,
  ledgerAccountDetail: {
    get: ({ accountId, ledgerAccountId, ledgerId }) =>
      getRouteWithAccount(accountId, `/ledgers/${ledgerId}/account/${ledgerAccountId}`),
    route: "/ledgers/:ledgerId/account/:ledgerAccountId",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string; ledgerAccountId?: string }>,
  ledgerAccountConfiguration: {
    get: ({ accountId, ledgerAccountId, ledgerId }) =>
      getRouteWithAccount(accountId, `/ledgers/${ledgerId}/account/${ledgerAccountId}/configure`),
    route: "/ledgers/:ledgerId/account/:ledgerAccountId/configure",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string; ledgerAccountId?: string }>,
  ledgerAccountCreate: {
    get: ({ accountId, ledgerId }) => getRouteWithAccount(accountId, `/ledgers/${ledgerId}/createAccount`),
    route: "/ledgers/:ledgerId/createAccount",
    public: false,
  } as ArgRoute<{ accountId?: string; ledgerId?: string }>,
  documentList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, `/documents`),
    route: "/documents",
    public: false,
  } as ArgRoute<{ accountId?: string; parentPath?: string }>,
  documentDetail: {
    get: ({ accountId, documentId }) => getRouteWithAccount(accountId, `/documents/${documentId}`),
    route: "/documents/:documentId",
    public: false,
  } as ArgRoute<{ accountId?: string; documentId?: string }>,
  documentListDeprecated: {
    get: ({ accountId, parentPath }) => getRouteWithAccount(accountId, `/documents-deprecated${parentPath}`),
    route: "/documents-deprecated",
    public: false,
  } as ArgRoute<{ accountId?: string; parentPath?: string }>,
  documentShow: {
    get: ({ accountId, directoryId, documentName, version }) =>
      getRouteWithAccount(accountId, `/document/${directoryId}/${documentName}${version ? `?version=${version}` : ""}`),
    route: "/document/:directoryId/:documentName",
    public: false,
  } as ArgRoute<{ accountId?: string; directoryId: string; documentName: string; version?: string }>,
  ...workflowsRoutes,
  transportsList: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/transports"),
    route: "/transports",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  transportsCreate: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/transports/create"),
    route: "/transports/create",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  transportsEditor: {
    get: ({ accountId, transportId }) => getRouteWithAccount(accountId, `/transports/${transportId}/edit`),
    route: "/transports/:transportId/edit",
    public: false,
  } as ArgRoute<{ accountId?: string; transportId: string }>,
  transportDetail: {
    get: ({ accountId, transportId }) => getRouteWithAccount(accountId, `/transports/${transportId}`),
    route: "/transports/:transportId",
    public: false,
  } as ArgRoute<{ accountId?: string; transportId: string }>,
  billing: {
    get: ({ accountId }) => getRouteWithAccount(accountId, "/billing"),
    route: "/billing",
    public: false,
  } as ArgRoute<{ accountId?: string }>,
  ...cloudPlatformRoutes.routes,
};

type RouteNames = keyof typeof routes;

export const isPublicRoute = (path: string): boolean =>
  !!Object.keys(routes).find((key) => routes[key].route === path && routes[key].public);

export type UseStediRouter = typeof useStediRouter;

export const useStediRouter = () => {
  const getService = useServices();
  const useGetCurrentAccountId = getService<UseGetCurrentAccountId>(services.useGetCurrentAccountId);
  const accountId = useGetCurrentAccountId(false);

  return useMemo(() => {
    return {
      root: () => routes.root.get(),
      signUp: () => routes.signUp.get(),
      signIn: () => routes.signIn.get(),
      confirmEmail: () => routes.confirmEmail.get(),
      user: () => routes.user.get(),
      acceptPartnerInvite: ({ id }: { id: string }) => routes.acceptPartnerInvite.get({ id }),
      acceptMemberInvite: ({ id }: { id: string }) => routes.acceptMemberInvite.get({ id }),
      forgotPassword: () => routes.forgotPassword.get(),
      resetPassword: () => routes.resetPassword.get(),
      createAccount: () => routes.createAccount.get(),
      accountDashboard: (newAccountId?: string) =>
        routes.accountDashboard.get({ accountId: newAccountId || accountId }),
      membersList: (overrideAccountId?: string) =>
        routes.membersList.get({ accountId: overrideAccountId ?? accountId }),
      apiKeys: () => routes.apiKeys.get({ accountId }),
      accountProfile: () => routes.accountProfile.get({ accountId }),
      partnersList: (overrideAccountId?: string) =>
        routes.partnersList.get({ accountId: overrideAccountId ?? accountId }),
      partnerDetail: (partnerAccountId: string) => routes.partnerDetail.get({ accountId, partnerAccountId }),
      transactionsList: (overrideAccountId?: string) =>
        routes.transactionsList.get({ accountId: overrideAccountId ?? accountId }),
      transactionDetail: ({ id }: { id: string }) => routes.transactionDetail.get({ id, accountId }),
      eventDetail: ({ eventId, eventSourceId }: { eventId: string; eventSourceId: string }) =>
        routes.eventDetail.get({ eventId, eventSourceId, accountId }),
      eventsList: (eventSourceId?: string) => routes.eventsList.get({ accountId, eventSourceId }),
      integrationList: () => routes.integrationList.get({ accountId }),
      integrationDetail: ({ id, tab }: { id: string; tab?: string }) =>
        routes.integrationDetail.get({
          accountId,
          id,
          tab,
        }),
      integrationCreate: () => routes.integrationCreate.get({ accountId }),
      functionsList: () => routes.functionsList.get({ accountId }),
      functionRead: ({ functionName }: { functionName: string }) =>
        routes.functionRead.get({ accountId, functionName }),
      functionEditor: ({ functionName }: { functionName: string }) =>
        routes.functionEditor.get({ accountId, functionName }),
      functionCreate: () => routes.functionCreate.get({ accountId }),
      functionLogs: ({ functionName }: { functionName?: string }) =>
        routes.functionLogs.get({ accountId, functionName }),
      functionMetrics: ({ functionName }: { functionName: string }) =>
        routes.functionMetrics.get({ accountId, functionName }),
      schemasList: () => routes.schemasList.get({ accountId }),
      schemaCreate: () => routes.schemaCreate.get({ accountId }),
      schemaValidateData: () => routes.schemaValidateData.get({ accountId }),
      schemaPreview: ({ schemaId }: { schemaId: string }) => routes.schemaPreview.get({ accountId, schemaId }),
      parameterSetsList: () => routes.parameterSetsList.get({ accountId }),
      parameterSetsCreate: () => routes.parameterSetCreate.get({ accountId }),
      parameterSetEditor: ({ parameterId, schemaId }: { parameterId: string; schemaId?: string }) =>
        routes.parameterSetEditor.get({ accountId, parameterId, schemaId }),
      parameterCreate: () => routes.parameterCreate.get({ accountId }),
      parameterEdit: ({ parameterId }: { parameterId: string }) => routes.parameterEdit.get({ accountId, parameterId }),
      parametersList: () => routes.parametersList.get({ accountId }),
      formationResourceSetDeploy: ({ resourceSetName }: { resourceSetName?: string }) =>
        routes.formationResourceSetDeploy.get({ accountId, resourceSetName }),
      formationResourceSets: () => routes.formationResourceSets.get({ accountId }),
      formationResourceSetByName: ({ resourceSetName }: { resourceSetName: string }) =>
        routes.formationResourceSetByName.get({ accountId, resourceSetName }),
      formationResourceSet: ({ resourceSetId }: { resourceSetId: string }) =>
        routes.formationResourceSet.get({ accountId, resourceSetId }),
      documentListDeprecated: (parentPath: string) => routes.documentListDeprecated.get({ accountId, parentPath }),
      ledgerCreate: () => routes.ledgerCreate.get({ accountId }),
      ledgersList: () => routes.ledgersList.get({ accountId }),
      ledgerDetail: (ledgerId: string) => routes.ledgerDetail.get({ accountId, ledgerId }),
      ledgerConfiguration: (ledgerId: string) => routes.ledgerConfiguration.get({ accountId, ledgerId }),
      ledgerTransactionCreate: (ledgerId: string) => routes.ledgerTransactionCreate.get({ accountId, ledgerId }),
      ledgerTransactionDetail: (ledgerId: string, transactionId: string) =>
        routes.ledgerTransactionDetail.get({ accountId, ledgerId, transactionId }),
      ledgerTransactionEdit: (ledgerId: string, transactionId: string) =>
        routes.ledgerTransactionEdit.get({ accountId, ledgerId, transactionId }),
      ledgerAccountDetail: (ledgerId: string, ledgerAccountId: string) =>
        routes.ledgerAccountDetail.get({ accountId, ledgerId, ledgerAccountId }),
      ledgerAccountConfiguration: (ledgerId: string, ledgerAccountId: string) =>
        routes.ledgerAccountConfiguration.get({ accountId, ledgerId, ledgerAccountId }),
      ledgerAccountCreate: (ledgerId: string) => routes.ledgerAccountCreate.get({ accountId, ledgerId }),
      documentList: () => routes.documentList.get({ accountId }),
      documentDetail: (documentId: string) => routes.documentDetail.get({ accountId, documentId }),
      documentShow: (directoryId: string, documentName: string, version?: string) =>
        routes.documentShow.get({ accountId, directoryId, documentName, version }),
      ...getWorkflowsRoutesForStediRouter(accountId),
      transportsList: () => routes.transportsList.get({ accountId }),
      transportsCreate: () => routes.transportsCreate.get({ accountId }),
      transportsEditor: ({ transportId }: { transportId: string }) =>
        routes.transportsEditor.get({ accountId, transportId }),
      transportDetail: ({ transportId }: { transportId: string }) =>
        routes.transportDetail.get({ accountId, transportId }),
      billing: () => routes.billing.get({ accountId }),
      ...cloudPlatformRoutes.routesForStediRouter(accountId),
    };
  }, [accountId]);
};

export type UseStediNavigate = typeof useStediNavigate;

export const useStediNavigate = () => {
  const history = useHistory();
  const router = useStediRouter();

  return {
    root: (options?: unknown) => history.push(router.root(), options),
    signUp: (state?: Record<string, string>) => history.push(router.signUp(), state),
    signIn: (options?: Record<string, string>) => history.push(router.signIn(), options),
    confirmEmail: (state?: Record<string, string>) => history.push(router.confirmEmail(), state),
    forgotPassword: (options?: Record<string, string>) => history.push(router.forgotPassword(), options),
    resetPassword: (options?: Record<string, string>) => history.push(router.resetPassword(), options),
    redirect: ({ args: { path }, ...options }: ArgsNavigateOptions<{ path: string }>) => history.push(path, options),
    createAccount: (options?: unknown) => history.push(router.createAccount(), options),
    user: (options?: unknown) => history.push(router.user(), options),
    accountDashboard: ({ args: { accountId }, ...options }: ArgsNavigateOptions<{ accountId: string }>) =>
      history.push(router.accountDashboard(accountId), options),
    membersList: (options?: ArgsNavigateOptions<{ accountId?: string }>) =>
      history.push(router.membersList(options?.args.accountId), options),
    apiKeys: (options?: unknown) => history.push(router.apiKeys(), options),
    accountProfile: (options?: unknown) => history.push(router.accountProfile(), options),
    acceptMemberInvite: ({ args: { id }, ...options }: ArgsNavigateOptions<{ id: string }>) =>
      history.push(router.acceptMemberInvite({ id }), options),
    acceptPartnerInvite: ({ args: { id }, ...options }: ArgsNavigateOptions<{ id: string }>) =>
      history.push(router.acceptPartnerInvite({ id }), options),
    ...getWorkflowsRoutesForStediNavigate(history, router),
    partnersList: (options?: ArgsNavigateOptions<{ accountId?: string }>) =>
      history.push(router.partnersList(options?.args.accountId), options),
    partnerDetail: ({ args: { partnerAccountId }, ...options }: ArgsNavigateOptions<{ partnerAccountId: string }>) =>
      history.push(router.partnerDetail(partnerAccountId), options),
    transactionsList: ({
      args: { accountId, queryString },
      replace,
      ...options
    }: ArgsNavigateOptions<{ accountId?: string; queryString?: string }>) => {
      if (replace) {
        history.replace(`${router.transactionsList(accountId)}${queryString ? `&${queryString}` : ""}`, options);
      } else {
        history.push(`${router.transactionsList(accountId)}${queryString ? `&${queryString}` : ""}`, options);
      }
    },
    transactionDetail: ({ args: { hash, id }, ...options }: ArgsNavigateOptions<{ id: string; hash?: string }>) =>
      history.push(`${router.transactionDetail({ id })}${hash ? `#${hash}` : ""}`, options),
    eventDetail: ({
      args: { eventId, eventSourceId },
      ...options
    }: ArgsNavigateOptions<{ eventId: string; eventSourceId: string }>) =>
      history.push(`${router.eventDetail({ eventId, eventSourceId })}`, options),
    eventsList: ({
      args: { eventSourceId, queryString },
      replace,
      ...options
    }: ArgsNavigateOptions<{ queryString?: string; eventSourceId?: string }>) => {
      if (replace) {
        history.replace(`${router.eventsList(eventSourceId)}${queryString ? `&${queryString}` : ""}`, options);
      } else {
        history.push(`${router.eventsList(eventSourceId)}${queryString ? `&${queryString}` : ""}`, options);
      }
    },

    integrationList: (options?: unknown) => history.push(router.integrationList(), options),
    integrationDetail: ({
      args: { id, queryString, tab },
      ...options
    }: ArgsNavigateOptions<{
      id: string;
      tab?: string;
      queryString?: string;
    }>) => history.push(`${router.integrationDetail({ id, tab })}${queryString ? `&${queryString}` : ""}`, options),
    integrationCreate: (options?: unknown) => history.push(router.integrationCreate(), options),
    functionCreate: (options?: unknown) => history.push(router.functionCreate(), options),
    functionsList: (options?: unknown) => history.push(router.functionsList(), options),
    functionRead: ({
      args: { functionName },
      ...options
    }: ArgsNavigateOptions<{
      functionName: string;
    }>) => history.push(router.functionRead({ functionName }), options),
    functionEditor: ({
      args: { functionName },
      ...options
    }: ArgsNavigateOptions<{
      functionName: string;
    }>) => history.push(router.functionEditor({ functionName }), options),
    functionLogs: ({
      args: { functionName },
      ...options
    }: ArgsNavigateOptions<{
      functionName: string;
    }>) => history.push(router.functionLogs({ functionName }), options),
    schemasList: (options?: unknown) => history.push(router.schemasList(), options),
    schemaCreate: (options?: unknown) => history.push(router.schemaCreate(), options),
    schemaValidateData: (options?: unknown) => history.push(router.schemaValidateData(), options),
    schemaPreview: ({
      args: { schemaId },
      ...options
    }: ArgsNavigateOptions<{
      schemaId: string;
    }>) => history.push(router.schemaPreview({ schemaId }), options),
    parameterSetsList: (options?: unknown) => history.push(router.parameterSetsList(), options),
    parameterSetsCreate: (options?: unknown) => history.push(router.parameterSetsCreate(), options),
    parameterSetEditor: ({
      args: { parameterId, schemaId },
      ...options
    }: ArgsNavigateOptions<{
      parameterId: string;
      schemaId?: string;
    }>) => history.push(router.parameterSetEditor({ parameterId, schemaId }), options),
    parameterCreate: (options?: unknown) => history.push(router.parameterCreate(), options),
    parameterEdit: ({ args: { parameterId }, ...options }: ArgsNavigateOptions<{ parameterId: string }>) =>
      history.push(router.parameterEdit({ parameterId }), options),
    parametersList: (options?: unknown) => history.push(router.parametersList(), options),
    formationResourceSetDeploy: ({
      args: { resourceSetName },
      ...options
    }: ArgsNavigateOptions<{
      resourceSetName: string;
    }>) => history.push(router.formationResourceSetDeploy({ resourceSetName }), options),
    formationResourceSets: (options?: unknown) => history.push(router.formationResourceSets(), options),
    formationResourceSetByName: ({
      args: { resourceSetName },
      ...options
    }: ArgsNavigateOptions<{
      resourceSetName: string;
    }>) => history.push(router.formationResourceSetByName({ resourceSetName }), options),
    formationResourceSet: ({
      args: { queryString, resourceSetId },
      ...options
    }: ArgsNavigateOptions<{
      resourceSetId: string;
      queryString?: string;
    }>) =>
      history.push(`${router.formationResourceSet({ resourceSetId })}${queryString ? `&${queryString}` : ""}`, options),
    documentListDeprecated: ({ args: { parentPath = "/" } }, options?: unknown) =>
      history.push(router.documentListDeprecated(parentPath), options),
    ledgerCreate: (options?: unknown) => history.push(router.ledgerCreate(), options),
    ledgersList: (options?: unknown) => history.push(router.ledgersList(), options),
    ledgerDetail: (
      {
        args: { ledgerId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerDetail(ledgerId), options),
    ledgerConfiguration: (
      {
        args: { ledgerId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerConfiguration(ledgerId), options),
    ledgerTransactionCreate: (
      {
        args: { ledgerId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerTransactionCreate(ledgerId), options),
    ledgerTransactionDetail: (
      {
        args: { ledgerId, transactionId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
        transactionId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerTransactionDetail(ledgerId, transactionId), options),
    ledgerTransactionEdit: (
      {
        args: { ledgerId, transactionId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
        transactionId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerTransactionEdit(ledgerId, transactionId), options),
    ledgerAccountDetail: (
      {
        args: { ledgerAccountId, ledgerId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
        ledgerAccountId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerAccountDetail(ledgerId, ledgerAccountId), options),
    ledgerAccountConfiguration: (
      {
        args: { ledgerAccountId, ledgerId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
        ledgerAccountId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerAccountConfiguration(ledgerId, ledgerAccountId), options),
    ledgerAccountCreate: (
      {
        args: { ledgerId },
      }: ArgsNavigateOptions<{
        ledgerId: string;
        ledgerAccountId: string;
      }>,
      options?: unknown,
    ) => history.push(router.ledgerAccountCreate(ledgerId), options),
    documentList: (options?: unknown) => history.push(router.documentList(), options),
    documentDetail: (
      {
        args: { documentId },
      }: ArgsNavigateOptions<{
        documentId: string;
      }>,
      options?: unknown,
    ) => history.push(router.documentDetail(documentId), options),
    documentShow: ({
      args: { directoryId = "", documentName = "", version },
      ...options
    }: ArgsNavigateOptions<{ directoryId: string; documentName: string; version?: string }>) =>
      history.push(router.documentShow(directoryId, documentName, version), options),
    transportsList: (options?: unknown) => history.push(router.transportsList(), options),
    transportsCreate: (options?: unknown) => history.push(router.transportsCreate(), options),
    transportsEditor: ({ args: { transportId }, ...options }: ArgsNavigateOptions<{ transportId: string }>) =>
      history.push(router.transportsEditor({ transportId }), options),
    transportDetail: ({ args: { transportId }, ...options }: ArgsNavigateOptions<{ transportId: string }>) =>
      history.push(router.transportDetail({ transportId }), options),
    billing: (options?: unknown) => history.push(router.billing(), options),
    ...cloudPlatformRoutes.routesForStediNavigate(history, router),
  };
};

export const paths: Record<RouteNames, string> = {
  root: routes.root.route,
  signUp: routes.signUp.route,
  signIn: routes.signIn.route,
  confirmEmail: routes.confirmEmail.route,
  forgotPassword: routes.forgotPassword.route,
  resetPassword: routes.resetPassword.route,
  createAccount: routes.createAccount.route,
  accountDashboard: routes.accountDashboard.route,
  membersList: routes.membersList.route,
  apiKeys: routes.apiKeys.route,
  accountProfile: routes.accountProfile.route,
  user: routes.user.route,
  acceptPartnerInvite: routes.acceptPartnerInvite.route,
  acceptMemberInvite: routes.acceptMemberInvite.route,
  partnersList: routes.partnersList.route,
  partnerDetail: routes.partnerDetail.route,
  transactionsList: routes.transactionsList.route,
  transactionDetail: routes.transactionDetail.route,
  eventDetail: routes.eventDetail.route,
  eventsList: routes.eventsList.route,
  executionDetail: routes.executionDetail.route,
  executionsList: routes.executionsList.route,
  executionErrorDetail: routes.executionErrorDetail.route,
  executionErrorsList: routes.executionErrorsList.route,
  integrationList: routes.integrationList.route,
  integrationDetail: routes.integrationDetail.route,
  integrationCreate: routes.integrationCreate.route,
  functionCreate: routes.functionCreate.route,
  functionsList: routes.functionsList.route,
  functionRead: routes.functionRead.route,
  functionEditor: routes.functionEditor.route,
  functionLogs: routes.functionLogs.route,
  functionMetrics: routes.functionMetrics.route,
  schemasList: routes.schemasList.route,
  schemaCreate: routes.schemaCreate.route,
  schemaPreview: routes.schemaPreview.route,
  schemaValidateData: routes.schemaValidateData.route,
  parameterSetsList: routes.parameterSetsList.route,
  parameterSetCreate: routes.parameterSetCreate.route,
  parameterSetEditor: routes.parameterSetEditor.route,
  parameterCreate: routes.parameterCreate.route,
  parameterEdit: routes.parameterEdit.route,
  parametersList: routes.parametersList.route,
  documentListDeprecated: routes.documentListDeprecated.route,
  ledgerCreate: routes.ledgerCreate.route,
  ledgersList: routes.ledgersList.route,
  ledgerDetail: routes.ledgerDetail.route,
  ledgerConfiguration: routes.ledgerConfiguration.route,
  ledgerTransactionCreate: routes.ledgerTransactionCreate.route,
  ledgerTransactionDetail: routes.ledgerTransactionDetail.route,
  ledgerTransactionEdit: routes.ledgerTransactionEdit.route,
  ledgerAccountDetail: routes.ledgerAccountDetail.route,
  ledgerAccountConfiguration: routes.ledgerAccountConfiguration.route,
  ledgerAccountCreate: routes.ledgerAccountCreate.route,
  documentList: routes.documentList.route,
  documentDetail: routes.documentDetail.route,
  documentShow: routes.documentShow.route,
  workflowsList: workflowsRoutes.workflowsList.route,
  workflowDetail: routes.workflowDetail.route,
  formationResourceSets: routes.formationResourceSets.route,
  formationResourceSetDeploy: routes.formationResourceSetDeploy.route,
  formationResourceSet: routes.formationResourceSet.route,
  formationResourceSetByName: routes.formationResourceSetByName.route,
  transportsList: routes.transportsList.route,
  transportsCreate: routes.transportsCreate.route,
  transportsEditor: routes.transportsEditor.route,
  transportDetail: routes.transportDetail.route,
  billing: routes.billing.route,
  ...cloudPlatformRoutes.paths,
};

export const useQueryParam = (queryParam: string): string | null => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export default routes;
