import { color, size, space, controlHeight, fontFamily } from '../../constants.js';
import { focusStyles, FocusRingColor } from './focusStyles.js';

const invalidFocusRing = Object.assign({
  /*
    The Select control uses a third party library that only
    knows about focus via a javascript flag. We need to
    give the border colour of the focus state a little
    specificity bump here, so that it is more specific
    than the :hover selector, which would otherwise
    override this style.
  */
  borderColor: `${color.red10} !important`
}, focusStyles(FocusRingColor.Error).ring);
const validFocusRing = Object.assign({
  /*
    The Select control uses a third party library that only
    knows about focus via a javascript flag. We need to
    give the border colour of the focus state a little
    specificity bump here, so that it is more specific
    than the :hover selector, which would otherwise
    override this style.
  */
  borderColor: `${color.gray10} !important`
}, focusStyles(FocusRingColor.Gray).ring);
const invalidStyles = {
  backgroundColor: color.red3,
  borderColor: color.red6,
  color: color.red13,
  ":hover": {
    backgroundColor: color.red4,
    borderColor: color.red7
  },
  ":focus-visible": invalidFocusRing,
  "::placeholder": {
    color: color.red10,
    opacity: 1
  }
};
const validStyles = {
  backgroundColor: color.gray3,
  borderColor: color.gray6,
  color: color.gray13,
  ":hover": {
    backgroundColor: color.gray4,
    borderColor: color.gray7
  },
  ":focus-visible": validFocusRing,
  "::placeholder": {
    color: color.gray10,
    opacity: 1
  }
};
const readOnlyStyles = {
  backgroundColor: "transparent",
  borderColor: color.gray6,
  color: color.gray10,
  ":focus-visible": validFocusRing,
  "::placeholder": {
    color: color.gray8,
    opacity: 1
  }
};
/**
 * Currently, we use :disabled on input fields to display a non-editable value.
 *
 * It might be more correct to use readOnly:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/:read-only
 *
 * Until we are using readOnly appropriately we need to keep disabled fields legible,
 * so for now, disabled and readOnly are styled the same.
 */

const disabledStyles = readOnlyStyles;
const inputDimensions = {
  small: {
    borderWidth: 1,
    fontSize: size.s2,
    lineHeight: space.s3,
    minHeight: controlHeight.small
  },
  medium: {
    borderWidth: 1,
    fontSize: size.s3,
    lineHeight: space.s3 + space.s1,
    minHeight: controlHeight.medium
  },
  large: {
    borderWidth: 1,
    fontSize: size.s4,
    lineHeight: space.s3 + space.s2,
    minHeight: controlHeight.large
  }
};
const textInputStyles = function () {
  let {
    hasError,
    isDisabled = false,
    isFocused = false,
    isMultiLine = false,
    isMonospace = false,
    scale = "medium"
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    borderWidth,
    fontSize,
    lineHeight,
    minHeight
  } = inputDimensions[scale];
  const padding = (minHeight - lineHeight) / 2 - borderWidth;
  const singleLineHeight = controlHeight[scale] - borderWidth * 2;
  return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({
    appearance: "none",
    borderRadius: space.s1,
    borderStyle: "solid",
    borderWidth,
    fontSize,
    lineHeight: `${isMultiLine ? lineHeight : singleLineHeight}px`,
    margin: 0,
    minHeight,
    outline: "none",
    padding: isMultiLine ? padding : `0 ${padding}px`,
    transition: `border 80ms ease-in-out, ${focusStyles().transition}`,
    width: "100%"
  }, hasError ? invalidStyles : validStyles), hasError !== false && {
    ":invalid": invalidStyles
  }), isFocused ? hasError ? invalidFocusRing : validFocusRing : {}), isDisabled ? disabledStyles : {}), isMonospace ? {
    fontFamily: fontFamily.mono
  } : undefined), {
    "&:disabled": Object.assign({}, disabledStyles),
    "&:is(input):read-only": Object.assign({}, readOnlyStyles),
    "&:is(textarea):read-only": Object.assign({}, readOnlyStyles)
  });
};

export { textInputStyles };
