import {
  InlineResponse2001 as ApiAccountLegacyData,
  InlineResponse2002 as ApiAccountsPage,
} from "@stedi/sdk-accounts-20210720";
import ky, { HTTPError } from "ky";
import { captureError } from "../../shared/utils/rum";
import { forTenant, forUser } from "../authorization";
import * as client from "../client";
import { parseDates } from "../client/basic";
import { TENANT_HOST } from "../config";
import { HTTPResponseError } from "../errors";

const api = ky.extend({ parseJson: (text) => JSON.parse(text, parseDates) });
const accounts = api.extend({ prefixUrl: TENANT_HOST });

type Options = RequestInit & { accountId: string };
const isHTTPError = (error: unknown): error is HTTPError => error instanceof HTTPError;
const toRequestInit = async (options?: Partial<Options>): Promise<RequestInit> => {
  const auth = options?.accountId ? await forTenant(options.accountId) : await forUser();
  return { ...options, headers: { ...options?.headers, ...auth } };
};

export interface Account {
  id: string;
  created_at: Date;
  updated_at: Date;
  website?: string;
  logo_url: string;
  name: string;
}

export type CreateAccount = Omit<Account, "id" | "created_at" | "updated_at"> & { idempotencyKey?: string };

export type UpdateAccount = CreateAccount & { id: string };

export enum MembershipStatus {
  Invited = "invited",
  Accepted = "accepted",
  Rejected = "rejected",
}

export enum AccountRole {
  Admin = "admin",
  Editor = "editor",
  Reader = "reader",
}

export enum MembershipInviteStatus {
  Proposed = "proposed",
  Accepted = "accepted",
  Rejected = "rejected",
}

export interface AccountMembership {
  account: Account;
  id: string;
  created_at: Date;
  updated_at: Date;
  membership: {
    status: MembershipStatus;
    roles: AccountRole[];
  };
}

export interface MembershipDetail {
  roles: Array<AccountRole>;
  status: MembershipStatus;
}

export interface User {
  id: string;
  email: string;
}

export interface UserMembership {
  readonly id: string;
  readonly created_at: Date;
  readonly updated_at: Date;
  membership: MembershipDetail;
  user: User;
}

export interface MemberInvitation {
  invitation_id: string;
  account_id: string;
  email: string;
  roles: Array<AccountRole>;
  status: MembershipInviteStatus;
  created_at: Date;
  updated_at: Date;
}

export interface AccountInvitation {
  id: string;
  account: Account;
  email: string;
  roles: Array<AccountRole>;
}

export interface ApiKey {
  tenant_id: string;
  description: string;
  created_at: string;
  api_key_prefix: string;
}

export interface GeneratedApiKey {
  tenant_id: string;
  description: string;
  created_at: string;
  api_key: string;
}

export const fromApiAccount = (data: ApiAccountLegacyData): Account => ({
  id: data.id,
  name: data.name,
  logo_url: data.logo || "",
  created_at: new Date(data.created_at || ""),
  updated_at: new Date(data.updated_at || ""),
  website: data.url || "",
});

export const toApiAccount = (account: Account): ApiAccountLegacyData => ({
  name: account.name,
  logo: account.logo_url,
  url: account.website,
  id: account.id,
  created_at: account.created_at.toString(),
  updated_at: account.updated_at.toString(),
});

export type CreateApiAccount = Omit<ApiAccountLegacyData, "created_at" | "updated_at" | "id">;

export const getAccount = async (accountId: string, options?: Options) => {
  try {
    const init = await toRequestInit(options);
    const data = await accounts.get(`2021-07-20/accounts/${accountId}/legacy_data`, init).json<ApiAccountLegacyData>();
    return fromApiAccount(data);
  } catch (err) {
    captureError(err);
    if (isHTTPError(err)) {
      throw await HTTPResponseError.create(err.response);
    }
    throw err;
  }
};

export const createAccount = async (_input: CreateAccount) => {
  throw new Error("Terminal account creation forbidden");
};

export const updateAccount = async (input: UpdateAccount) => {
  const data = await client.tenant<ApiAccountLegacyData>(`/2021-07-20/accounts/${input.id}/legacy_data`, {
    body: {
      name: input.name,
      logo: input.logo_url,
      url: input.website,
    },
    method: "PATCH",
    accountId: input.id,
  });

  return fromApiAccount(data);
};

/**
 * The batch get accounts endpoint only accepts a maximum of 10 ids at a time.
 * We chunk them, await all promises, and return the flattened map
 * @param accountIds List of account ids
 * @returns
 */
export const getAccounts = async (accountIds: string[], options?: Options): Promise<Account[]> => {
  try {
    const chunks: string[][] = [];
    while (accountIds.length > 0) {
      chunks.push(accountIds.splice(0, 10));
    }

    const mergedAccounts = await Promise.all(
      chunks.map(async (chunkedIds) => {
        return client.tenant<ApiAccountsPage>(`/2021-07-20/batch/accounts/legacy_data?ids=${chunkedIds.join(",")}`, {
          method: "GET",
        });
      }),
    ).then((results) => {
      return results.flatMap((r) => r.results);
    });

    return mergedAccounts.map(fromApiAccount);
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};
