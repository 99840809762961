import * as React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { color, fontFamily, fontWeight, Logo, size, space } from "@stedi/dls";
import { UseStediRouter } from "../../routes";
import { RouteTextLink } from "../../shared/components/RouteTextLink";
import { services, useServices } from "../../shared/context/ServicesProvider";

interface AuthLayoutProps {
  title: string;
  hideLogo?: boolean;
}

const SContainer = styled.div({
  width: "100%",
  maxWidth: 500,
  margin: "0 auto",
  padding: space.s4,
  "@media (min-height: 769px)": {
    paddingTop: "15vh",
  },
});

export const SHeading = styled.h1({
  fontFamily: fontFamily.display,
  fontSize: size.s8,
  ...fontWeight("semibold"),
  marginTop: space.s4,
  marginBottom: space.s3,
  color: color.c50,
});

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, hideLogo, title }) => {
  const getService = useServices();
  const useStediRouter = getService<UseStediRouter>(services.useStediRouter);
  const router = useStediRouter();
  return (
    <>
      <Helmet>
        <title>{title} - Stedi Terminal</title>
      </Helmet>
      <SContainer>
        {!hideLogo && (
          <RouteTextLink to={router.root()}>
            <Logo fill={color.green} width={70} />
          </RouteTextLink>
        )}
        <SHeading>{title}</SHeading>
        {children}
      </SContainer>
    </>
  );
};

export default AuthLayout;
