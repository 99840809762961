import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, size, space } from "@stedi/dls";
import AuthLayout from "../../components/auth/AuthLayout";

const SDescription = styled.p({
  marginBottom: space.s4,
  fontSize: size.s4,
});

function NotFoundPage({ children, title = "Not found" }: React.PropsWithChildren<{ title?: string }>) {
  return <AuthLayout title={title}>{children}</AuthLayout>;
}

const NotFoundButton = ({ children = "Go home", to }: React.PropsWithChildren<{ to: string }>) => {
  const history = useHistory();

  function onClick() {
    history.push(to);
  }

  return <Button onClick={onClick}>{children}</Button>;
};

const NotFoundDescription = ({ children }: React.PropsWithChildren<{}>) => <SDescription>{children}</SDescription>;

NotFoundPage.Description = NotFoundDescription;
NotFoundPage.Action = NotFoundButton;

export { NotFoundPage };
