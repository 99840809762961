import { useEffect } from 'react';

const useIntersectionObserver = _ref => {
  let {
    enabled = true,
    onIntersect,
    root,
    rootMargin = "0px",
    target,
    threshold = 0.1
  } = _ref;
  useEffect(() => {
    if (!enabled) {
      return;
    }

    let observer;

    if ("IntersectionObserver" in window) {
      observer = new IntersectionObserver(entries => entries.forEach(entry => entry.isIntersecting && onIntersect()), {
        root: root && root.current,
        rootMargin,
        threshold
      });
    } else {
      return;
    }

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [onIntersect, root, rootMargin, target, threshold, enabled]);
};

export { useIntersectionObserver };
