import { jsx } from 'react/jsx-runtime';
import React__default from 'react';
import Tippy from '@tippyjs/react/headless';

const LazyTippy = props => {
  const [mounted, setMounted] = React__default.useState(false);
  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false)
    })
  };
  const {
    plugins,
    render
  } = props;
  const computedProps = Object.assign(Object.assign({}, props), {
    plugins: [lazyPlugin, ...(plugins || [])]
  });

  if (render) {
    computedProps.render = function () {
      return mounted ? render(...arguments) : "";
    };
  } else {
    computedProps.content = mounted ? props.content : "";
  }

  return jsx(Tippy, Object.assign({}, computedProps));
};

export { LazyTippy };
