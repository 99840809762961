import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { BrowserRouter as Router } from "react-router-dom";
import { configureAmplify } from "./api/config";
import { AppProviders } from "./shared/context/AppProviders";
import App from "./shell/components/App";

/**
 * Webpack is not smart enough to tree-shake the code guarded by multiple conditions,
 * having `if (foo === "true" || bar === "true") {}` will not work.
 * The condition has to evaluate to primitives on both sides by means of replacing values, eg. true === true or 'true' === 'true'
 */
if (process.env["REACT_APP_USE_MSW"] === "true") {
  // eslint-disable-next-line global-require
  require("./test/api/server");
}

/**
 * Re-render tracking will only be enabled for components that have their `whyDidYouRerender` property set to true.
 * @example
 *  const MyComponent = () => {}
 *  MyComponent.whyDidYouRender = true;
 *  export {MyComponent}
 */
if (process.env["NODE_ENV"] === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: false,
  });
}

configureAmplify();

const root = document.getElementById("root");

if (root) {
  ReactModal.setAppElement(root);

  ReactDOM.render(
    <Router basename="/terminal/">
      <AppProviders>
        <App />
      </AppProviders>
    </Router>,
    root,
  );
}
