import short from "short-uuid";

const translator = short();
const REDIRECT_PATH_KEY = "redirect_path";

// TODO: this should probably live in sessionStorage as the redirect path is usually read from
// a query parameter, which is local to the current tab.

export const setRedirectPath = (value: string): void => {
  // TODO: remove this once no longer needed for backward compatibility with old URL structure
  // (/o/:accountId/...restPath)
  if (value.startsWith("/o")) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [firstSlash, o, accountSegment, ...restPath] = value.split("/");
    if (o === "o" && accountSegment) {
      if (accountSegment.length === 22) {
        const accountId = translator.toUUID(accountSegment);
        value = restPath.join("/");
        value = value.includes("?") ? `/${value}&account=${accountId}` : `/${value}?account=${accountId}`;
      } else {
        // account segment here might be create
        value = `/${accountSegment}/${restPath.length ? `${restPath.join("/")}` : ""}`;
      }
    }
  }

  localStorage.setItem(REDIRECT_PATH_KEY, value);
};

export const getRedirectPath = (): string | null => {
  return localStorage.getItem(REDIRECT_PATH_KEY);
};

export const clearRedirectPath = (): void => {
  localStorage.removeItem(REDIRECT_PATH_KEY);
};

export const setRedirectWithoutAccount = (pathWithSearch: string): void => {
  const [path, search] = pathWithSearch.split("?");
  if (!search) {
    setRedirectPath(path);
  } else {
    const params = search
      .split("&")
      .filter((param) => !param.includes("account"))
      .join("&");
    const pathWithoutAccount = path + (params.length > 0 ? `?${params}` : "");
    setRedirectPath(pathWithoutAccount);
  }
};

/**
 * Performs a redirect to a different Stedi subdomain if the redirect path
 * has been set to a Stedi subdomain (guards against redirects to third-party domains)
 * @returns { boolean } true if redirect occurred
 */
export const redirectIfExternalStediUrl = () => {
  const path = getRedirectPath();
  if (path && /^https:\/\/[0-9a-z-]+\.+stedi\.com\//.test(path)) {
    clearRedirectPath();
    window.location.replace(path);
    return true;
  }

  return false;
};
