import React from "react";
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from "react-router-dom";
import styled from "@emotion/styled";
import { BaseLinkStyle, color } from "@stedi/dls";
import { mergePathWithQueryParams } from "../utils/url";

type LinkProps = RouterLinkProps<{}> & {
  to: string;
  params?: Record<string, string>;
  ref?: React.Ref<HTMLAnchorElement>;
};

const SLink = styled(RouterLink)({
  ...BaseLinkStyle,
  ":hover, :focus": {
    textDecorationColor: color.c40,
  },
});

export const RouteTextLink = ({ params, to, ...props }: LinkProps) => {
  return <SLink to={params ? mergePathWithQueryParams(to, params) : to} {...props} />;
};
