import { isPreprod, isProd } from "../api/environment";
import { useListMembershipsAndAccounts } from "../hooks/memberships";
import { useStediNavigate, useStediRouter } from "../routes";
import { useAuth } from "./context/AuthProvider";
import { useGetAccount, useGetCurrentAccountId } from "./hooks/accounts";
import { useGetAwsAuthUser, useUserHasAccessToAccount } from "./hooks/authentication";

/**
 * The default collection of injectable services.
 *
 * These are commonly used across many components, and are mocked
 * out in just about every test.
 */
export const defaultServices = {
  useAuth,
  useStediRouter,
  useStediNavigate,
  useGetAwsAuthUser,
  useGetAccount,
  useGetCurrentAccountId,
  isProd,
  isPreprod,
  useUserHasAccessToAccount: useUserHasAccessToAccount,
  useListMembershipsAndAccounts,
};
