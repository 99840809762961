import React from "react";
import { Helmet } from "react-helmet";
import { isHotkeyPressed } from "react-hotkeys-hook";
import { useHistory } from "react-router-dom";
import { PageHeader } from "@stedi/dls";
import { withBasePathUrl } from "../utils/url";

export interface TerminalHeaderNavigateProps {
  title: string | JSX.Element;
  children?: React.ReactNode;
  backUrl?: string;
  hasParentPage?: boolean;
  backText?: string;
  headerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const TerminalHeader = ({
  backText,
  backUrl,
  children,
  hasParentPage,
  headerProps,
  title,
}: TerminalHeaderNavigateProps) => {
  const history = useHistory<{ backUrl?: string; backText?: string }>();

  const url = history.location.state?.backUrl || backUrl;
  const text = history.location.state?.backText || backText;

  const onClickBack: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    const isModifierPressed = isHotkeyPressed("cmd") || isHotkeyPressed("ctrl");
    if (isModifierPressed) {
      return;
    }

    e.preventDefault();

    if (url) {
      history.push(url);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {typeof title === "string" && (
        <Helmet>
          <title>{title} - Stedi Terminal</title>
        </Helmet>
      )}
      <PageHeader
        backText={text && url ? text : undefined}
        backUrl={withBasePathUrl(url)}
        hasParentPage={hasParentPage || false}
        headerProps={headerProps}
        onClickBack={onClickBack}
        title={title}
      >
        {children}
      </PageHeader>
    </>
  );
};
