import {
  InlineObject as MemberInvitationPayload,
  InlineResponse200 as ApiMembersPage,
  InlineResponse200Results as ApiMember,
  InlineResponse2001 as ApiMemberInvitationsPage,
  InlineResponse2001Results as ApiInvitation,
} from "@stedi/sdk-members-20210720";
import ky, { HTTPError } from "ky";
import { forTenant, forUser } from "../../api/authorization";
import { parseDates } from "../../api/client/basic";
import { MEMBERS_HOST, USERS_HOST } from "../../api/config";
import { HTTPResponseError } from "../../api/errors";
import { sortByEmail } from "../../api/utils";
import { captureError } from "../../shared/utils/rum";

const api = ky.extend({ parseJson: (text) => JSON.parse(text, parseDates) });
const client = api.extend({ prefixUrl: `${MEMBERS_HOST}/2021-07-20` });
const users = api.extend({ prefixUrl: USERS_HOST });

export type MemberStatus = "accepted" | "proposed" | "rejected";
export interface AcceptedMember {
  readonly user_id: string;
  readonly account_id: string;
  readonly email: string;
  readonly created_at: string;
  readonly status: "accepted";
}
export interface InvitedMember {
  readonly invitation_id: string;
  readonly account_id: string;
  readonly email: string;
  readonly created_at: string;
  readonly status: "proposed";
}
export type Member = AcceptedMember | InvitedMember;
export interface MembersPage {
  readonly next_page_token?: string;
  readonly results: Member[];
}

type Options = RequestInit & { accountId: string };
const isHTTPError = (error: unknown): error is HTTPError => error instanceof HTTPError;
const toRequestInit = async (options?: Partial<Options>): Promise<RequestInit> => {
  const auth = options?.accountId ? await forTenant(options.accountId) : await forUser();
  return { ...options, headers: { ...options?.headers, ...auth } };
};

type PaginationOptions = { page_size?: number; next_page_token?: string };

export const listAcceptedMembers = async (
  { next_page_token, page_size }: PaginationOptions,
  options: Options,
): Promise<MembersPage> => {
  try {
    const searchParams = {
      ...(page_size && { page_size }),
      ...(next_page_token && { next_page_token }),
    };
    const init = { searchParams, ...(await toRequestInit(options)) };
    const response = await client.get("members", init).json<ApiMembersPage>();
    // Fetch members' email addresses
    const results = (
      await Promise.all(
        response.results.map(async (member) => {
          const { email } = await users.get(`v1/users/${member.user_id}`, init).json<{ email: string }>();
          return {
            account_id: member.account_id,
            user_id: member.user_id,
            email,
            created_at: member.created_at,
            status: "accepted",
          } as const;
        }),
      )
    ).sort(sortByEmail);
    return {
      next_page_token: response.next_page_token,
      results,
    };
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export const listMemberInvitations = async (
  { next_page_token, page_size }: PaginationOptions,
  options: Options,
): Promise<MembersPage> => {
  try {
    const searchParams = {
      ...(page_size && { page_size }),
      ...(next_page_token && { next_page_token }),
    };
    const init = { searchParams, ...(await toRequestInit(options)) };
    const response = await client.get("invitations", init).json<ApiMemberInvitationsPage>();
    return {
      next_page_token: response.next_page_token,
      results: response.results.map((invitation) => ({
        ...invitation,
        status: "proposed",
      })),
    };
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export type CreateMemberInvitationVariables = MemberInvitationPayload;
export const createMemberInvite = async (body: MemberInvitationPayload, options: Options): Promise<InvitedMember> => {
  try {
    const init = await toRequestInit(options);
    const response = await client.post("invitations", { ...init, json: body }).json<ApiInvitation>();
    return {
      ...response,
      status: "proposed",
    };
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export type ResendMemberInvitationVariables = { id: string };
export const resendMemberInvite = async ({ id }: ResendMemberInvitationVariables, options: Options): Promise<void> => {
  try {
    const init = await toRequestInit(options);
    await client.put(`invitations/${id}/resend`, init);
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export type AcceptMemberInvitationVariables = { id: string };
export const acceptMemberInvite = async (
  { id }: AcceptMemberInvitationVariables,
  options?: RequestInit,
): Promise<Omit<AcceptedMember, "email">> => {
  const init = await toRequestInit(options);
  try {
    const response = await client.put(`invitations/${id}/accept`, init).json<ApiMember>();
    return {
      ...response,
      status: "accepted" as const,
    };
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export type RemoveMemberInvitationVariables = { id: string };
export const removeMemberInvite = async ({ id }: RemoveMemberInvitationVariables, options: Options): Promise<void> => {
  try {
    const init = await toRequestInit(options);
    await client.delete(`invitations/${id}`, init);
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export type RemoveMemberVariables = { userId: string };
export const removeMember = async ({ userId }: RemoveMemberVariables, options: Options): Promise<void> => {
  try {
    const init = await toRequestInit(options);
    await client.delete(`members/${userId}`, init);
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};

export const listMemberships = async (
  { next_page_token, page_size }: PaginationOptions,
  options?: RequestInit,
): Promise<ApiMembersPage> => {
  try {
    const searchParams = {
      ...(page_size && { page_size }),
      ...(next_page_token && { next_page_token }),
    };
    const init = { searchParams, ...(await toRequestInit(options)) };
    const response = await client.get("memberships", init).json<ApiMembersPage>();
    return {
      next_page_token: response.next_page_token,
      results: response.results,
    };
  } catch (error) {
    captureError(error);
    if (isHTTPError(error)) {
      throw await HTTPResponseError.create(error.response);
    }
    throw error;
  }
};
