import * as React from "react";
import { LoadingLogo } from "@stedi/dls";
import * as auth from "../../api/authentication";
import { UseGetAwsAuthUser } from "../hooks/authentication";
import { services, useServices } from "./ServicesProvider";

export interface AuthContextProps {
  user: auth.StediUser | undefined;
}

const { createContext } = React;

export const AuthContext = createContext<AuthContextProps>({ user: undefined });

export function AuthProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const getService = useServices();
  const useGetAwsAuthUser = getService<UseGetAwsAuthUser>(services.useGetAwsAuthUser);

  const { isLoading, user } = useGetAwsAuthUser();

  if (isLoading) {
    return <LoadingLogo animate={true} data-testid="waiting-for-user-check" fullscreen={true} />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const { useContext } = React;

export type UseAuth = () => AuthContextProps;

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
