/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * storage
 * Stedi Storage
 * OpenAPI spec version: 2021-11-16
 */
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { storageClient } from "../../../api/client/custom-instances";
import type {
  AccessDeniedResponseContent,
  BadRequestResponseContent,
  CreateDocumentRequestContent,
  CreateDocumentResponseContent,
  CreateDocumentStoreRequestContent,
  CreateDocumentStoreResponseContent,
  GatewayTimeoutResponseContent,
  InternalFailureResponseContent,
  ListDocumentsParams,
  ListDocumentsResponseContent,
  ListDocumentStoresParams,
  ListDocumentStoresResponseContent,
  ListDocumentVersionsParams,
  ListDocumentVersionsResponseContent,
  NotAcceptableResponseContent,
  QuotaExceededResponseContent,
  ReadDocumentResponseContent,
  ReadDocumentStoreResponseContent,
  ReadDocumentVersionResponseContent,
  RequestTooLargeResponseContent,
  ResourceConflictResponseContent,
  ResourceNotFoundResponseContent,
  ServiceUnavailableResponseContent,
  UnauthorizedResponseContent,
  UnsupportedMediaTypeResponseContent,
  UpdateDocumentResponseContent,
  UpdateDocumentStoreRequestContent,
  UpdateDocumentStoreResponseContent,
} from ".././models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * Retrieve a list containing every document store.
 */
export const listDocumentStores = (params?: ListDocumentStoresParams) => {
  return storageClient<ListDocumentStoresResponseContent>({ url: `/document_stores`, method: "get", params });
};

export const getListDocumentStoresQueryKey = (params?: ListDocumentStoresParams) => [
  `/document_stores`,
  ...(params ? [params] : []),
];

export const useListDocumentStoresInfinite = <
  TData = AsyncReturnType<typeof listDocumentStores>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListDocumentStoresParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listDocumentStores>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListDocumentStoresQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listDocumentStores>> = ({ pageParam }) =>
    listDocumentStores({ page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listDocumentStores>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListDocumentStores = <
  TData = AsyncReturnType<typeof listDocumentStores>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListDocumentStoresParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listDocumentStores>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListDocumentStoresQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listDocumentStores>> = () => listDocumentStores(params);

  const query = useQuery<AsyncReturnType<typeof listDocumentStores>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new document store.
 */
export const createDocumentStore = (createDocumentStoreRequestContent: CreateDocumentStoreRequestContent) => {
  return storageClient<CreateDocumentStoreResponseContent>({
    url: `/document_stores`,
    method: "post",
    data: createDocumentStoreRequestContent,
  });
};

export const useCreateDocumentStore = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createDocumentStore>,
    TError,
    { data: CreateDocumentStoreRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createDocumentStore>,
    { data: CreateDocumentStoreRequestContent }
  > = (props) => {
    const { data } = props || {};

    return createDocumentStore(data);
  };

  return useMutation<
    AsyncReturnType<typeof createDocumentStore>,
    TError,
    { data: CreateDocumentStoreRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Delete an existing document store.
 */
export const deleteDocumentStore = (bucketName: string) => {
  return storageClient<void>({ url: `/document_stores/${bucketName}`, method: "delete" });
};

export const useDeleteDocumentStore = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof deleteDocumentStore>, TError, { bucketName: string }, TContext>;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteDocumentStore>, { bucketName: string }> = (props) => {
    const { bucketName } = props || {};

    return deleteDocumentStore(bucketName);
  };

  return useMutation<AsyncReturnType<typeof deleteDocumentStore>, TError, { bucketName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve an existing document store.
 */
export const readDocumentStore = (bucketName: string) => {
  return storageClient<ReadDocumentStoreResponseContent>({ url: `/document_stores/${bucketName}`, method: "get" });
};

export const getReadDocumentStoreQueryKey = (bucketName: string) => [`/document_stores/${bucketName}`];

export const useReadDocumentStoreInfinite = <
  TData = AsyncReturnType<typeof readDocumentStore>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readDocumentStore>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadDocumentStoreQueryKey(bucketName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readDocumentStore>> = () => readDocumentStore(bucketName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readDocumentStore>, TError, TData>(queryKey, queryFn, {
    enabled: !!bucketName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadDocumentStore = <
  TData = AsyncReturnType<typeof readDocumentStore>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readDocumentStore>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadDocumentStoreQueryKey(bucketName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readDocumentStore>> = () => readDocumentStore(bucketName);

  const query = useQuery<AsyncReturnType<typeof readDocumentStore>, TError, TData>(queryKey, queryFn, {
    enabled: !!bucketName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing document store.
 */
export const updateDocumentStore = (
  bucketName: string,
  updateDocumentStoreRequestContent: UpdateDocumentStoreRequestContent,
) => {
  return storageClient<UpdateDocumentStoreResponseContent>({
    url: `/document_stores/${bucketName}`,
    method: "put",
    data: updateDocumentStoreRequestContent,
  });
};

export const useUpdateDocumentStore = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateDocumentStore>,
    TError,
    { bucketName: string; data: UpdateDocumentStoreRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateDocumentStore>,
    { bucketName: string; data: UpdateDocumentStoreRequestContent }
  > = (props) => {
    const { bucketName, data } = props || {};

    return updateDocumentStore(bucketName, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateDocumentStore>,
    TError,
    { bucketName: string; data: UpdateDocumentStoreRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Retrieve a list containing every document.
 */
export const listDocuments = (bucketName: string, params?: ListDocumentsParams) => {
  return storageClient<ListDocumentsResponseContent>({
    url: `/document_stores/${bucketName}/documents`,
    method: "get",
    params,
  });
};

export const getListDocumentsQueryKey = (bucketName: string, params?: ListDocumentsParams) => [
  `/document_stores/${bucketName}/documents`,
  ...(params ? [params] : []),
];

export const useListDocumentsInfinite = <
  TData = AsyncReturnType<typeof listDocuments>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  params?: ListDocumentsParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listDocuments>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListDocumentsQueryKey(bucketName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listDocuments>> = ({ pageParam }) =>
    listDocuments(bucketName, { page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listDocuments>, TError, TData>(queryKey, queryFn, {
    enabled: !!bucketName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListDocuments = <
  TData = AsyncReturnType<typeof listDocuments>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  params?: ListDocumentsParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listDocuments>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListDocumentsQueryKey(bucketName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listDocuments>> = () => listDocuments(bucketName, params);

  const query = useQuery<AsyncReturnType<typeof listDocuments>, TError, TData>(queryKey, queryFn, {
    enabled: !!bucketName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new document.
 */
export const createDocument = (bucketName: string, createDocumentRequestContent: CreateDocumentRequestContent) => {
  return storageClient<CreateDocumentResponseContent>({
    url: `/document_stores/${bucketName}/documents`,
    method: "post",
    data: createDocumentRequestContent,
  });
};

export const useCreateDocument = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createDocument>,
    TError,
    { bucketName: string; data: CreateDocumentRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createDocument>,
    { bucketName: string; data: CreateDocumentRequestContent }
  > = (props) => {
    const { bucketName, data } = props || {};

    return createDocument(bucketName, data);
  };

  return useMutation<
    AsyncReturnType<typeof createDocument>,
    TError,
    { bucketName: string; data: CreateDocumentRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Delete an existing document.
 */
export const deleteDocument = (bucketName: string, key: string) => {
  return storageClient<void>({ url: `/document_stores/${bucketName}/documents/${key}`, method: "delete" });
};

export const useDeleteDocument = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocument>,
    TError,
    { bucketName: string; key: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteDocument>, { bucketName: string; key: string }> = (
    props,
  ) => {
    const { bucketName, key } = props || {};

    return deleteDocument(bucketName, key);
  };

  return useMutation<AsyncReturnType<typeof deleteDocument>, TError, { bucketName: string; key: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Read an existing document.
 */
export const readDocument = (bucketName: string, key: string) => {
  return storageClient<ReadDocumentResponseContent>({
    url: `/document_stores/${bucketName}/documents/${key}`,
    method: "get",
  });
};

export const getReadDocumentQueryKey = (bucketName: string, key: string) => [
  `/document_stores/${bucketName}/documents/${key}`,
];

export const useReadDocumentInfinite = <
  TData = AsyncReturnType<typeof readDocument>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  key: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readDocument>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadDocumentQueryKey(bucketName, key);

  const queryFn: QueryFunction<AsyncReturnType<typeof readDocument>> = () => readDocument(bucketName, key);

  const query = useInfiniteQuery<AsyncReturnType<typeof readDocument>, TError, TData>(queryKey, queryFn, {
    enabled: !!(bucketName && key),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadDocument = <
  TData = AsyncReturnType<typeof readDocument>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  key: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readDocument>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadDocumentQueryKey(bucketName, key);

  const queryFn: QueryFunction<AsyncReturnType<typeof readDocument>> = () => readDocument(bucketName, key);

  const query = useQuery<AsyncReturnType<typeof readDocument>, TError, TData>(queryKey, queryFn, {
    enabled: !!(bucketName && key),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing document.
 */
export const updateDocument = (bucketName: string, key: string) => {
  return storageClient<UpdateDocumentResponseContent>({
    url: `/document_stores/${bucketName}/documents/${key}`,
    method: "put",
  });
};

export const useUpdateDocument = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateDocument>,
    TError,
    { bucketName: string; key: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof updateDocument>, { bucketName: string; key: string }> = (
    props,
  ) => {
    const { bucketName, key } = props || {};

    return updateDocument(bucketName, key);
  };

  return useMutation<AsyncReturnType<typeof updateDocument>, TError, { bucketName: string; key: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve a list containing every document version.
 */
export const listDocumentVersions = (bucketName: string, key: string, params?: ListDocumentVersionsParams) => {
  return storageClient<ListDocumentVersionsResponseContent>({
    url: `/document_stores/${bucketName}/documents/${key}/versions`,
    method: "get",
    params,
  });
};

export const getListDocumentVersionsQueryKey = (
  bucketName: string,
  key: string,
  params?: ListDocumentVersionsParams,
) => [`/document_stores/${bucketName}/documents/${key}/versions`, ...(params ? [params] : [])];

export const useListDocumentVersionsInfinite = <
  TData = AsyncReturnType<typeof listDocumentVersions>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  key: string,
  params?: ListDocumentVersionsParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listDocumentVersions>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListDocumentVersionsQueryKey(bucketName, key, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listDocumentVersions>> = ({ pageParam }) =>
    listDocumentVersions(bucketName, key, { page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listDocumentVersions>, TError, TData>(queryKey, queryFn, {
    enabled: !!(bucketName && key),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListDocumentVersions = <
  TData = AsyncReturnType<typeof listDocumentVersions>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  key: string,
  params?: ListDocumentVersionsParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listDocumentVersions>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListDocumentVersionsQueryKey(bucketName, key, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listDocumentVersions>> = () =>
    listDocumentVersions(bucketName, key, params);

  const query = useQuery<AsyncReturnType<typeof listDocumentVersions>, TError, TData>(queryKey, queryFn, {
    enabled: !!(bucketName && key),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Delete an existing document version.
 */
export const deleteDocumentVersion = (bucketName: string, key: string, versionId: string) => {
  return storageClient<void>({
    url: `/document_stores/${bucketName}/documents/${key}/versions/${versionId}`,
    method: "delete",
  });
};

export const useDeleteDocumentVersion = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocumentVersion>,
    TError,
    { bucketName: string; key: string; versionId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDocumentVersion>,
    { bucketName: string; key: string; versionId: string }
  > = (props) => {
    const { bucketName, key, versionId } = props || {};

    return deleteDocumentVersion(bucketName, key, versionId);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDocumentVersion>,
    TError,
    { bucketName: string; key: string; versionId: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Read an existing document version.
 */
export const readDocumentVersion = (bucketName: string, key: string, versionId: string) => {
  return storageClient<ReadDocumentVersionResponseContent>({
    url: `/document_stores/${bucketName}/documents/${key}/versions/${versionId}`,
    method: "get",
  });
};

export const getReadDocumentVersionQueryKey = (bucketName: string, key: string, versionId: string) => [
  `/document_stores/${bucketName}/documents/${key}/versions/${versionId}`,
];

export const useReadDocumentVersionInfinite = <
  TData = AsyncReturnType<typeof readDocumentVersion>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  key: string,
  versionId: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readDocumentVersion>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadDocumentVersionQueryKey(bucketName, key, versionId);

  const queryFn: QueryFunction<AsyncReturnType<typeof readDocumentVersion>> = () =>
    readDocumentVersion(bucketName, key, versionId);

  const query = useInfiniteQuery<AsyncReturnType<typeof readDocumentVersion>, TError, TData>(queryKey, queryFn, {
    enabled: !!(bucketName && key && versionId),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadDocumentVersion = <
  TData = AsyncReturnType<typeof readDocumentVersion>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  bucketName: string,
  key: string,
  versionId: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readDocumentVersion>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadDocumentVersionQueryKey(bucketName, key, versionId);

  const queryFn: QueryFunction<AsyncReturnType<typeof readDocumentVersion>> = () =>
    readDocumentVersion(bucketName, key, versionId);

  const query = useQuery<AsyncReturnType<typeof readDocumentVersion>, TError, TData>(queryKey, queryFn, {
    enabled: !!(bucketName && key && versionId),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
