import * as React from 'react';

function useTimeout(callback, delay) {
  const savedCallback = React.useRef(); // Remember the latest callback.

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // Set up the interval.

  React.useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }

    return () => {};
  }, [delay]);
}

export { useTimeout };
