import styled from "@emotion/styled";
import { color, CopyToClipboardButton, monospace, size, space } from "@stedi/dls";
import ClipboardIcon from "remixicon-react/FileCopyLineIcon";

const SIdCell = styled.div({
  ...monospace(),
  color: color.c40,
  display: "flex",
  alignItems: "center",
  maxWidth: "100%",
  fontSize: size.s2,
  "> button": {
    opacity: 0,
    color: color.c30,
    margin: 0,
    padding: 0,
    marginLeft: space.s1,
    flex: "none",
  },
  "> span": {
    flex: "1 1 auto",
    whiteSpace: "normal",
  },
  ":hover > button": {
    opacity: 1,
  },
});

const LogMessageCell = ({ type, value }: { value: string; type: string }) => (
  <SIdCell>
    <span>{value}</span>
    <CopyToClipboardButton copyMessage={`Copy ${type} ID to clipboard`} value={value}>
      <ClipboardIcon size={11} />
    </CopyToClipboardButton>
  </SIdCell>
);

export default LogMessageCell;
