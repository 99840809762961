import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { HighlighterProvider } from "@stedi/edi-components";
import { defaultServices } from "../services";
import { AnalyticsProvider } from "./AnalyticsProvider";
import { AuthProvider } from "./AuthProvider";
import { GlobalDropzoneProvider } from "./GlobalDropzoneProvider";
import { ServicesProvider } from "./ServicesProvider";

export const getReactQueryProvider = () => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false }, mutations: { retry: false } },
  });
  return ({ children }: { children: any }) => (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export function AppProviders({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const ReactQueryProvider = getReactQueryProvider();

  return (
    <ReactQueryProvider>
      <ServicesProvider value={defaultServices}>
        <AuthProvider>
          <AnalyticsProvider>
            <DndProvider backend={HTML5Backend}>
              <GlobalDropzoneProvider>
                <HighlighterProvider>{children}</HighlighterProvider>
              </GlobalDropzoneProvider>
            </DndProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </ServicesProvider>
    </ReactQueryProvider>
  );
}
