import { BroadcastChannel } from "broadcast-channel";

export interface SignOut {
  messageType: "signed-out";
}

export interface ConfirmEmail {
  messageType: "confirmed-email";
}

export type Message = SignOut | ConfirmEmail;
export type Listener = (msg: Message) => void;

export interface Channel {
  sendMessage: (msg: Message) => Promise<void>;
  addMessageListener: (fn: Listener) => void;
  removeMessageListener: (fn: Listener) => void;
  removeAllListeners: () => void;
  close: () => Promise<void>;
}

let instance: Channel;

const isJsdom = () => typeof navigator === "object" && navigator.userAgent.includes("jsdom");

const defaultInstance = (): Channel => {
  if (!instance) {
    const channel = new BroadcastChannel("stedi-terminal", {
      // https://github.com/pubkey/broadcast-channel#methods
      type: isJsdom() ? "simulate" : undefined,
    });

    let listeners = new Array<Listener>();
    let closed = false;

    const assertNotClosed = (): void => {
      if (closed) {
        throw new Error(`channel closed`);
      }
    };

    instance = {
      sendMessage: (message: Message) => {
        assertNotClosed();

        listeners.forEach((listener) => listener(message));
        return channel.postMessage(message);
      },
      addMessageListener: (fn: Listener) => {
        assertNotClosed();

        channel.addEventListener("message", fn);
        listeners.push(fn);
      },

      removeMessageListener: (fn: Listener) => {
        assertNotClosed();

        channel.removeEventListener("message", fn);
        listeners = listeners.filter((listener) => listener !== fn);
      },

      removeAllListeners: () => {
        assertNotClosed();

        listeners.forEach((listener) => channel.removeEventListener("message", listener));
        listeners = [];
      },

      close: async () => {
        if (!closed) {
          await channel.close();
          closed = true;
        }
      },
    };
  }
  return instance;
};

export const sendMessage: Channel["sendMessage"] = (message: Message) => defaultInstance().sendMessage(message);

export const addMessageListener: Channel["addMessageListener"] = (fn: Listener) =>
  defaultInstance().addMessageListener(fn);

export const removeMessageListener: Channel["removeMessageListener"] = (fn: Listener) =>
  defaultInstance().removeMessageListener(fn);

export const removeAllListeners: Channel["removeAllListeners"] = () => defaultInstance().removeAllListeners();

export const close: Channel["close"] = async () => {
  if (instance) {
    await instance.close();
  }
};
