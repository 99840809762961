import { __rest } from '../../node_modules/tslib/tslib.es6.js';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import * as React from 'react';
import { Popover } from './Popover.js';
import { TextLinkButton } from './TextLink.js';

const ConfirmationPopover = _a => {
  var {
    actionText,
    confirmationText,
    onConfirm
  } = _a,
      popoverProps = __rest(_a, ["actionText", "confirmationText", "onConfirm"]);

  const confirmationButtonRef = React.useRef(null);

  const handleOnShow = function () {
    var _a;

    window.requestAnimationFrame(() => {
      var _a;

      (_a = confirmationButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    });

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    (_a = popoverProps.onShow) === null || _a === void 0 ? void 0 : _a.call(popoverProps, ...args);
  };

  return jsx(Popover, Object.assign({
    onShow: handleOnShow,
    placement: "bottom-end"
  }, popoverProps, {
    render: jsxs(Fragment, {
      children: [confirmationText, " ", jsxs(TextLinkButton, Object.assign({
        ref: confirmationButtonRef,
        color: "red",
        onClick: onConfirm
      }, {
        children: ["Yes, ", actionText]
      }))]
    })
  }));
};

export { ConfirmationPopover };
