import * as React from "react";
import { LoadMoreButton, useIntersectionObserver } from "@stedi/dls";

interface LoadMoreTriggerProps {
  fetchNextPage: VoidFunction;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
}

export const LoadMoreTrigger = ({ fetchNextPage, hasNextPage, isFetchingNextPage }: LoadMoreTriggerProps) => {
  const loadMoreButtonRef = React.useRef<HTMLDivElement | null>(null);

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: () => fetchNextPage(),
    enabled: !!hasNextPage && !isFetchingNextPage,
    rootMargin: "300px",
  });

  return (
    <LoadMoreButton
      ref={loadMoreButtonRef}
      canFetchMore={hasNextPage}
      fetchMore={() => fetchNextPage()}
      isFetchingMore={isFetchingNextPage}
    />
  );
};
