import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { InlineResponse200Results as ApiMember } from "@stedi/sdk-members-20210720";
import * as accountsApi from "../api/accounts";
import { Account } from "../api/accounts";
import * as api from "../members/api";

export const MEMBERSHIPS_QUERY_KEY = "memberships";
export const MEMBERSHIPS_ACCOUNTS_QUERY_KEY = "membershipsAccounts";
export const MEMBERSHIPS_ACCOUNTS_INFINITE_QUERY_KEY = "membershipsAccountsInf";
// TODO(tommy): change to InfiniteData
export type MembershipsListCacheType = Array<ApiMember>;
export type MembershipsWithAccountsListCacheType = Array<MembershipWithAccount>;
export type MembershipsWithAccountsInfiniteListCacheType = InfiniteData<MembershipWithAccountPage>;

// // Default number in milliseconds for refetching data
// const REFETCH_INTERVAL_IN_MS = 60000;

/*
 * Until we push new memberships out via websockets, make sure we keep them
 * up to date in case we a memberships is added in another window
 * TODO: Attach this pollInterval to the window being focused
 */
const MEMBERSHIPS_POLL_INTERVAL = 5 * 60 * 1000;

export interface MembershipWithAccount extends ApiMember {
  readonly account?: Account;
}

export interface MembershipWithAccountPage {
  readonly results: Array<MembershipWithAccount>;
  readonly next_page_token?: string;
}

const getNextPageParam = (lastPage: { next_page_token?: string }) => lastPage.next_page_token;
const basePaginationOptions = {
  refetchOnMount: true,
  refetchInterval: 120 * 1000,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  getNextPageParam,
};

export const useInfiniteListMembershipsAndAccounts = (
  options: UseInfiniteQueryOptions<MembershipWithAccountPage, Error> = {},
) => {
  return useInfiniteQuery<MembershipWithAccountPage, Error>(
    [MEMBERSHIPS_ACCOUNTS_INFINITE_QUERY_KEY],
    ({ pageParam }) =>
      api
        .listMemberships({ page_size: 50, next_page_token: pageParam })
        .then(async (page) => ({ ...page, results: await fetchAccountNames(page.results) })),
    { ...basePaginationOptions, ...options },
  );
};

const fetchAccountNames = async (memberships: ReadonlyArray<ApiMember>): Promise<Array<MembershipWithAccount>> => {
  const accountIds = memberships.map((membership) => membership.account_id);
  const accounts = await accountsApi.getAccounts(accountIds);

  return memberships
    .map((membership) => {
      return {
        ...membership,
        account: accounts.find((account) => account.id === membership.account_id),
      };
    })
    .sort((a, b) => {
      if (!a.account?.name) return 1;
      if (!b.account?.name) return -1;
      return a.account.name.localeCompare(b.account.name);
    });
};

// TODO(tommy): migrate listMemberships to useInfiniteQuery.
// Because this hook is used in AuthenticatedRoutes, I want to make the change in its own PR, since this
// change is already very large.
const fetchAllMemberships = async (): Promise<Array<ApiMember>> => {
  const results: Array<ApiMember> = [];
  const paginationOptions: { readonly page_size: number; next_page_token?: string } = { page_size: 100 };
  do {
    // eslint-disable-next-line no-await-in-loop
    const response = await api.listMemberships(paginationOptions);
    results.push(...response.results);
    paginationOptions.next_page_token = response.next_page_token;
  } while (paginationOptions.next_page_token);

  return results;
};

export const useListMembershipsAndAccounts = (
  options: UseQueryOptions<MembershipsWithAccountsListCacheType, Error> = {},
) => {
  const membershipsQuery = useListMemberships(options);

  const query = useQuery<Array<MembershipWithAccount>, Error>(
    [MEMBERSHIPS_ACCOUNTS_QUERY_KEY],
    async () => {
      const memberships = membershipsQuery.data;
      if (!memberships) return [];
      return fetchAccountNames(memberships);
    },
    {
      ...options,
      enabled: !membershipsQuery.isLoading,
    },
  );
  return {
    ...query,
    isLoading: membershipsQuery.isLoading || query.isLoading,
    error: membershipsQuery.error || query.error,
  };
};

export type UseListMembershipsAndAccounts = typeof useListMembershipsAndAccounts;

export const useListMemberships = (options: UseQueryOptions<Array<ApiMember>, Error> = {}) => {
  return useQuery<MembershipsListCacheType, Error>([MEMBERSHIPS_QUERY_KEY], () => fetchAllMemberships(), {
    staleTime: 10000,
    refetchInterval: MEMBERSHIPS_POLL_INTERVAL,
    refetchOnWindowFocus: false,
    ...options,
  });
};
