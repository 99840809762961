import { isTest } from "./environment";

export interface MonitoredFetch {
  (...args: Parameters<typeof fetch>): ReturnType<typeof fetch>;
  inflightRequests: Record<string, unknown>;
  stop(): Promise<void>;
}

export class MonitoredFetch {
  public requests: Promise<unknown>[] = [];

  public inflightRequests: Record<string, unknown> = {};

  private stopped = false;

  static create(isTestEnvironment = isTest()) {
    if (!isTestEnvironment) return fetch;

    const f = async function spy(this: MonitoredFetch, ...args: Parameters<typeof fetch>) {
      if (f.stopped) {
        throw new Error(
          `cannot send request because the test runner has stopped:\n${(args[1] as RequestInit).method || "GET"} ${
            args[0] as RequestInfo
          }\n`,
        );
      }

      /**
       * This function will run only while tests are running, thus
       * it is safe to use `jest.XX` methods.
       *
       * Ensures that the uuid is not mocked.
       */
      // Using @ts-ignore since TS is complaining but this will never execute outside of tests
      // @ts-ignore
      const { v4: uuid } = jest.requireActual("uuid");

      const requestId = uuid();
      f.inflightRequests[requestId] = args;

      const request = fetch(...args).finally(() => {
        delete f.inflightRequests[requestId];
      });

      f.requests.push(request);

      return request;
    } as MonitoredFetch;

    const monitoredFetch = new MonitoredFetch();
    Object.assign(f, monitoredFetch);
    Object.setPrototypeOf(f, MonitoredFetch.prototype);

    return f;
  }

  get totalRequests() {
    return this.requests.length;
  }

  public async stop() {
    this.stopped = true;
    await Promise.allSettled(this.requests);
  }
}

export const mfetch = MonitoredFetch.create();
