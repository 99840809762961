import { color, size, lineHeight, space, fontFamily } from '../../constants.js';
import { fontWeight } from '../utils/index.js';
import { focusStyles } from './focusStyles.js';

const bodyTextMargin = {
  marginBottom: `${lineHeight.body}em`,
  "&:last-child": {
    marginBottom: 0
  }
};
const listItemMargin = {
  marginBottom: `${lineHeight.body / 2}em`,
  "&:last-child": {
    marginBottom: 0
  }
};
const blockMargin = {
  marginTop: `${lineHeight.body}em`,
  marginBottom: `${lineHeight.body}em`,
  "&:first-child": {
    marginBottom: 0
  },
  "&:last-child": {
    marginBottom: 0
  }
};
const bodyTextStyles = Object.assign(Object.assign({}, bodyTextMargin), {
  color: color.gray13,
  fontSize: size.base,
  lineHeight: lineHeight.body
});
const listStyles = Object.assign(Object.assign({}, bodyTextStyles), {
  marginLeft: "1.75em"
});
const subHeadingStyles = Object.assign(Object.assign({}, fontWeight("semibold")), {
  color: color.gray14,
  letterSpacing: "-0.01em",
  lineHeight: lineHeight.subheading,
  marginTop: `${lineHeight.subheading * 1.25}em`,
  marginBottom: `${lineHeight.subheading * 0.75}em`,
  "&:first-child": {
    marginTop: 0
  },
  "&:last-child": {
    marginBottom: 0
  }
});
const headingStyles = Object.assign(Object.assign({}, subHeadingStyles), {
  letterSpacing: "-0.015em",
  lineHeight: lineHeight.heading,
  marginTop: `${lineHeight.heading * 1.25}em`,
  marginBottom: `${lineHeight.heading * 0.75}em`
});
const address = Object.assign(Object.assign({}, bodyTextStyles), {
  fontStyle: "normal"
});
const blockquote = Object.assign(Object.assign({}, bodyTextStyles), {
  boxShadow: `inset 4px 0 0 0 ${color.gray6}`,
  paddingLeft: `${lineHeight.body * 0.75}em`,
  footer: Object.assign(Object.assign({}, bodyTextMargin), {
    fontSize: size.small
  })
});
const p = Object.assign({}, bodyTextStyles);
const dl = bodyTextStyles;
const dt = Object.assign(Object.assign({}, fontWeight("semibold")), listItemMargin);
const dd = listItemMargin;
const ul = Object.assign(Object.assign({}, listStyles), {
  listStyleType: "square"
});
const ol = Object.assign(Object.assign({}, listStyles), {
  listStyleType: "decimal"
});
const li = Object.assign(Object.assign({}, listItemMargin), {
  paddingLeft: "0.25em"
});
const h1 = Object.assign(Object.assign({}, headingStyles), {
  fontSize: size.h1
});
const h2 = Object.assign(Object.assign({}, subHeadingStyles), {
  fontSize: size.h2
});
const h3 = Object.assign(Object.assign({}, subHeadingStyles), {
  fontSize: size.h3
});
const h4 = Object.assign(Object.assign({}, subHeadingStyles), {
  fontSize: size.h4
});
const h5 = Object.assign(Object.assign({}, subHeadingStyles), {
  fontSize: size.h5,
  lineHeight: lineHeight.body
});
const h6 = Object.assign(Object.assign({}, subHeadingStyles), {
  fontSize: size.h6,
  lineHeight: lineHeight.body
});
const hr = {
  border: "none",
  borderTop: `1px solid ${color.gray6}`,
  marginTop: `${lineHeight.body}em`,
  marginBottom: `${lineHeight.body}em`,
  opacity: 0.6
};
const mark = {
  borderRadius: 2,
  backgroundColor: color.green3,
  padding: "0.125em 0.25em"
};
const pre = Object.assign(Object.assign(Object.assign({}, bodyTextStyles), blockMargin), {
  backgroundColor: color.gray3,
  borderRadius: space.s1,
  display: "block",
  fontFamily: fontFamily.mono,
  overflowX: "auto",
  padding: space.s3
});
const code = Object.assign(Object.assign({}, fontWeight("medium")), {
  color: color.blue,
  fontFamily: fontFamily.mono
});
const strong = fontWeight("semibold");
const em = {
  fontStyle: "italic"
};
const sub = {
  fontSize: size.small
};
const sup = {
  fontSize: size.small
};
const small = {
  color: color.gray10,
  fontSize: size.small
};
const a = Object.assign(Object.assign({}, fontWeight("medium")), {
  backgroundColor: "transparent",
  borderRadius: 2,
  color: color.green,
  cursor: "pointer",
  margin: 0,
  padding: 0,
  textDecoration: "underline",
  textDecorationColor: color.green9,
  textDecorationThickness: 1,
  textUnderlinePosition: "from-font",
  transition: focusStyles().transition,
  "&:hover": {
    textDecorationThickness: 2
  },
  "&:focus-visible": Object.assign(Object.assign({}, focusStyles().ring), {
    outline: "none",
    textDecoration: "none"
  })
});
const details = Object.assign(Object.assign({}, bodyTextStyles), {
  backgroundColor: color.gray1,
  border: `1px solid ${color.gray6}`,
  borderRadius: space.s1,
  paddingLeft: space.s3,
  paddingRight: space.s3,
  summary: {
    alignItems: "center",
    backgroundColor: color.gray4,
    borderRadius: 1,
    cursor: "pointer",
    display: "flex",
    outline: "none",
    marginLeft: -space.s3,
    marginRight: -space.s3,
    paddingBottom: space.s2,
    paddingLeft: space.s2,
    paddingRight: space.s3,
    paddingTop: space.s2,
    transition: `background-color 80ms ease-in-out, ${focusStyles().transition}`,
    "::-webkit-details-marker": {
      display: "none"
    },
    "&:hover": {
      backgroundColor: color.gray5
    },
    "&:active": {
      backgroundColor: color.gray6
    },
    "&:focus-visible": focusStyles().ring,
    "&:before": Object.assign(Object.assign({}, fontWeight("semibold")), {
      content: '"›"',
      alignItems: "center",
      display: "flex",
      height: size.s2,
      justifyContent: "center",
      marginRight: space.s2,
      width: size.s2,
      transition: "transform 160ms ease-in-out"
    })
  },
  "*:not(summary)": {
    opacity: 0,
    transition: "opacity 240ms ease-out"
  },
  "&[open]": {
    paddingBottom: space.s3,
    summary: {
      borderBottom: `1px solid ${color.gray6}`,
      borderRadius: "1px 1px 0 0",
      marginBottom: space.s3,
      "&:before": {
        transform: "rotate(90deg)"
      }
    },
    "*:not(summary)": {
      opacity: 1
    }
  }
});
const table = Object.assign(Object.assign(Object.assign({}, bodyTextStyles), blockMargin), {
  borderCollapse: "separate",
  borderSpacing: 0,
  "&:last-child": {
    marginBottom: 0
  },
  width: "100%",
  "th, td": {
    borderBottom: `1px solid ${color.gray6}`,
    lineHeight: lineHeight.body,
    overflowWrap: "break-word",
    paddingTop: `${lineHeight.body / 2}em`,
    paddingBottom: `${lineHeight.body / 2}em`,
    paddingRight: space.s3,
    textAlign: "left"
  },
  "thead:last-child, tbody:last-child, tfoot:last-child": {
    "tr:last-child td, tr:last-child th": {
      borderBottom: "none"
    }
  },
  "& > tr:last-child td, & > tr:last-child th": {
    borderBottom: "none"
  },
  td: {
    verticalAlign: "top"
  },
  th: Object.assign(Object.assign({}, fontWeight("medium")), {
    color: color.gray14,
    verticalAlign: "bottom"
  })
});

export { a, address, blockquote, code, dd, details, dl, dt, em, h1, h2, h3, h4, h5, h6, hr, li, mark, ol, p, pre, small, strong, sub, sup, table, ul };
