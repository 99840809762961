const removeTrailingSlash = (value: string) => {
  return value.replace(/\/$/, "");
};

const removeLeadingSlash = (url: string) => (url.startsWith("/") ? url.slice(1) : url);

export const basePath = removeTrailingSlash(process.env["ASSET_PATH"] || "/terminal");

export const withBasePathUrl = (url?: string) => (url ? `${basePath}/${removeLeadingSlash(url)}` : undefined);

export const withBasePath = (relPath: string) => {
  return `${basePath}${relPath}`;
};

export function searchString(params: Record<string, string>): string {
  return new URLSearchParams(params).toString();
}

export function getParam(paramName: string, locationSearch = ""): string {
  const params = new URLSearchParams(locationSearch);
  return params.get(paramName) ?? "";
}

export function parseQueryString(locationSearch: string = "") {
  const params = new URLSearchParams(locationSearch);
  return Object.fromEntries(params.entries());
}

export function mergePathWithQueryParams(
  pathPossiblyContainingParams: string,
  additionalParamsToMerge: Record<string, string>,
) {
  const [pathname, queryStringFromPath] = pathPossiblyContainingParams.split("?");

  let unifiedParams: URLSearchParams | null = null;

  if (queryStringFromPath) {
    unifiedParams = new URLSearchParams(queryStringFromPath);
  }

  if (additionalParamsToMerge) {
    if (unifiedParams) {
      Object.entries(additionalParamsToMerge).forEach(([key, value]) => {
        // @ts-ignore This messes up typescript so much - if you expect error, it fails, if you remove it, it fails.
        unifiedParams.set(key, value);
      });
    } else {
      unifiedParams = new URLSearchParams(additionalParamsToMerge);
    }

    unifiedParams.sort(); // ensure consistency in tests
  }

  return unifiedParams ? `${pathname}?${unifiedParams.toString()}` : pathname;
}
