import React, { createContext } from "react";

export interface ServicesCollection {
  [key: string]: Function;
}
/**
 * Provides a list of const aliases for services in the DI container.
 * To add a new service to the DI container:
 * - Add an alias here
 * - import the implementation of the service into `src/services.ts`
 * - provide a mock implementation in `src/test/utils/render` if applicable.
 */
export const services = {
  useGetCurrentAccountId: "useGetCurrentAccountId",
  useAuth: "useAuth",
  useStediNavigate: "useStediNavigate",
  useStediRouter: "useStediRouter",
  useGetAwsAuthUser: "useGetAwsAuthUser",
  useGetAccount: "useGetAccount",
  isProd: "isProd",
  isPreprod: "isPreprod",
  useUserHasAccessToAccount: "useUserHasAccessToAccount",
  useListMembershipsAndAccounts: "useListMembershipsAndAccounts",
};

const ServicesContext = createContext(undefined as unknown as ServicesCollection);

/**
 * Provides access to injected common services, defined in `src/services.ts`.
 */
export const ServicesProvider = ServicesContext.Provider;

export const ServicesConsumer = ServicesContext.Consumer;

type GetService = <T extends Function>(name: string) => T;

/**
 * `useServices` provides access to a lightweight dependency injection container.
 * The container can be used to replace common hooks that are deeply nested within the
 * component tree with mocks for testing.
 *
 * The service container also provides a contract between common infrastructure
 * (for instance, authentication code) and your business logic. Prefer resolving these
 * dependencies with `getService` instead of via static imports.
 *
 * Usage:
 * ```
 * function myComponent() {
 *   const getService = useServices()
 *   const myService = getService<MyServiceInterface>(services.myService);
 *   const result = myService();
 *   ...
 * }
 * ```
 * @returns @type {GetService} - a function for resolving a service from the DI container
 */
export const useServices = () => {
  const context = React.useContext(ServicesContext);

  if (context === undefined) {
    throw new Error("useServices hook must be used within a <ServicesProvider>");
  }

  const getService: GetService = function getService<T extends Function>(name: string) {
    if (name in context) {
      return context[name] as T;
    }

    throw new Error(`The service ${name} was not registered`);
  };

  return getService;
};

type ServiceProviderWrapper = ({ children }: { children: React.ReactChildren }) => JSX.Element;

/**
 *
 * @param serviceCollection
 * @param Wrapper an optional wrapping component. Useful when composing several Providers
 * @returns @type {ServicesProvider}
 */
export function getServicesProvider(serviceCollection: Partial<ServicesCollection>, Wrapper?: ServiceProviderWrapper) {
  return ({ children }: { children: React.ReactChildren }) => (
    <ServicesContext.Provider value={serviceCollection as ServicesCollection}>
      {Wrapper ? <Wrapper>{children}</Wrapper> : children}
    </ServicesContext.Provider>
  );
}
