import * as React from "react";
import styled from "@emotion/styled";
import { flex, size, Tooltip, truncate } from "@stedi/dls";
import dayjs from "../../utils/dayjs";

const STimestamp = styled.span({ fontSize: size.s2, ...flex("center"), ...truncate() });

const TimestampCell = ({
  format = "lll",
  timestampStyles,
  tooltipFormat = "LLL UTC",
  value,
}: {
  value: Date;
  format?: string;
  tooltipFormat?: string;
  timestampStyles?: React.CSSProperties;
}) => (
  <Tooltip content={dayjs(value).utc().format(tooltipFormat)}>
    <STimestamp style={timestampStyles}>{dayjs(value).format(format)}</STimestamp>
  </Tooltip>
);

export default TimestampCell;
