/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * transports
 * Stedi Transports
 * OpenAPI spec version: 2021-11-16
 */
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { transportsClient } from "../../../api/client/custom-instances";
import type {
  ClientExceptionResponseContent,
  CreateSftpServerRequestContent,
  CreateSftpServerResponseContent,
  CreateSftpUserRequestContent,
  CreateSftpUserResponseContent,
  ListSftpServersParams,
  ListSftpServersResponseContent,
  ListSftpUsersParams,
  ListSftpUsersResponseContent,
  ReadSftpServerResponseContent,
  ReadSftpUserResponseContent,
  UpdateSftpServerResponseContent,
  UpdateSftpUserRequestContent,
  UpdateSftpUserResponseContent,
} from ".././models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * Retrieve a list containing every sftp server.
 */
export const listSftpServers = (params?: ListSftpServersParams) => {
  return transportsClient<ListSftpServersResponseContent>({ url: `/sftp_servers`, method: "get", params });
};

export const getListSftpServersQueryKey = (params?: ListSftpServersParams) => [
  `/sftp_servers`,
  ...(params ? [params] : []),
];

export const useListSftpServersInfinite = <
  TData = AsyncReturnType<typeof listSftpServers>,
  TError = ClientExceptionResponseContent,
>(
  params?: ListSftpServersParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listSftpServers>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListSftpServersQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listSftpServers>> = ({ pageParam }) =>
    listSftpServers({ page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listSftpServers>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListSftpServers = <
  TData = AsyncReturnType<typeof listSftpServers>,
  TError = ClientExceptionResponseContent,
>(
  params?: ListSftpServersParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listSftpServers>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListSftpServersQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listSftpServers>> = () => listSftpServers(params);

  const query = useQuery<AsyncReturnType<typeof listSftpServers>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new sftp server.
 */
export const createSftpServer = (createSftpServerRequestContent: CreateSftpServerRequestContent) => {
  return transportsClient<CreateSftpServerResponseContent>({
    url: `/sftp_servers`,
    method: "post",
    data: createSftpServerRequestContent,
  });
};

export const useCreateSftpServer = <TError = ClientExceptionResponseContent, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createSftpServer>,
    TError,
    { data: CreateSftpServerRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createSftpServer>,
    { data: CreateSftpServerRequestContent }
  > = (props) => {
    const { data } = props || {};

    return createSftpServer(data);
  };

  return useMutation<
    AsyncReturnType<typeof createSftpServer>,
    TError,
    { data: CreateSftpServerRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Delete an existing sftp server.
 */
export const deleteSftpServer = (sftpTransportName: string) => {
  return transportsClient<void>({ url: `/sftp_servers/${sftpTransportName}`, method: "delete" });
};

export const useDeleteSftpServer = <TError = ClientExceptionResponseContent, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteSftpServer>,
    TError,
    { sftpTransportName: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteSftpServer>, { sftpTransportName: string }> = (
    props,
  ) => {
    const { sftpTransportName } = props || {};

    return deleteSftpServer(sftpTransportName);
  };

  return useMutation<AsyncReturnType<typeof deleteSftpServer>, TError, { sftpTransportName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve an existing sftp server.
 */
export const readSftpServer = (sftpTransportName: string) => {
  return transportsClient<ReadSftpServerResponseContent>({ url: `/sftp_servers/${sftpTransportName}`, method: "get" });
};

export const getReadSftpServerQueryKey = (sftpTransportName: string) => [`/sftp_servers/${sftpTransportName}`];

export const useReadSftpServerInfinite = <
  TData = AsyncReturnType<typeof readSftpServer>,
  TError = ClientExceptionResponseContent,
>(
  sftpTransportName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readSftpServer>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadSftpServerQueryKey(sftpTransportName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readSftpServer>> = () => readSftpServer(sftpTransportName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readSftpServer>, TError, TData>(queryKey, queryFn, {
    enabled: !!sftpTransportName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadSftpServer = <
  TData = AsyncReturnType<typeof readSftpServer>,
  TError = ClientExceptionResponseContent,
>(
  sftpTransportName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readSftpServer>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadSftpServerQueryKey(sftpTransportName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readSftpServer>> = () => readSftpServer(sftpTransportName);

  const query = useQuery<AsyncReturnType<typeof readSftpServer>, TError, TData>(queryKey, queryFn, {
    enabled: !!sftpTransportName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing sftp server.
 */
export const updateSftpServer = (sftpTransportName: string) => {
  return transportsClient<UpdateSftpServerResponseContent>({
    url: `/sftp_servers/${sftpTransportName}`,
    method: "put",
  });
};

export const useUpdateSftpServer = <TError = ClientExceptionResponseContent, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateSftpServer>,
    TError,
    { sftpTransportName: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof updateSftpServer>, { sftpTransportName: string }> = (
    props,
  ) => {
    const { sftpTransportName } = props || {};

    return updateSftpServer(sftpTransportName);
  };

  return useMutation<AsyncReturnType<typeof updateSftpServer>, TError, { sftpTransportName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve a list containing every sftp user.
 */
export const listSftpUsers = (sftpTransportName: string, params?: ListSftpUsersParams) => {
  return transportsClient<ListSftpUsersResponseContent>({
    url: `/sftp_servers/${sftpTransportName}/users`,
    method: "get",
    params,
  });
};

export const getListSftpUsersQueryKey = (sftpTransportName: string, params?: ListSftpUsersParams) => [
  `/sftp_servers/${sftpTransportName}/users`,
  ...(params ? [params] : []),
];

export const useListSftpUsersInfinite = <
  TData = AsyncReturnType<typeof listSftpUsers>,
  TError = ClientExceptionResponseContent,
>(
  sftpTransportName: string,
  params?: ListSftpUsersParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listSftpUsers>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListSftpUsersQueryKey(sftpTransportName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listSftpUsers>> = ({ pageParam }) =>
    listSftpUsers(sftpTransportName, { page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listSftpUsers>, TError, TData>(queryKey, queryFn, {
    enabled: !!sftpTransportName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListSftpUsers = <
  TData = AsyncReturnType<typeof listSftpUsers>,
  TError = ClientExceptionResponseContent,
>(
  sftpTransportName: string,
  params?: ListSftpUsersParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listSftpUsers>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListSftpUsersQueryKey(sftpTransportName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listSftpUsers>> = () => listSftpUsers(sftpTransportName, params);

  const query = useQuery<AsyncReturnType<typeof listSftpUsers>, TError, TData>(queryKey, queryFn, {
    enabled: !!sftpTransportName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new sftp user.
 */
export const createSftpUser = (
  sftpTransportName: string,
  createSftpUserRequestContent: CreateSftpUserRequestContent,
) => {
  return transportsClient<CreateSftpUserResponseContent>({
    url: `/sftp_servers/${sftpTransportName}/users`,
    method: "post",
    data: createSftpUserRequestContent,
  });
};

export const useCreateSftpUser = <TError = ClientExceptionResponseContent, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createSftpUser>,
    TError,
    { sftpTransportName: string; data: CreateSftpUserRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createSftpUser>,
    { sftpTransportName: string; data: CreateSftpUserRequestContent }
  > = (props) => {
    const { data, sftpTransportName } = props || {};

    return createSftpUser(sftpTransportName, data);
  };

  return useMutation<
    AsyncReturnType<typeof createSftpUser>,
    TError,
    { sftpTransportName: string; data: CreateSftpUserRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Delete an existing sftp user.
 */
export const deleteSftpUser = (sftpTransportName: string, sftpUserName: string) => {
  return transportsClient<void>({ url: `/sftp_servers/${sftpTransportName}/users/${sftpUserName}`, method: "delete" });
};

export const useDeleteSftpUser = <TError = ClientExceptionResponseContent, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteSftpUser>,
    TError,
    { sftpTransportName: string; sftpUserName: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteSftpUser>,
    { sftpTransportName: string; sftpUserName: string }
  > = (props) => {
    const { sftpTransportName, sftpUserName } = props || {};

    return deleteSftpUser(sftpTransportName, sftpUserName);
  };

  return useMutation<
    AsyncReturnType<typeof deleteSftpUser>,
    TError,
    { sftpTransportName: string; sftpUserName: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Read an existing sftp user.
 */
export const readSftpUser = (sftpTransportName: string, sftpUserName: string) => {
  return transportsClient<ReadSftpUserResponseContent>({
    url: `/sftp_servers/${sftpTransportName}/users/${sftpUserName}`,
    method: "get",
  });
};

export const getReadSftpUserQueryKey = (sftpTransportName: string, sftpUserName: string) => [
  `/sftp_servers/${sftpTransportName}/users/${sftpUserName}`,
];

export const useReadSftpUserInfinite = <
  TData = AsyncReturnType<typeof readSftpUser>,
  TError = ClientExceptionResponseContent,
>(
  sftpTransportName: string,
  sftpUserName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readSftpUser>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadSftpUserQueryKey(sftpTransportName, sftpUserName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readSftpUser>> = () =>
    readSftpUser(sftpTransportName, sftpUserName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readSftpUser>, TError, TData>(queryKey, queryFn, {
    enabled: !!(sftpTransportName && sftpUserName),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadSftpUser = <TData = AsyncReturnType<typeof readSftpUser>, TError = ClientExceptionResponseContent>(
  sftpTransportName: string,
  sftpUserName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readSftpUser>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadSftpUserQueryKey(sftpTransportName, sftpUserName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readSftpUser>> = () =>
    readSftpUser(sftpTransportName, sftpUserName);

  const query = useQuery<AsyncReturnType<typeof readSftpUser>, TError, TData>(queryKey, queryFn, {
    enabled: !!(sftpTransportName && sftpUserName),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing sftp user.
 */
export const updateSftpUser = (
  sftpTransportName: string,
  sftpUserName: string,
  updateSftpUserRequestContent: UpdateSftpUserRequestContent,
) => {
  return transportsClient<UpdateSftpUserResponseContent>({
    url: `/sftp_servers/${sftpTransportName}/users/${sftpUserName}`,
    method: "put",
    data: updateSftpUserRequestContent,
  });
};

export const useUpdateSftpUser = <TError = ClientExceptionResponseContent, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateSftpUser>,
    TError,
    { sftpTransportName: string; sftpUserName: string; data: UpdateSftpUserRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateSftpUser>,
    { sftpTransportName: string; sftpUserName: string; data: UpdateSftpUserRequestContent }
  > = (props) => {
    const { data, sftpTransportName, sftpUserName } = props || {};

    return updateSftpUser(sftpTransportName, sftpUserName, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateSftpUser>,
    TError,
    { sftpTransportName: string; sftpUserName: string; data: UpdateSftpUserRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
