import { __awaiter } from '../../../node_modules/tslib/tslib.es6.js';
import { useState } from 'react';

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState(null);

  const copy = text => __awaiter(void 0, void 0, void 0, function* () {
    if (!(navigator === null || navigator === void 0 ? void 0 : navigator.clipboard)) {
      // eslint-disable-next-line no-console
      console.warn("Clipboard not supported");
      return false;
    } // Try to save to clipboard then save it in the state if worked


    try {
      yield navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  });

  return [copiedText, copy];
};

export { useCopyToClipboard };
