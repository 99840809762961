import { jsxs, jsx } from 'react/jsx-runtime';

const Logo = _ref => {
  let {
    fill = "currentColor",
    width = 82
  } = _ref;
  return jsxs("svg", Object.assign({
    className: "logo",
    height: width * 29 / 82,
    viewBox: "0 0 82 29",
    width: width
  }, {
    children: [jsx("title", {
      children: "Stedi"
    }), jsx("path", {
      clipRule: "evenodd",
      d: "M58.3 23.9206C55.4005 23.9206 53.2259 21.8496 53.2259 18.5359C53.2259 15.2222 55.4005 13.1512 58.3 13.1512C61.303 13.1512 63.374 15.2222 63.374 18.5359C63.374 21.8496 61.303 23.9206 58.3 23.9206ZM68.3446 23.0922V19.3643V0H63.4776V11.2872C62.1314 9.52683 59.7497 8.80196 57.5751 8.80196C52.1904 8.80196 48.1518 12.6334 48.1518 18.6395C48.1518 25.0597 52.0868 28.3734 57.4715 28.3734C59.9568 28.3734 62.3385 27.3379 63.5811 25.5775C63.6106 25.8429 63.6317 26.0915 63.6516 26.3257L63.6516 26.3258V26.3259C63.7015 26.914 63.7437 27.4113 63.8918 27.8556H68.3446V23.0922ZM17.604 9.31965V3.62427H22.3674V9.31965H25.8882V13.3582H22.3674V27.8556H17.604V13.3582V9.31965ZM10.2517 22.5744C10.2517 23.9205 9.00907 24.4383 7.55933 24.4383C5.79894 24.4383 4.65986 23.6099 4.45276 22.2637L0 22.9886C0.41421 25.9916 2.89947 28.2697 7.55933 28.2697C12.3227 28.2697 15.0151 25.9916 15.0151 22.5744C15.0151 19.882 13.2547 18.0181 9.83749 17.0861L7.45578 16.4648C6.00604 16.0506 5.38473 15.5328 5.38473 14.6008C5.38473 13.4617 6.3167 12.7369 7.76644 12.7369C9.11262 12.7369 10.2517 13.4617 10.3552 14.7044L14.7045 13.6688C14.1867 10.5623 11.2872 8.80188 7.66288 8.80188C3.10657 8.80188 0.621315 11.3907 0.621315 14.7044C0.621315 17.1896 2.07105 19.1571 6.00604 20.1927L8.4913 20.814C9.63038 21.1246 10.2517 21.6424 10.2517 22.5744ZM31.8942 16.9826H40.7998C40.5926 14.1867 38.9358 12.8405 36.5541 12.8405C33.9653 12.8405 32.412 14.2902 31.8942 16.9826ZM45.7703 20.2963H31.8942C32.2049 23.0922 34.3795 24.1277 36.5541 24.1277C38.8323 24.1277 40.1784 23.0922 41.0069 21.4354L45.0454 22.9886C43.6992 26.1988 40.3855 28.1663 36.5541 28.1663C31.0658 28.1663 27.2344 24.3348 27.2344 18.4323C27.2344 12.6334 30.9623 8.59485 36.5541 8.59485C42.0424 8.59485 45.7703 12.4263 45.7703 18.4323V20.2963ZM72.2794 9.3197H77.1464V27.752H72.2794V9.3197ZM77.119 4.86841L77.1331 0.00144109L82 0.0155553L81.9859 4.88252L77.119 4.86841Z",
      fill: fill,
      fillRule: "evenodd"
    })]
  }));
};

export { Logo };
