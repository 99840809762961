import { fontFamily } from '../../constants.js';
import rgbHex from '../../../node_modules/rgb-hex/index.js';

const fontWeightToValue = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  heavy: 800
};
const fontWeight = weight => ({
  "--fontweight": fontWeightToValue[weight]
});
const fontVariation = _ref => {
  let {
    isMonospace = false,
    tabularFigures = false,
    weight = "normal",
    slashedZero = false
  } = _ref;
  return Object.assign({
    "--mono": isMonospace ? "1" : undefined,
    "--fontweight": weight ? fontWeightToValue[weight] : undefined,
    "--tabularfigures": tabularFigures ? "on" : undefined,
    "--slashedzero": slashedZero ? "on" : undefined
  }, isMonospace ? {
    fontFamily: fontFamily.mono
  } : {});
};
const truncate = () => ({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden"
});
const flex = (alignItems, justifyContent, gap) => ({
  display: "flex",
  alignItems,
  justifyContent,
  gap
});
const noPrint = () => ({
  "@media print": {
    display: "none"
  }
});
const monospace = () => ({
  fontFamily: fontFamily.mono,
  "--mono": "1"
}); // TODO: in a breaking change, accept a node as a second argument so that variable
// values can be calculated on the node, falling back to document.documentElement if not supplied

const colorVarToHex = (colorVar, includeHash) => {
  return `${includeHash ? "#" : ""}${rgbHex(window.getComputedStyle(document.documentElement).getPropertyValue(colorVar))}`;
};
const downloadAsset = (asset, filename) => {
  if (!asset || !filename) return;
  const element = document.createElement("a");
  element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(asset)}`);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
const parseQuery = search => {
  return new URLSearchParams(search);
};
const urlWithQuery = (href, query, params) => {
  const originAndPath = href.split("?")[0];
  const url = new URL(`${originAndPath}${query}`);
  Object.entries(params).forEach(_ref2 => {
    let [key, val] = _ref2;

    if (typeof val === "undefined") {
      return;
    }

    if (Array.isArray(val)) {
      url.searchParams.delete(key);
      val.forEach(item => {
        url.searchParams.append(key, item);
      });
    }

    if (typeof val === "string") {
      url.searchParams.set(key, val);
    }
  });
  return url.toString();
};
const removeTrailingSlash = href => {
  return href.replace(/\/$/, "");
};
const removeLeadingSlash = href => {
  return href.replace(/^\//, "");
};
function getFirstQueryParamStringValue(value) {
  if (Array.isArray(value)) {
    return value[0];
  }

  if (typeof value === "string") {
    return value;
  }

  return null;
}

export { colorVarToHex, downloadAsset, flex, fontVariation, fontWeight, getFirstQueryParamStringValue, monospace, noPrint, parseQuery, removeLeadingSlash, removeTrailingSlash, truncate, urlWithQuery };
