import { useCallback, useEffect } from "react";
import { useToast } from "@stedi/dls";
import { addMessageListener, Message, removeMessageListener } from "../utils/broadcast";

const SignedOutToast = () => {
  const toast = useToast({ single: true });

  const signedOut = useCallback(
    async (msg: Message) => {
      if (msg.messageType === "signed-out") {
        toast(<>You have been signed out</>);
      }
    },
    [toast],
  );

  useEffect(() => {
    addMessageListener(signedOut);
    return () => removeMessageListener(signedOut);
  }, [signedOut]);

  return null;
};

export default SignedOutToast;
