import styled from "@emotion/styled";
import { color, ConfirmationPopover, flex, PopoverProps, space, TextLinkButton, Tooltip } from "@stedi/dls";
import { RemoveIcon } from "../Icons";

interface DeleteItemCellProps {
  onRemove: (e: React.MouseEvent) => void;
  actionText?: string;
  label: string;
  children?: React.ReactNode;
  confirmationText: string;
  name: string;
}

const SContainer = styled.div({
  ...flex("center", "flex-end"),
  height: 16,
  button: {
    color: color.red,
  },
});

export function DeleteItemCell({
  actionText = "delete",
  children,
  confirmationText,
  label,
  name,
  onRemove,
  ...popoverProps
}: DeleteItemCellProps & Partial<Omit<PopoverProps, "popoverContainerStyle" | "children">>) {
  const childrenToRender = children || <RemoveIcon data-testid="remove-icon" size={16} />;
  const ariaLabel = name ? `${label} ${name}` : label;

  return (
    <ConfirmationPopover
      {...popoverProps}
      actionText={actionText}
      confirmationText={confirmationText}
      onConfirm={(e) => {
        e.preventDefault();
        onRemove(e);
      }}
      popoverContainerStyle={{ maxWidth: space.s8, lineHeight: 1.5 }}
    >
      <SContainer>
        <Tooltip placement="bottom" render={label}>
          <TextLinkButton aria-label={ariaLabel} data-dd-action-name={label} data-testid="remove-button" type="button">
            {childrenToRender}
          </TextLinkButton>
        </Tooltip>
      </SContainer>
    </ConfirmationPopover>
  );
}
