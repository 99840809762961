import React, { createContext, useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { UseAuth } from "./AuthProvider";
import { services, useServices } from "./ServicesProvider";

export interface Analytics {
  setPageview: (page: string) => void;
  setUserId: (userId: string) => void;
}

const AnalyticsContext = createContext<Analytics>({
  setPageview: (page: string): void => ReactGA.pageview(page),
  setUserId: (userId: string): void => ReactGA.set({ userId }),
});

export function useAnalytics(): Analytics {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error(`useAnalytics must be used within a AnalyticsProvider`);
  }
  return context;
}

export function AnalyticsProvider({ children }: JSX.ElementChildrenAttribute): JSX.Element {
  const { setPageview, setUserId } = useAnalytics();
  const location = useLocation();
  const getService = useServices();
  const useAuth = getService<UseAuth>(services.useAuth);

  const { user } = useAuth();

  useEffect(() => {
    ReactGA.initialize("UA-98802486-3");
  }, []);
  useEffect(() => {
    setPageview(location.pathname);
    if (user) {
      setUserId(user.id);
    }
  }, [location.pathname, user, setPageview, setUserId]);

  return <AnalyticsContext.Provider value={{ setPageview, setUserId }}>{children}</AnalyticsContext.Provider>;
}
