import { lazy, Suspense } from "react";
import { LoadingLogo } from "@stedi/dls";
import SignedOutToast from "../../shared/components/SignedOutToast";
import UpgradeAppToast from "../../shared/components/UpgradeAppToast";
import { UseAuth } from "../../shared/context/AuthProvider";
import { services, useServices } from "../../shared/context/ServicesProvider";
import "@stedi/dls/dist/styles.css";

const AuthenticatedApp = lazy(
  () =>
    import(
      /* webpackChunkName: "AuthenticatedApp" */
      /* webpackPrefetch: true */
      "./AuthenticatedApp"
    ),
);
const UnauthenticatedApp = lazy(
  () =>
    import(
      /* webpackChunkName: "UnauthenticatedApp" */
      "./UnauthenticatedApp"
    ),
);

export default function App(): JSX.Element {
  const getService = useServices();
  const useAuth = getService<UseAuth>(services.useAuth);
  const { user } = useAuth();

  return (
    <Suspense fallback={<LoadingLogo animate={true} data-testid="waiting-for-current-account" fullscreen={true} />}>
      {user ? <AuthenticatedApp user={user} /> : <UnauthenticatedApp />}
      <UpgradeAppToast />
      <SignedOutToast />
    </Suspense>
  );
}
