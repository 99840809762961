var space = {
    s0: 0,
    s1: 4,
    s2: 8,
    s3: 16,
    s4: 32,
    s5: 64,
    s6: 128,
    s7: 256,
    s8: 512
};

export { space };
