import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, color, HideVisually, space, useToast } from "@stedi/dls";
import { isTest } from "../../api/environment";
import { useVersion } from "../hooks/version";

const SButton = styled(Button)({
  marginLeft: space.s3,
  backgroundColor: color.c60,
  "&:hover": {
    backgroundColor: color.c50,
  },
});

export const UPDATE_MESSAGE = "An updated version of Terminal is available";

export interface UpgradeAppToastProps {
  upgrade?: () => void;
}

const DefaultUpgrade = () => {
  window.location.reload();
};

const UpgradeAppToast: React.FC<UpgradeAppToastProps> = (props = {}) => {
  const { upgrade = DefaultUpgrade } = props;
  const { data: version, isFetching } = useVersion();

  const currentLocation = useLocation();
  const previousLocation = useRef(currentLocation.pathname);

  const toast = useToast({ single: true });

  useEffect(() => {
    if (previousLocation.current !== currentLocation.pathname) {
      if (version === "should-update") {
        upgrade();
      } else {
        previousLocation.current = currentLocation.pathname;
      }
    }
    if (version === "new-version") {
      if (document.getElementsByClassName("updateToast").length > 0) return;
      toast(
        <>
          {UPDATE_MESSAGE}
          <SButton className="updateToast" onClick={upgrade} small={true}>
            Refresh
          </SButton>
        </>,
        { duration: null },
      );
    }
  }, [version, currentLocation, previousLocation, toast, upgrade]);

  if (isTest()) {
    const testId = isFetching ? "checking-for-updates" : "no-updates";
    return (
      <HideVisually data-testid={testId} hide={true}>
        There are currently no updates available.
      </HideVisually>
    );
  }

  return null;
};

export default UpgradeAppToast;
