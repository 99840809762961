import { __rest } from '../../../../node_modules/tslib/tslib.es6.js';
import { jsx } from 'react/jsx-runtime';
import { size } from '../../../constants.js';

const createIconFromRemixIcon = RemixIcon => _a => {
  var {
    color,
    fixedSize,
    size: size$1 = "s6"
  } = _a,
      extraProps = __rest(_a, ["color", "fixedSize", "size"]);

  return jsx(RemixIcon, Object.assign({
    color: color,
    size: fixedSize || size[size$1]
  }, extraProps));
};

export { createIconFromRemixIcon };
