var paletteDeprecated = {
    base: "var(--base)",
    baseAlpha5: "var(--base-a5)",
    baseAlpha10: "var(--base-a10)",
    baseAlpha20: "var(--base-a20)",
    baseAlpha40: "var(--base-a40)",
    baseAlpha60: "var(--base-a60)",
    baseAlpha80: "var(--base-a80)",
    c0: "var(--c0)",
    c0Alpha5: "var(--c0-a5)",
    c0Alpha10: "var(--c0-a10)",
    c0Alpha20: "var(--c0-a20)",
    c0Alpha40: "var(--c0-a40)",
    c0Alpha60: "var(--c0-a60)",
    c0Alpha80: "var(--c0-a80)",
    c2: "var(--c2)",
    c2Alpha5: "var(--c2-a5)",
    c2Alpha10: "var(--c2-a10)",
    c2Alpha20: "var(--c2-a20)",
    c2Alpha40: "var(--c2-a40)",
    c2Alpha60: "var(--c2-a60)",
    c2Alpha80: "var(--c2-a80)",
    c5: "var(--c5)",
    c5Alpha5: "var(--c5-a5)",
    c5Alpha10: "var(--c5-a10)",
    c5Alpha20: "var(--c5-a20)",
    c5Alpha40: "var(--c5-a40)",
    c5Alpha60: "var(--c5-a60)",
    c5Alpha80: "var(--c5-a80)",
    c10: "var(--c10)",
    c10Alpha5: "var(--c10-a5)",
    c10Alpha10: "var(--c10-a10)",
    c10Alpha20: "var(--c10-a20)",
    c10Alpha40: "var(--c10-a40)",
    c10Alpha60: "var(--c10-a60)",
    c10Alpha80: "var(--c10-a80)",
    c20: "var(--c20)",
    c20Alpha5: "var(--c20-a5)",
    c20Alpha10: "var(--c20-a10)",
    c20Alpha20: "var(--c20-a20)",
    c20Alpha40: "var(--c20-a40)",
    c20Alpha60: "var(--c20-a60)",
    c20Alpha80: "var(--c20-a80)",
    c30: "var(--c30)",
    c30Alpha5: "var(--c30-a5)",
    c30Alpha10: "var(--c30-a10)",
    c30Alpha20: "var(--c30-a20)",
    c30Alpha40: "var(--c30-a40)",
    c30Alpha60: "var(--c30-a60)",
    c30Alpha80: "var(--c30-a80)",
    c40: "var(--c40)",
    c40Alpha5: "var(--c40-a5)",
    c40Alpha10: "var(--c40-a10)",
    c40Alpha20: "var(--c40-a20)",
    c40Alpha40: "var(--c40-a40)",
    c40Alpha60: "var(--c40-a60)",
    c40Alpha80: "var(--c40-a80)",
    c50: "var(--c50)",
    c50Alpha5: "var(--c50-a5)",
    c50Alpha10: "var(--c50-a10)",
    c50Alpha20: "var(--c50-a20)",
    c50Alpha40: "var(--c50-a40)",
    c50Alpha60: "var(--c50-a60)",
    c50Alpha80: "var(--c50-a80)",
    c60: "var(--c60)",
    c60Alpha5: "var(--c60-a5)",
    c60Alpha10: "var(--c60-a10)",
    c60Alpha20: "var(--c60-a20)",
    c60Alpha40: "var(--c60-a40)",
    c60Alpha60: "var(--c60-a60)",
    c60Alpha80: "var(--c60-a80)",
    green: "var(--green)",
    greenAlpha5: "var(--green-a5)",
    greenAlpha10: "var(--green-a10)",
    greenAlpha20: "var(--green-a20)",
    greenAlpha40: "var(--green-a40)",
    greenAlpha60: "var(--green-a60)",
    greenAlpha80: "var(--green-a80)",
    yellow: "var(--yellow)",
    yellowAlpha5: "var(--yellow-a5)",
    yellowAlpha10: "var(--yellow-a10)",
    yellowAlpha20: "var(--yellow-a20)",
    yellowAlpha40: "var(--yellow-a40)",
    yellowAlpha60: "var(--yellow-a60)",
    yellowAlpha80: "var(--yellow-a80)",
    desert: "var(--desert)",
    desertAlpha5: "var(--desert-a5)",
    desertAlpha10: "var(--desert-a10)",
    desertAlpha20: "var(--desert-a20)",
    desertAlpha40: "var(--desert-a40)",
    desertAlpha60: "var(--desert-a60)",
    desertAlpha80: "var(--desert-a80)",
    red: "var(--red)",
    redAlpha5: "var(--red-a5)",
    redAlpha10: "var(--red-a10)",
    redAlpha20: "var(--red-a20)",
    redAlpha40: "var(--red-a40)",
    redAlpha60: "var(--red-a60)",
    redAlpha80: "var(--red-a80)",
    magenta: "var(--magenta)",
    magentaAlpha5: "var(--magenta-a5)",
    magentaAlpha10: "var(--magenta-a10)",
    magentaAlpha20: "var(--magenta-a20)",
    magentaAlpha40: "var(--magenta-a40)",
    magentaAlpha60: "var(--magenta-a60)",
    magentaAlpha80: "var(--magenta-a80)",
    blue: "var(--blue)",
    blueAlpha5: "var(--blue-a5)",
    blueAlpha10: "var(--blue-a10)",
    blueAlpha20: "var(--blue-a20)",
    blueAlpha40: "var(--blue-a40)",
    blueAlpha60: "var(--blue-a60)",
    blueAlpha80: "var(--blue-a80)"
};

export { paletteDeprecated };
