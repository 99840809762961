export const preparePayload = <T,>(formData: any, schema: any) => {
  if (!schema.properties) {
    return formData.payload as T;
  }
  const x = Object.entries(schema.properties)
    .map(([k]) => {
      if (formData[k] !== undefined) {
        // empty non required fields will be skipped in the payload
        if (formData[k] === "" && !schema.required?.some((o: string) => k === o)) {
          return undefined;
        }
        return [k, formData[k]];
      }
      return undefined;
    })
    .filter((value) => value !== undefined) as [string, any];
  return Object.fromEntries(x) as T;
};
