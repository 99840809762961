import { useState, useCallback, useEffect } from 'react';

const isValidColorScheme = colorScheme => {
  return colorScheme === "inherit" || colorScheme === "light" || colorScheme === "dark";
};

const getClosestColorSchemeAttribute = node => {
  let currentNode = node; // Dark mode can be set on any node

  do {
    const {
      theme
    } = currentNode.dataset;

    if (theme && isValidColorScheme(theme)) {
      return theme;
    }

    currentNode = currentNode.parentElement;
  } while (currentNode);
};

const colorSchemeMediaQuery = typeof window !== "undefined" && window.matchMedia ? window.matchMedia("(prefers-color-scheme: dark)") : null; // This function explicitly returns light/dark mode, falling back to the
// prefers-color-scheme media query if inherit is used

const getLightOrDarkModeForNode = node => {
  if (typeof window === "undefined") {
    return "light";
  }

  const theme = getClosestColorSchemeAttribute(node);

  if (!theme || theme === "inherit") {
    if (colorSchemeMediaQuery.matches) {
      return "dark";
    }

    return "light";
  }

  return theme;
};

const colorSchemeLocalStorageKey = "stedidotcom:colorScheme";

const defaultStorageHandler = _event => {};

const defaultMediaQueryHandler = _event => {};

const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useState(null);
  const [currentHandlers, setCurrentHandlers] = useState([defaultStorageHandler, defaultMediaQueryHandler]);
  const cleanup = useCallback(() => {
    const [storageHandler, mediaQueryHandler] = currentHandlers;
    window.removeEventListener("storage", storageHandler);
    colorSchemeMediaQuery.removeEventListener("change", mediaQueryHandler);
  }, [currentHandlers]);
  useEffect(() => {
    // Remove event listeners when unmounted
    return cleanup;
  }, [cleanup]);
  const ref = useCallback(node => {
    cleanup();

    if (typeof window === "undefined" || node === null) {
      return;
    }

    const storageHandler = event => {
      if (node && event.key === colorSchemeLocalStorageKey) {
        setColorScheme(getLightOrDarkModeForNode(node));
      }
    };

    const mediaQueryHandler = () => {
      if (node) {
        setColorScheme(getLightOrDarkModeForNode(node));
      }
    };

    window.addEventListener("storage", storageHandler);
    colorSchemeMediaQuery.addEventListener("change", mediaQueryHandler);
    setColorScheme(getLightOrDarkModeForNode(node));
    setCurrentHandlers([storageHandler, mediaQueryHandler]); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    ref,
    colorScheme
  };
};

export { colorSchemeLocalStorageKey, colorSchemeMediaQuery, isValidColorScheme, useColorScheme };
