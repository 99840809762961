import { QueryClient } from "react-query";
import { forTenant, forUser } from "../authorization";
import {
  API_KEYS_HOST,
  BILLING_HOST,
  DOCUMENTS_HOST,
  EDICORE_HOST,
  EDIGUIDES_HOST,
  EVENTS_HOST,
  FORMATION_HOST,
  FUNCTIONS_HOST,
  INTEGRATIONS_HOST,
  LABELS_HOST,
  LEDGER_HOST,
  LEDGER_SEARCH_HOST,
  LEDGER2_HOST,
  MEMBERS_HOST,
  NOTIFICATIONS_HOST,
  PARAMETERS_HOST,
  PARTNERS_HOST,
  SCHEMAS_HOST,
  TENANT_HOST,
  TRANSPORTS_HOST,
  WORKFLOWS_HOST,
} from "../config";
import { HTTPResponseError } from "../errors";
import * as client from "./basic";

export const request =
  (host: string, clientOptions?: Partial<client.RequestOptions>) =>
  async <T>(path: string, { accountId, ...requestOptions }: Partial<client.RequestOptions> = {}) => {
    return client.request<T>(path, {
      host,
      ...clientOptions,
      ...requestOptions,
      headers: {
        ...clientOptions?.headers,
        ...requestOptions.headers,
        ...(accountId ? await forTenant(accountId) : await forUser()),
      },
    });
  };

export const apikeys = request(API_KEYS_HOST);
export const billing = request(BILLING_HOST);
export const documents = request(DOCUMENTS_HOST);
export const integrations = request(INTEGRATIONS_HOST);
export const ledger = request(LEDGER_HOST);
export const ledger2 = request(LEDGER2_HOST);
export const labels = request(LABELS_HOST);
export const members = request(MEMBERS_HOST);
export const notifications = request(NOTIFICATIONS_HOST);
export const tenant = request(TENANT_HOST);
export const functions = request(FUNCTIONS_HOST);
export const parameters = request(PARAMETERS_HOST);
export const schemas = request(SCHEMAS_HOST);
export const formation = request(FORMATION_HOST);
export const ediGuides = request(EDIGUIDES_HOST, { headers: { "x-stedi-internal": "true" } });
export const transactionsSearch = request(LEDGER_SEARCH_HOST);
export const edicore = request(EDICORE_HOST);
export const events = request(EVENTS_HOST);
export const partners = request(PARTNERS_HOST);
export const transports = request(TRANSPORTS_HOST);
export const workflows = request(WORKFLOWS_HOST);

interface QueryRetryHandlerProps {
  queryClient: QueryClient;
  failureCount: number;
  error: HTTPResponseError;
}
export const queryRetryHandler = ({ error, failureCount, queryClient }: QueryRetryHandlerProps) => {
  /**
   * Make sure the request is not retried on 404, but preserve the global defaults declared within the ReactQuery provider.
   *
   * If we do not preserve the defaults, all errors except the 404 case will be retried indefinitely since the function would always return true.
   * This would make it impossible to write tests for components that use useQuery hook and exercise the non-404 error scenario.
   */
  const isNotFound = error.status === 404;
  if (isNotFound) {
    return false;
  }

  const queriesRetryOptions = queryClient.getDefaultOptions().queries?.retry;
  if (queriesRetryOptions === undefined) {
    return false;
  }

  if (typeof queriesRetryOptions === "boolean") {
    return queriesRetryOptions;
  }

  if (typeof queriesRetryOptions === "number") {
    return queriesRetryOptions < failureCount;
  }

  return queriesRetryOptions(failureCount, error);
};
