import { mergePathWithQueryParams } from "./url";

export interface Route {
  get: () => string;
  route: string;
  public: boolean;
}

export interface ArgRoute<TArgs = {}> {
  get: (args: TArgs) => string;
  route: string;
  public: boolean;
}

export type ArgsNavigateOptions<TArgs = {}> = {
  args: TArgs;
  replace?: boolean;
};

export const getRouteWithAccount = (accountId?: string, restRoute: string = "") => {
  if (!accountId) return "";
  return mergePathWithQueryParams(restRoute, accountId ? { account: accountId } : {});
};
