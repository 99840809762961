var breakpoints = [
    "small",
    "medium",
    "large",
    "xlarge",
    "xxlarge"
];
var breakpoint = {
    /** < 640 */ small: 640,
    medium: 960,
    large: 1280,
    xlarge: 1440,
    xxlarge: 1720
};

export { breakpoint, breakpoints };
