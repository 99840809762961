import React__default, { useState, useEffect } from 'react';

const useMotionSensor = function (delay) {
  let steadyState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const [state, setState] = useState(steadyState);
  useEffect(() => {
    if (steadyState !== state) {
      const handler = setTimeout(() => {
        setState(steadyState);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }

    return () => {};
  }, [steadyState, state, delay]);
  const reset = React__default.useCallback(() => {
    setState(steadyState);
  }, [steadyState]);
  const trigger = React__default.useCallback(() => {
    setState(!steadyState);
  }, [steadyState]);
  return [state, trigger, reset];
};

export { useMotionSensor };
