import { __rest } from '../../node_modules/tslib/tslib.es6.js';
import { jsxs, jsx } from 'react/jsx-runtime';
import { Box } from './Box/Box.js';
import { HideVisually } from './HideVisually.js';

const FormField = _a => {
  var {
    children,
    hideLabel,
    htmlFor,
    label
  } = _a,
      props = __rest(_a, ["children", "hideLabel", "htmlFor", "label"]);

  return jsxs(Box, Object.assign({
    mb: "s4"
  }, props, {
    children: [jsx(HideVisually, Object.assign({
      hide: !!hideLabel
    }, {
      children: jsx(Box, Object.assign({
        as: "label",
        display: "block",
        htmlFor: htmlFor,
        mb: "s2"
      }, {
        children: label
      }))
    })), children]
  }));
};

export { FormField };
