import facepaint from 'facepaint';
import { breakpoint as breakpoint$1, breakpoints as breakpoints$1 } from '../packages/tokens/dist/breakpoints.js';
import { palette } from '../packages/tokens/dist/palette.js';
import { paletteDeprecated } from '../packages/tokens/dist/paletteDeprecated.js';

const color = Object.assign(Object.assign({}, palette), paletteDeprecated); // These are duplicated to avoid any references to @stedi/ui-tokens

const breakpoint = Object.assign({}, breakpoint$1);
const breakpoints = [...breakpoints$1];

const FONTSTACK_FALLBACK = 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif';
const INTER_FONTSTACK = `"Inter", ${FONTSTACK_FALLBACK}`;
const RECURSIVE_FONTSTACK = '"Recursive", monospace';
const fontFamily = {
  display: INTER_FONTSTACK,
  body: INTER_FONTSTACK,
  mono: RECURSIVE_FONTSTACK
}; // control heights

const controlHeight = {
  small: 28,
  medium: 36,
  large: 40
}; // lineHeight

const lineHeight = {
  default: 1.3,
  heading: 1.125,
  subheading: 1.333,
  body: 1.618
}; // type scale — ~1.2 — doubles every 4th size

const scale = {
  s1: 10,
  s2: 12,
  s3: 14,
  s4: 16,
  s5: 20,
  s6: 24,
  s7: 28,
  s8: 32,
  s9: 40,
  s10: 48,
  s11: 56,
  s12: 64
}; // fontSize — doubles every 4th size

const size = Object.assign(Object.assign({}, scale), {
  micro: scale.s1,
  small: scale.s2,
  base: scale.s3,
  h6: scale.s3,
  h5: scale.s4,
  h4: scale.s5,
  h3: scale.s6,
  h2: scale.s7,
  h1: scale.s10
}); // margin and padding

const space = {
  s0: 0,
  s1: 4,
  s2: 8,
  s3: 16,
  s4: 32,
  s5: 64,
  s6: 128,
  s7: 256,
  s8: 512,
  auto: "auto"
}; // media queries at breakpoints
// check out https://github.com/emotion-js/facepaint for usage instructions

const withMediaQueries = facepaint([`@media(min-width: ${breakpoint.small}px)`, `@media(min-width: ${breakpoint.medium}px)`, `@media(min-width: ${breakpoint.large}px)`, `@media(min-width: ${breakpoint.xlarge}px)`, `@media(min-width: ${breakpoint.xxlarge}px)`]); // heights

const navigationHeight = 54;
const navHeight = navigationHeight;
const meanings = ["neutral", "primary", "danger"];
const prominences = ["low", "medium", "high"];

export { breakpoint, breakpoints, color, controlHeight, fontFamily, lineHeight, meanings, navHeight, navigationHeight, prominences, size, space, withMediaQueries };
