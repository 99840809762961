import { Auth } from "../../shared/AWSAuth";
import { CURRENT_ENVIRONMENT, Environment } from "./common";

export interface Config {
  stedi_user_pool: {
    user_pool_id: string;
    login_client_id: string;
    domain: string;
  };
}

export type Configs = Record<Environment, Config>;

export const MetaConfig: Configs = {
  preprod: {
    stedi_user_pool: {
      login_client_id: "30mitdaer6eu5d63i7o8lqthl3",
      user_pool_id: "us-east-1_c5O6n0zeA",
      domain: "oauth.preprod.saas.stedi.com",
    },
  },
  prod: {
    stedi_user_pool: {
      login_client_id: "3mg352bkn07gm1nlcfcbg9mohv",
      user_pool_id: "us-east-1_gwQBJaG34",
      domain: "oauth.prod.saas.stedi.com",
    },
  },
};

export const userPoolConfig = () => {
  const config = MetaConfig[CURRENT_ENVIRONMENT].stedi_user_pool;
  return {
    userPoolId: config.user_pool_id,
    userPoolWebClientId: config.login_client_id,
    userPoolDomain: config.domain,
  };
};

export const configureAmplify = (): void => {
  const loc = window.location;
  const redirectUrl = `${loc.protocol}//${loc.host}`;
  const poolConfig = userPoolConfig();
  Auth.configure({
    region: "us-east-1",
    ...userPoolConfig(),
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: poolConfig.userPoolDomain,
      scope: ["profile", "openid", "email"],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
    ssr: true,
  });
};
