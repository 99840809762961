var palette = {
    /** Solid UI element background, or subtle text */ gray: "var(--gray)",
    /** App background */ gray1: "var(--gray1)",
    /** Subtle background */ gray2: "var(--gray2)",
    /** UI element background */ gray3: "var(--gray3)",
    /** Hovered UI element background */ gray4: "var(--gray4)",
    /** Active / Selected UI element background */ gray5: "var(--gray5)",
    /** Subtle borders and separators */ gray6: "var(--gray6)",
    /** UI element border and focus rings */ gray7: "var(--gray7)",
    /** Hovered UI element border */ gray8: "var(--gray8)",
    /** Solid background */ gray9: "var(--gray9)",
    /** Solid UI element background, or subtle text */ gray10: "var(--gray10)",
    /** Hovered solid UI element background */ gray11: "var(--gray11)",
    /** Active / Selected solid UI element background */ gray12: "var(--gray12)",
    /** Normal text */ gray13: "var(--gray13)",
    /** Strong text */ gray14: "var(--gray14)",
    /** Solid UI element background, or subtle text */ green: "var(--green)",
    /** App background */ green1: "var(--green1)",
    /** Subtle background */ green2: "var(--green2)",
    /** UI element background */ green3: "var(--green3)",
    /** Hovered UI element background */ green4: "var(--green4)",
    /** Active / Selected UI element background */ green5: "var(--green5)",
    /** Subtle borders and separators */ green6: "var(--green6)",
    /** UI element border and focus rings */ green7: "var(--green7)",
    /** Hovered UI element border */ green8: "var(--green8)",
    /** Solid background */ green9: "var(--green9)",
    /** Solid UI element background, or subtle text */ green10: "var(--green10)",
    /** Hovered solid UI element background */ green11: "var(--green11)",
    /** Active / Selected solid UI element background */ green12: "var(--green12)",
    /** Normal text */ green13: "var(--green13)",
    /** Strong text */ green14: "var(--green14)",
    /** Solid UI element background, or subtle text */ yellow: "var(--yellow)",
    /** App background */ yellow1: "var(--yellow1)",
    /** Subtle background */ yellow2: "var(--yellow2)",
    /** UI element background */ yellow3: "var(--yellow3)",
    /** Hovered UI element background */ yellow4: "var(--yellow4)",
    /** Active / Selected UI element background */ yellow5: "var(--yellow5)",
    /** Subtle borders and separators */ yellow6: "var(--yellow6)",
    /** UI element border and focus rings */ yellow7: "var(--yellow7)",
    /** Hovered UI element border */ yellow8: "var(--yellow8)",
    /** Solid background */ yellow9: "var(--yellow9)",
    /** Solid UI element background, or subtle text */ yellow10: "var(--yellow10)",
    /** Hovered solid UI element background */ yellow11: "var(--yellow11)",
    /** Active / Selected solid UI element background */ yellow12: "var(--yellow12)",
    /** Normal text */ yellow13: "var(--yellow13)",
    /** Strong text */ yellow14: "var(--yellow14)",
    /** Solid UI element background, or subtle text */ orange: "var(--orange)",
    /** App background */ orange1: "var(--orange1)",
    /** Subtle background */ orange2: "var(--orange2)",
    /** UI element background */ orange3: "var(--orange3)",
    /** Hovered UI element background */ orange4: "var(--orange4)",
    /** Active / Selected UI element background */ orange5: "var(--orange5)",
    /** Subtle borders and separators */ orange6: "var(--orange6)",
    /** UI element border and focus rings */ orange7: "var(--orange7)",
    /** Hovered UI element border */ orange8: "var(--orange8)",
    /** Solid background */ orange9: "var(--orange9)",
    /** Solid UI element background, or subtle text */ orange10: "var(--orange10)",
    /** Hovered solid UI element background */ orange11: "var(--orange11)",
    /** Active / Selected solid UI element background */ orange12: "var(--orange12)",
    /** Normal text */ orange13: "var(--orange13)",
    /** Strong text */ orange14: "var(--orange14)",
    /** Solid UI element background, or subtle text */ red: "var(--red)",
    /** App background */ red1: "var(--red1)",
    /** Subtle background */ red2: "var(--red2)",
    /** UI element background */ red3: "var(--red3)",
    /** Hovered UI element background */ red4: "var(--red4)",
    /** Active / Selected UI element background */ red5: "var(--red5)",
    /** Subtle borders and separators */ red6: "var(--red6)",
    /** UI element border and focus rings */ red7: "var(--red7)",
    /** Hovered UI element border */ red8: "var(--red8)",
    /** Solid background */ red9: "var(--red9)",
    /** Solid UI element background, or subtle text */ red10: "var(--red10)",
    /** Hovered solid UI element background */ red11: "var(--red11)",
    /** Active / Selected solid UI element background */ red12: "var(--red12)",
    /** Normal text */ red13: "var(--red13)",
    /** Strong text */ red14: "var(--red14)",
    /** Solid UI element background, or subtle text */ magenta: "var(--magenta)",
    /** App background */ magenta1: "var(--magenta1)",
    /** Subtle background */ magenta2: "var(--magenta2)",
    /** UI element background */ magenta3: "var(--magenta3)",
    /** Hovered UI element background */ magenta4: "var(--magenta4)",
    /** Active / Selected UI element background */ magenta5: "var(--magenta5)",
    /** Subtle borders and separators */ magenta6: "var(--magenta6)",
    /** UI element border and focus rings */ magenta7: "var(--magenta7)",
    /** Hovered UI element border */ magenta8: "var(--magenta8)",
    /** Solid background */ magenta9: "var(--magenta9)",
    /** Solid UI element background, or subtle text */ magenta10: "var(--magenta10)",
    /** Hovered solid UI element background */ magenta11: "var(--magenta11)",
    /** Active / Selected solid UI element background */ magenta12: "var(--magenta12)",
    /** Normal text */ magenta13: "var(--magenta13)",
    /** Strong text */ magenta14: "var(--magenta14)",
    /** Solid UI element background, or subtle text */ blue: "var(--blue)",
    /** App background */ blue1: "var(--blue1)",
    /** Subtle background */ blue2: "var(--blue2)",
    /** UI element background */ blue3: "var(--blue3)",
    /** Hovered UI element background */ blue4: "var(--blue4)",
    /** Active / Selected UI element background */ blue5: "var(--blue5)",
    /** Subtle borders and separators */ blue6: "var(--blue6)",
    /** UI element border and focus rings */ blue7: "var(--blue7)",
    /** Hovered UI element border */ blue8: "var(--blue8)",
    /** Solid background */ blue9: "var(--blue9)",
    /** Solid UI element background, or subtle text */ blue10: "var(--blue10)",
    /** Hovered solid UI element background */ blue11: "var(--blue11)",
    /** Active / Selected solid UI element background */ blue12: "var(--blue12)",
    /** Normal text */ blue13: "var(--blue13)",
    /** Strong text */ blue14: "var(--blue14)",
    blackAlpha1: "var(--blackAlpha1)",
    blackAlpha2: "var(--blackAlpha2)",
    blackAlpha3: "var(--blackAlpha3)",
    blackAlpha4: "var(--blackAlpha4)",
    blackAlpha5: "var(--blackAlpha5)",
    blackAlpha6: "var(--blackAlpha6)",
    blackAlpha7: "var(--blackAlpha7)",
    blackAlpha8: "var(--blackAlpha8)",
    blackAlpha9: "var(--blackAlpha9)",
    blackAlpha10: "var(--blackAlpha10)",
    blackAlpha11: "var(--blackAlpha11)",
    blackAlpha12: "var(--blackAlpha12)",
    blackAlpha13: "var(--blackAlpha13)",
    blackAlpha14: "var(--blackAlpha14)"
};

export { palette };
