import { jsxs, jsx } from 'react/jsx-runtime';

const Alert = _ref => {
  let {
    id,
    onClose,
    title
  } = _ref;
  return jsxs("div", Object.assign({
    className: "Toaster__alert",
    id: id
  }, {
    children: [typeof title === "string" ? jsx("div", Object.assign({
      className: "Toaster__alert_text"
    }, {
      children: title
    })) : title, onClose && jsx(Close, {
      onClose: onClose
    })]
  }));
};
const Close = _ref2 => {
  let {
    onClose
  } = _ref2;
  return jsx("button", Object.assign({
    "aria-label": "Close",
    className: "Toaster__alert_close",
    onClick: onClose,
    type: "button"
  }, {
    children: jsx("span", Object.assign({
      role: "presentation"
    }, {
      children: "\u00D7"
    }))
  }));
};

export { Alert, Close };
