import React__default from 'react';

const useMediaQuery = query => {
  const [matches, setMatches] = React__default.useState(() => {
    if (typeof window === "undefined") {
      return false;
    }

    return window.matchMedia(query).matches;
  });
  React__default.useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const mql = window.matchMedia(query);

    const onChange = () => {
      setMatches(mql.matches);
    };
    /**
     * Backwards compatibility with Safari.
     */


    mql.addListener(onChange);
    return () => {
      /**
       * Backwards compatibility with Safari.
       */
      mql.removeListener(onChange);
    };
  }, [query]);
  return matches;
};

export { useMediaQuery };
