import { __rest } from '../../node_modules/tslib/tslib.es6.js';
import { jsx } from 'react/jsx-runtime';
import { useMotionSensor } from './hooks/useMotionSensor.js';
import { CopyIcon } from './icons/CopyIcon.js';
import { TextLinkButton } from './TextLink.js';
import { Tooltip } from './Tooltip.js';
import { useCopyToClipboard } from './hooks/useCopyToClipboard.js';
import 'react';

const CopyToClipboardButton = _a => {
  var {
    "aria-label": ariaLabel,
    children,
    copiedMessage = "Copied!",
    copyMessage = "Copy to clipboard",
    value,
    tooltipProps = {}
  } = _a,
      restProps = __rest(_a, ["aria-label", "children", "copiedMessage", "copyMessage", "value", "tooltipProps"]);

  const [showCopiedMessage, triggerCopiedMessage, reset] = useMotionSensor(2000);
  const [_, copy] = useCopyToClipboard();

  const onCopy = e => {
    copy(typeof value === "function" ? value() : value);
    triggerCopiedMessage();
    e.stopPropagation();
    e.preventDefault();
  };

  const message = showCopiedMessage ? copiedMessage : copyMessage;
  return jsx(Tooltip, Object.assign({
    content: message,
    onHide: () => {
      reset();
    },
    onUntrigger: instance => {
      if (!showCopiedMessage) {
        instance.hide();
      }
    }
  }, tooltipProps, {
    trigger: "mouseenter"
  }, {
    children: jsx(TextLinkButton, Object.assign({
      "aria-label": getButtonLabel({
        copyMessage,
        providedLabel: ariaLabel
      }),
      onClick: onCopy,
      type: "button"
    }, restProps, {
      children: children || jsx(CopyIcon, {
        fixedSize: 14
      })
    }))
  }));
};

const getButtonLabel = _ref => {
  let {
    copyMessage,
    providedLabel
  } = _ref;

  if (providedLabel) {
    return providedLabel;
  }

  if (typeof copyMessage === "string") {
    return copyMessage;
  }

  return "Copy to clipboard";
};

export { CopyToClipboardButton };
