import { searchString } from "../../shared/utils/url";
import { forTenant, forUser } from "../authorization";
import {
  CLOUD_EVENTS_HOST,
  CLOUD_FUNCTIONS_HOST,
  CLOUD_LOGS_HOST,
  CLOUD_STORAGE_HOST,
  CLOUD_TRANSPORTS_HOST,
  CLOUD_WORKFLOWS_HOST,
} from "../config";
import { HTTPResponseError } from "../errors";
import * as client from "./basic";

const clientConfig: { accountId?: string } = {};

export const RAW_RESPONSE_SYMBOL = Symbol();
export const PRIMITIVE_VALUE_RESULT_SYMBOL = Symbol();

export const setAccountId = (accountId: string) => {
  clientConfig.accountId = accountId;
};

type CustomInstanceOptions = {
  url: string;
  method: "get" | "post" | "put" | "delete" | "patch";
  params?: any;
  data?: any;
  responseType?: string;
  accountId?: string;
  headers?: Record<string, string>;
};

interface TypedResponse<T = any> extends Response {
  /**
   * this will override `json` method from `Body` that is extended by `Response`
   * interface Body {
   *     json(): Promise<any>;
   * }
   */
  json<P = T>(): Promise<P>;
}

export type ModifiedResponse<T> = T &
  (
    | {
        [RAW_RESPONSE_SYMBOL]: TypedResponse<T>;
        [PRIMITIVE_VALUE_RESULT_SYMBOL]: true;
        output: any;
      }
    | {
        [RAW_RESPONSE_SYMBOL]: TypedResponse<T>;
      }
  );

export const customInstance = (host: string, clientOptions?: Partial<client.RequestOptions>) => {
  return async <T = any>({
    data,
    method,
    params = {},
    url,
    ...requestOptions
  }: Partial<CustomInstanceOptions> = {}): Promise<T | ModifiedResponse<T>> => {
    try {
      const accountId = clientConfig.accountId;
      Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);
      const queryString = Object.keys(params).length > 0 ? `?${searchString(params)}` : "";
      const path = `${url || "/"}${queryString}`;
      const response = await client.request<TypedResponse<T>>(path, {
        host,
        method: method as client.RequestOptions["method"],
        body: data,
        ...clientOptions,
        ...requestOptions,
        rawResponse: true,
        headers: {
          ...clientOptions?.headers,
          ...requestOptions.headers,
          ...(accountId ? await forTenant(accountId) : await forUser()),
        },
      });

      let result: ModifiedResponse<T>;
      if (!response.ok) {
        throw await HTTPResponseError.create(response);
      }
      const contentLength: string | null = response.headers.get("Content-Length");
      if (response.status === 204 || (response.status === 201 && contentLength === "0")) {
        const body = await response.text();
        return body as unknown as T;
      }

      const respClone = response.clone();

      const possibleObject = await response.json<ModifiedResponse<T>>().catch(async (error) => {
        if (error instanceof SyntaxError) {
          return respClone.text();
        } else {
          throw error;
        }
      });
      if (typeof possibleObject !== "object" || possibleObject === null) {
        result = {
          output: possibleObject,
          [PRIMITIVE_VALUE_RESULT_SYMBOL]: true,
        } as unknown as ModifiedResponse<T>;
      } else {
        result = possibleObject;
      }

      result[RAW_RESPONSE_SYMBOL] = response;
      return result;
    } catch (err) {
      console.debug(err);
      throw err;
    }
  };
};

export const eventsClient = customInstance(CLOUD_EVENTS_HOST);
export const functionsClient = customInstance(CLOUD_FUNCTIONS_HOST);
export const logsClient = customInstance(CLOUD_LOGS_HOST);
export const storageClient = customInstance(CLOUD_STORAGE_HOST);
export const transportsClient = customInstance(CLOUD_TRANSPORTS_HOST);
export const workflowsClient = customInstance(CLOUD_WORKFLOWS_HOST);
