/* eslint-disable max-classes-per-file */
import * as React from "react";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { Hub } from "@aws-amplify/core";
import { HubCallback } from "@aws-amplify/core/lib/Hub";
import { clearTenantTokenCache, getTenantToken, StediUser } from "../../api/authentication";
import { AuthError, HTTPResponseError, UnconfirmedUserError } from "../../api/errors";
import { redirectIfExternalStediUrl } from "../../shell/util/redirects";
import { AWSAuth, CognitoUser } from "../AWSAuth";
import { sendMessage } from "../utils/broadcast";
import * as rum from "../utils/rum";

const { useEffect, useState } = React;

const CACHE_KEY = "current_user";

export type UseUserHasAccessToAccount = (option?: string) => UseQueryResult<boolean, unknown>;

export const useUserHasAccessToAccount = (accountId?: string) =>
  useQuery<boolean>(
    ["accountAccess", accountId],
    async () => {
      try {
        await getTenantToken(accountId!);
        return true;
      } catch (error) {
        if (error instanceof HTTPResponseError) {
          if (error.status === 403) {
            return false;
          }
        }
        throw error;
      }
    },
    {
      enabled: !!accountId,
      retry: false,
    },
  );

const setStediSubCookie = (sub: string) => {
  const { hostname } = window.location;
  const determinedHostname = hostname === "localhost" ? "localhost" : "stedi.com";
  const oneMonth = 60 * 60 * 24 * 7 * 4;
  document.cookie = `stediSub=${sub}; domain=${determinedHostname}; path=/; max-age=${oneMonth}; secure`;
};

const setStediEmail = (email: string) => {
  const { hostname } = window.location;
  const determinedHostname = hostname === "localhost" ? "localhost" : "stedi.com";
  const oneMonth = 60 * 60 * 24 * 7 * 4;
  document.cookie = `stediEmail=${email}; domain=${determinedHostname}; path=/; max-age=${oneMonth}; secure`;
};

const deleteStediSubCookie = () => {
  const { hostname } = window.location;
  const determinedHostname = hostname === "localhost" ? "localhost" : "stedi.com";
  document.cookie = `stediSub= ; domain=${determinedHostname}; path=/; expires = Sat, 01 Jan 1983 00:00:00 GMT`;
};

const getUser = async (): Promise<StediUser | undefined> => {
  try {
    const user = (await AWSAuth.currentAuthenticatedUser()) as CognitoUser;
    const session = user.getSignInUserSession();
    if (session) {
      const jwt = session.getIdToken().decodePayload();
      const id = jwt["custom:stedi:userSub"] as string;
      setStediSubCookie(id);
      const email = (jwt["email"] as string) || jwt["custom:stedi:user"];
      setStediEmail(email);

      return {
        email,
        familyName: jwt["family_name"],
        givenName: jwt["given_name"],
        nickname: jwt["nickname"],
        username: jwt["preferred_username"],
        imageUrl: jwt["picture"],
        id,
        isInternalUser: email.endsWith("@stedi.com"),
      };
    }
  } catch (e) {
    // Don't capture this exception. An exception is thrown
    // every time an unauthenticated user loads terminal.
  }
  return undefined;
};

export type UseGetAwsAuthUser = () => {
  isLoading: boolean;
  user: StediUser | undefined;
};

export const useGetAwsAuthUser = () => {
  const [user, setUser] = useState<StediUser | undefined>(undefined);
  const [loadingState, setLoadingState] = useState<string>("LOADING");

  const fetchUser = async () => {
    setLoadingState("LOADING");
    const currentUser = await getUser();
    setUser(currentUser);
    setLoadingState("LOADED");
  };

  useEffect(() => {
    void fetchUser();

    const callback: HubCallback = ({ payload }) => {
      if (payload.event === "signIn") {
        void fetchUser();
      }

      if (payload.event === "signOut") {
        setUser(undefined);
      }
    };

    Hub.listen("auth", callback);
    return (): void => {
      Hub.remove("auth", callback);
    };
  }, []);

  return {
    isLoading: loadingState === "LOADING",
    user,
  };
};
export interface SignUpVariables {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

const generalCognitoError = "An error occurred, please try again.";

export const useSignUp = () =>
  useMutation<SignUpVariables, AuthError, SignUpVariables>(async ({ email, firstName, lastName, password }) => {
    const normalizedEmail = email.toLocaleLowerCase();
    try {
      await AWSAuth.signUp({
        password,
        username: normalizedEmail,
        attributes: { email: normalizedEmail, family_name: lastName, given_name: firstName },
      });
      return { email: normalizedEmail, password, lastName, firstName };
    } catch (error: any) {
      if (error.code === "UsernameExistsException") {
        throw new AuthError("UsernameExistsException");
      }
      throw new Error(generalCognitoError);
    }
  });

export const useSignOut = () => {
  const queryClient = useQueryClient();
  return async () => {
    try {
      await AWSAuth.signOut();
      deleteStediSubCookie();
      queryClient.clear();
      clearTenantTokenCache();
      void sendMessage({ messageType: "signed-out" });
    } catch (err) {
      rum.captureError(err);
      throw err;
    }
  };
};

type AuthDataObject = { attributes: { sub: string } };

export const useConfirm = (
  options: UseMutationOptions<AuthDataObject, AuthError, { email: string; code: string; password: string }>,
) => {
  const queryClient = useQueryClient();
  const confirmedUserAction = async (email: string, password: string) => {
    const response = await AWSAuth.signIn({ username: email, password });
    void queryClient.invalidateQueries(CACHE_KEY);
    void sendMessage({ messageType: "confirmed-email" });
    return response;
  };
  return useMutation<AuthDataObject, AuthError, { email: string; code: string; password: string }>(
    async ({ code, email, password }) => {
      try {
        await AWSAuth.confirmSignUp(email, code);
        return await confirmedUserAction(email, password);
      } catch (err: any) {
        // This means that the user is already confirmed
        if (err.code === "NotAuthorizedException" && err.message.includes("Current status is CONFIRMED")) {
          return await confirmedUserAction(email, password);
        }
        rum.captureError(err);
        throw new Error(generalCognitoError);
      }
    },
    options,
  );
};

export const useSignIn = () => {
  const queryClient = useQueryClient();
  return useMutation<AuthDataObject, AuthError | UnconfirmedUserError, { email: string; password: string }>(
    async ({ email, password }) => {
      try {
        return await AWSAuth.signIn({ username: email, password });
      } catch (err: any) {
        if (err.code === "UserNotConfirmedException") {
          await AWSAuth.resendSignUp(email);
          throw new UnconfirmedUserError(email, password);
        }

        // Cognito timeout
        if (err.code === "NetworkError") {
          throw new HTTPResponseError("Cognito network error", window.location.href, 500, {
            message: "An error occurred while signing in",
            type: "unknown",
          });
        }

        if (err.code === "PasswordResetRequiredException") {
          throw new AuthError("PasswordResetRequired", "Password reset required");
        }

        throw new AuthError("InvalidUsernameOrPassword", "Invalid email or password");
      }
    },
    {
      onSuccess: async (data) => {
        setStediSubCookie(data.attributes.sub);
        redirectIfExternalStediUrl();
        queryClient.clear();
      },
      onError: rum.captureError,
    },
  );
};

export const useResendCode = () =>
  useMutation<void, AuthError, { email: string }>(({ email }) => AWSAuth.resendSignUp(email), {
    onError: rum.captureError,
  });

export const useForgotPassword = () =>
  useMutation<void, AuthError, { email: string }>(
    async ({ email }) => {
      try {
        await AWSAuth.forgotPassword(email);
      } catch (err: any) {
        if (err.code === "UserNotFoundException") {
          throw new AuthError("UserNotFoundException", "User not found");
        } else if (err.code === "InvalidParameterException") {
          throw new AuthError("UserNotConfirmedException", "Please verify your email address");
        }

        throw new AuthError("AuthException", err.message);
      }
    },
    {
      onError: (e) => {
        if (e.code !== "UserNotConfirmedException" && e.code !== "UserNotFoundException") rum.captureError(e);
      },
    },
  );

export const useForgotPasswordSubmit = () => {
  const queryClient = useQueryClient();
  return useMutation<string, AuthError, { email: string; password: string; code: string }>(
    ({ code, email, password }) => AWSAuth.forgotPasswordSubmit(email, code, password),
    {
      onSuccess: async (_, { email, password }) => {
        await AWSAuth.signIn({ username: email, password });
        void queryClient.invalidateQueries(CACHE_KEY);
      },
      onError: rum.captureError,
    },
  );
};
