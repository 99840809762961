/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * workflows
 * Stedi Workflows
 * OpenAPI spec version: 2021-11-16
 */
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { workflowsClient } from "../../../api/client/custom-instances";
import type {
  AccessDeniedResponseContent,
  BadRequestResponseContent,
  CreateWorkflowExecutionRequestContent,
  CreateWorkflowExecutionResponseContent,
  CreateWorkflowRequestContent,
  CreateWorkflowResponseContent,
  GatewayTimeoutResponseContent,
  InternalFailureResponseContent,
  ListWorkflowExecutionsParams,
  ListWorkflowExecutionsResponseContent,
  ListWorkflowsParams,
  ListWorkflowsResponseContent,
  NotAcceptableResponseContent,
  QuotaExceededResponseContent,
  ReadWorkflowExecutionResponseContent,
  ReadWorkflowResponseContent,
  RequestTooLargeResponseContent,
  ResourceConflictResponseContent,
  ResourceNotFoundResponseContent,
  ServiceUnavailableResponseContent,
  UnauthorizedResponseContent,
  UnsupportedMediaTypeResponseContent,
  UpdateWorkflowRequestContent,
  UpdateWorkflowResponseContent,
} from ".././models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * Retrieve a list containing every workflow.
 */
export const listWorkflows = (params?: ListWorkflowsParams) => {
  return workflowsClient<ListWorkflowsResponseContent>({ url: `/workflows`, method: "get", params });
};

export const getListWorkflowsQueryKey = (params?: ListWorkflowsParams) => [`/workflows`, ...(params ? [params] : [])];

export const useListWorkflowsInfinite = <
  TData = AsyncReturnType<typeof listWorkflows>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListWorkflowsParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listWorkflows>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListWorkflowsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listWorkflows>> = ({ pageParam }) =>
    listWorkflows({ page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listWorkflows>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListWorkflows = <
  TData = AsyncReturnType<typeof listWorkflows>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListWorkflowsParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listWorkflows>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListWorkflowsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listWorkflows>> = () => listWorkflows(params);

  const query = useQuery<AsyncReturnType<typeof listWorkflows>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new workflow.
 */
export const createWorkflow = (createWorkflowRequestContent: CreateWorkflowRequestContent) => {
  return workflowsClient<CreateWorkflowResponseContent>({
    url: `/workflows`,
    method: "post",
    data: createWorkflowRequestContent,
  });
};

export const useCreateWorkflow = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createWorkflow>,
    TError,
    { data: CreateWorkflowRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof createWorkflow>, { data: CreateWorkflowRequestContent }> = (
    props,
  ) => {
    const { data } = props || {};

    return createWorkflow(data);
  };

  return useMutation<AsyncReturnType<typeof createWorkflow>, TError, { data: CreateWorkflowRequestContent }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Delete an existing workflow.
 */
export const deleteWorkflow = (stateMachineName: string) => {
  return workflowsClient<void>({ url: `/workflows/${stateMachineName}`, method: "delete" });
};

export const useDeleteWorkflow = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof deleteWorkflow>, TError, { stateMachineName: string }, TContext>;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteWorkflow>, { stateMachineName: string }> = (
    props,
  ) => {
    const { stateMachineName } = props || {};

    return deleteWorkflow(stateMachineName);
  };

  return useMutation<AsyncReturnType<typeof deleteWorkflow>, TError, { stateMachineName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve an existing workflow.
 */
export const readWorkflow = (stateMachineName: string) => {
  return workflowsClient<ReadWorkflowResponseContent>({ url: `/workflows/${stateMachineName}`, method: "get" });
};

export const getReadWorkflowQueryKey = (stateMachineName: string) => [`/workflows/${stateMachineName}`];

export const useReadWorkflowInfinite = <
  TData = AsyncReturnType<typeof readWorkflow>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  stateMachineName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readWorkflow>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadWorkflowQueryKey(stateMachineName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readWorkflow>> = () => readWorkflow(stateMachineName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readWorkflow>, TError, TData>(queryKey, queryFn, {
    enabled: !!stateMachineName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadWorkflow = <
  TData = AsyncReturnType<typeof readWorkflow>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  stateMachineName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readWorkflow>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadWorkflowQueryKey(stateMachineName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readWorkflow>> = () => readWorkflow(stateMachineName);

  const query = useQuery<AsyncReturnType<typeof readWorkflow>, TError, TData>(queryKey, queryFn, {
    enabled: !!stateMachineName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing workflow.
 */
export const updateWorkflow = (
  stateMachineName: string,
  updateWorkflowRequestContent: UpdateWorkflowRequestContent,
) => {
  return workflowsClient<UpdateWorkflowResponseContent>({
    url: `/workflows/${stateMachineName}`,
    method: "put",
    data: updateWorkflowRequestContent,
  });
};

export const useUpdateWorkflow = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateWorkflow>,
    TError,
    { stateMachineName: string; data: UpdateWorkflowRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateWorkflow>,
    { stateMachineName: string; data: UpdateWorkflowRequestContent }
  > = (props) => {
    const { data, stateMachineName } = props || {};

    return updateWorkflow(stateMachineName, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateWorkflow>,
    TError,
    { stateMachineName: string; data: UpdateWorkflowRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Retrieve a list containing every workflow execution.
 */
export const listWorkflowExecutions = (stateMachineName: string, params?: ListWorkflowExecutionsParams) => {
  return workflowsClient<ListWorkflowExecutionsResponseContent>({
    url: `/workflows/${stateMachineName}/executions`,
    method: "get",
    params,
  });
};

export const getListWorkflowExecutionsQueryKey = (stateMachineName: string, params?: ListWorkflowExecutionsParams) => [
  `/workflows/${stateMachineName}/executions`,
  ...(params ? [params] : []),
];

export const useListWorkflowExecutionsInfinite = <
  TData = AsyncReturnType<typeof listWorkflowExecutions>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  stateMachineName: string,
  params?: ListWorkflowExecutionsParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listWorkflowExecutions>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListWorkflowExecutionsQueryKey(stateMachineName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listWorkflowExecutions>> = ({ pageParam }) =>
    listWorkflowExecutions(stateMachineName, { page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listWorkflowExecutions>, TError, TData>(queryKey, queryFn, {
    enabled: !!stateMachineName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListWorkflowExecutions = <
  TData = AsyncReturnType<typeof listWorkflowExecutions>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  stateMachineName: string,
  params?: ListWorkflowExecutionsParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listWorkflowExecutions>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListWorkflowExecutionsQueryKey(stateMachineName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listWorkflowExecutions>> = () =>
    listWorkflowExecutions(stateMachineName, params);

  const query = useQuery<AsyncReturnType<typeof listWorkflowExecutions>, TError, TData>(queryKey, queryFn, {
    enabled: !!stateMachineName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new workflow execution.
 */
export const createWorkflowExecution = (
  stateMachineName: string,
  createWorkflowExecutionRequestContent: CreateWorkflowExecutionRequestContent,
) => {
  return workflowsClient<CreateWorkflowExecutionResponseContent>({
    url: `/workflows/${stateMachineName}/executions`,
    method: "post",
    data: createWorkflowExecutionRequestContent,
  });
};

export const useCreateWorkflowExecution = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createWorkflowExecution>,
    TError,
    { stateMachineName: string; data: CreateWorkflowExecutionRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createWorkflowExecution>,
    { stateMachineName: string; data: CreateWorkflowExecutionRequestContent }
  > = (props) => {
    const { data, stateMachineName } = props || {};

    return createWorkflowExecution(stateMachineName, data);
  };

  return useMutation<
    AsyncReturnType<typeof createWorkflowExecution>,
    TError,
    { stateMachineName: string; data: CreateWorkflowExecutionRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Read an existing workflow execution.
 */
export const readWorkflowExecution = (stateMachineName: string, stateMachineExecutionId: string) => {
  return workflowsClient<ReadWorkflowExecutionResponseContent>({
    url: `/workflows/${stateMachineName}/executions/${stateMachineExecutionId}`,
    method: "get",
  });
};

export const getReadWorkflowExecutionQueryKey = (stateMachineName: string, stateMachineExecutionId: string) => [
  `/workflows/${stateMachineName}/executions/${stateMachineExecutionId}`,
];

export const useReadWorkflowExecutionInfinite = <
  TData = AsyncReturnType<typeof readWorkflowExecution>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  stateMachineName: string,
  stateMachineExecutionId: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readWorkflowExecution>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getReadWorkflowExecutionQueryKey(stateMachineName, stateMachineExecutionId);

  const queryFn: QueryFunction<AsyncReturnType<typeof readWorkflowExecution>> = () =>
    readWorkflowExecution(stateMachineName, stateMachineExecutionId);

  const query = useInfiniteQuery<AsyncReturnType<typeof readWorkflowExecution>, TError, TData>(queryKey, queryFn, {
    enabled: !!(stateMachineName && stateMachineExecutionId),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadWorkflowExecution = <
  TData = AsyncReturnType<typeof readWorkflowExecution>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  stateMachineName: string,
  stateMachineExecutionId: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readWorkflowExecution>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getReadWorkflowExecutionQueryKey(stateMachineName, stateMachineExecutionId);

  const queryFn: QueryFunction<AsyncReturnType<typeof readWorkflowExecution>> = () =>
    readWorkflowExecution(stateMachineName, stateMachineExecutionId);

  const query = useQuery<AsyncReturnType<typeof readWorkflowExecution>, TError, TData>(queryKey, queryFn, {
    enabled: !!(stateMachineName && stateMachineExecutionId),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};
