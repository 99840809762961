import styled from "@emotion/styled";
import { color, flex, space } from "@stedi/dls";
import { RouteTextLink } from "./RouteTextLink";

export const TableCellLink = styled(RouteTextLink)({
  ...flex("center"),
  maxWidth: "100%",
  textUnderlineOffset: 1.5,
  "&:hover, &:active": {
    textDecorationColor: color.greenAlpha40,
  },
  "> svg": {
    color: color.greenAlpha40,
    marginRight: space.s2,
  },
});
