/**
 * Generated by orval v6.6.0 🍺
 * Do not edit manually.
 * events
 * Stedi Events
 * OpenAPI spec version: 2021-11-16
 */
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { eventsClient } from "../../../api/client/custom-instances";
import type {
  AccessDeniedResponseContent,
  BadRequestResponseContent,
  CreateEventBusRequestContent,
  CreateEventBusResponseContent,
  CreateEventRuleRequestContent,
  CreateEventRuleResponseContent,
  CreateEventScheduleRequestContent,
  CreateEventScheduleResponseContent,
  GatewayTimeoutResponseContent,
  InternalFailureResponseContent,
  ListEventBusesParams,
  ListEventBusesResponseContent,
  ListEventRulesParams,
  ListEventRulesResponseContent,
  ListEventSchedulesParams,
  ListEventSchedulesResponseContent,
  NotAcceptableResponseContent,
  QuotaExceededResponseContent,
  ReadEventBusResponseContent,
  ReadEventRuleResponseContent,
  ReadEventScheduleResponseContent,
  RequestTooLargeResponseContent,
  ResourceConflictResponseContent,
  ResourceNotFoundResponseContent,
  ServiceUnavailableResponseContent,
  UnauthorizedResponseContent,
  UnsupportedMediaTypeResponseContent,
  UpdateEventBusResponseContent,
  UpdateEventRuleRequestContent,
  UpdateEventRuleResponseContent,
  UpdateEventScheduleRequestContent,
  UpdateEventScheduleResponseContent,
} from ".././models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * Retrieve a list containing every event bus.
 */
export const listEventBuses = (params?: ListEventBusesParams) => {
  return eventsClient<ListEventBusesResponseContent>({ url: `/event_buses`, method: "get", params });
};

export const getListEventBusesQueryKey = (params?: ListEventBusesParams) => [
  `/event_buses`,
  ...(params ? [params] : []),
];

export const useListEventBusesInfinite = <
  TData = AsyncReturnType<typeof listEventBuses>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListEventBusesParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listEventBuses>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListEventBusesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listEventBuses>> = ({ pageParam }) =>
    listEventBuses({ page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listEventBuses>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListEventBuses = <
  TData = AsyncReturnType<typeof listEventBuses>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListEventBusesParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listEventBuses>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListEventBusesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listEventBuses>> = () => listEventBuses(params);

  const query = useQuery<AsyncReturnType<typeof listEventBuses>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new event bus.
 */
export const createEventBus = (createEventBusRequestContent: CreateEventBusRequestContent) => {
  return eventsClient<CreateEventBusResponseContent>({
    url: `/event_buses`,
    method: "post",
    data: createEventBusRequestContent,
  });
};

export const useCreateEventBus = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createEventBus>,
    TError,
    { data: CreateEventBusRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof createEventBus>, { data: CreateEventBusRequestContent }> = (
    props,
  ) => {
    const { data } = props || {};

    return createEventBus(data);
  };

  return useMutation<AsyncReturnType<typeof createEventBus>, TError, { data: CreateEventBusRequestContent }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Delete an existing event bus.
 */
export const deleteEventBus = (eventBusName: string) => {
  return eventsClient<void>({ url: `/event_buses/${eventBusName}`, method: "delete" });
};

export const useDeleteEventBus = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof deleteEventBus>, TError, { eventBusName: string }, TContext>;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteEventBus>, { eventBusName: string }> = (props) => {
    const { eventBusName } = props || {};

    return deleteEventBus(eventBusName);
  };

  return useMutation<AsyncReturnType<typeof deleteEventBus>, TError, { eventBusName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve an existing event bus.
 */
export const readEventBus = (eventBusName: string) => {
  return eventsClient<ReadEventBusResponseContent>({ url: `/event_buses/${eventBusName}`, method: "get" });
};

export const getReadEventBusQueryKey = (eventBusName: string) => [`/event_buses/${eventBusName}`];

export const useReadEventBusInfinite = <
  TData = AsyncReturnType<typeof readEventBus>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  eventBusName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readEventBus>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadEventBusQueryKey(eventBusName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readEventBus>> = () => readEventBus(eventBusName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readEventBus>, TError, TData>(queryKey, queryFn, {
    enabled: !!eventBusName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadEventBus = <
  TData = AsyncReturnType<typeof readEventBus>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  eventBusName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readEventBus>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadEventBusQueryKey(eventBusName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readEventBus>> = () => readEventBus(eventBusName);

  const query = useQuery<AsyncReturnType<typeof readEventBus>, TError, TData>(queryKey, queryFn, {
    enabled: !!eventBusName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing event bus.
 */
export const updateEventBus = (eventBusName: string) => {
  return eventsClient<UpdateEventBusResponseContent>({ url: `/event_buses/${eventBusName}`, method: "post" });
};

export const useUpdateEventBus = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof updateEventBus>, TError, { eventBusName: string }, TContext>;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof updateEventBus>, { eventBusName: string }> = (props) => {
    const { eventBusName } = props || {};

    return updateEventBus(eventBusName);
  };

  return useMutation<AsyncReturnType<typeof updateEventBus>, TError, { eventBusName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Retrieve a list containing every event rule.
 */
export const listEventRules = (eventBusName: string, params?: ListEventRulesParams) => {
  return eventsClient<ListEventRulesResponseContent>({
    url: `/event_buses/${eventBusName}/rules`,
    method: "get",
    params,
  });
};

export const getListEventRulesQueryKey = (eventBusName: string, params?: ListEventRulesParams) => [
  `/event_buses/${eventBusName}/rules`,
  ...(params ? [params] : []),
];

export const useListEventRulesInfinite = <
  TData = AsyncReturnType<typeof listEventRules>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  eventBusName: string,
  params?: ListEventRulesParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listEventRules>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListEventRulesQueryKey(eventBusName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listEventRules>> = ({ pageParam }) =>
    listEventRules(eventBusName, { page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listEventRules>, TError, TData>(queryKey, queryFn, {
    enabled: !!eventBusName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListEventRules = <
  TData = AsyncReturnType<typeof listEventRules>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  eventBusName: string,
  params?: ListEventRulesParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listEventRules>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListEventRulesQueryKey(eventBusName, params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listEventRules>> = () => listEventRules(eventBusName, params);

  const query = useQuery<AsyncReturnType<typeof listEventRules>, TError, TData>(queryKey, queryFn, {
    enabled: !!eventBusName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new event rule.
 */
export const createEventRule = (eventBusName: string, createEventRuleRequestContent: CreateEventRuleRequestContent) => {
  return eventsClient<CreateEventRuleResponseContent>({
    url: `/event_buses/${eventBusName}/rules`,
    method: "post",
    data: createEventRuleRequestContent,
  });
};

export const useCreateEventRule = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createEventRule>,
    TError,
    { eventBusName: string; data: CreateEventRuleRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createEventRule>,
    { eventBusName: string; data: CreateEventRuleRequestContent }
  > = (props) => {
    const { data, eventBusName } = props || {};

    return createEventRule(eventBusName, data);
  };

  return useMutation<
    AsyncReturnType<typeof createEventRule>,
    TError,
    { eventBusName: string; data: CreateEventRuleRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Delete an existing event rule.
 */
export const deleteEventRule = (eventBusName: string, ruleName: string) => {
  return eventsClient<void>({ url: `/event_buses/${eventBusName}/rules/${ruleName}`, method: "delete" });
};

export const useDeleteEventRule = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteEventRule>,
    TError,
    { eventBusName: string; ruleName: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteEventRule>,
    { eventBusName: string; ruleName: string }
  > = (props) => {
    const { eventBusName, ruleName } = props || {};

    return deleteEventRule(eventBusName, ruleName);
  };

  return useMutation<
    AsyncReturnType<typeof deleteEventRule>,
    TError,
    { eventBusName: string; ruleName: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Read an existing event rule.
 */
export const readEventRule = (eventBusName: string, ruleName: string) => {
  return eventsClient<ReadEventRuleResponseContent>({
    url: `/event_buses/${eventBusName}/rules/${ruleName}`,
    method: "get",
  });
};

export const getReadEventRuleQueryKey = (eventBusName: string, ruleName: string) => [
  `/event_buses/${eventBusName}/rules/${ruleName}`,
];

export const useReadEventRuleInfinite = <
  TData = AsyncReturnType<typeof readEventRule>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  eventBusName: string,
  ruleName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readEventRule>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadEventRuleQueryKey(eventBusName, ruleName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readEventRule>> = () => readEventRule(eventBusName, ruleName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readEventRule>, TError, TData>(queryKey, queryFn, {
    enabled: !!(eventBusName && ruleName),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadEventRule = <
  TData = AsyncReturnType<typeof readEventRule>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  eventBusName: string,
  ruleName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readEventRule>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadEventRuleQueryKey(eventBusName, ruleName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readEventRule>> = () => readEventRule(eventBusName, ruleName);

  const query = useQuery<AsyncReturnType<typeof readEventRule>, TError, TData>(queryKey, queryFn, {
    enabled: !!(eventBusName && ruleName),
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing event rule.
 */
export const updateEventRule = (
  eventBusName: string,
  ruleName: string,
  updateEventRuleRequestContent: UpdateEventRuleRequestContent,
) => {
  return eventsClient<UpdateEventRuleResponseContent>({
    url: `/event_buses/${eventBusName}/rules/${ruleName}`,
    method: "put",
    data: updateEventRuleRequestContent,
  });
};

export const useUpdateEventRule = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateEventRule>,
    TError,
    { eventBusName: string; ruleName: string; data: UpdateEventRuleRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateEventRule>,
    { eventBusName: string; ruleName: string; data: UpdateEventRuleRequestContent }
  > = (props) => {
    const { data, eventBusName, ruleName } = props || {};

    return updateEventRule(eventBusName, ruleName, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateEventRule>,
    TError,
    { eventBusName: string; ruleName: string; data: UpdateEventRuleRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Retrieve a list containing every event schedule.
 */
export const listEventSchedules = (params?: ListEventSchedulesParams) => {
  return eventsClient<ListEventSchedulesResponseContent>({ url: `/event_schedules`, method: "get", params });
};

export const getListEventSchedulesQueryKey = (params?: ListEventSchedulesParams) => [
  `/event_schedules`,
  ...(params ? [params] : []),
];

export const useListEventSchedulesInfinite = <
  TData = AsyncReturnType<typeof listEventSchedules>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListEventSchedulesParams,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof listEventSchedules>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListEventSchedulesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listEventSchedules>> = ({ pageParam }) =>
    listEventSchedules({ page_token: pageParam, ...params });

  const query = useInfiniteQuery<AsyncReturnType<typeof listEventSchedules>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useListEventSchedules = <
  TData = AsyncReturnType<typeof listEventSchedules>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  params?: ListEventSchedulesParams,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof listEventSchedules>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getListEventSchedulesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof listEventSchedules>> = () => listEventSchedules(params);

  const query = useQuery<AsyncReturnType<typeof listEventSchedules>, TError, TData>(queryKey, queryFn, {
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Create a new event schedule.
 */
export const createEventSchedule = (createEventScheduleRequestContent: CreateEventScheduleRequestContent) => {
  return eventsClient<CreateEventScheduleResponseContent>({
    url: `/event_schedules`,
    method: "post",
    data: createEventScheduleRequestContent,
  });
};

export const useCreateEventSchedule = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof createEventSchedule>,
    TError,
    { data: CreateEventScheduleRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof createEventSchedule>,
    { data: CreateEventScheduleRequestContent }
  > = (props) => {
    const { data } = props || {};

    return createEventSchedule(data);
  };

  return useMutation<
    AsyncReturnType<typeof createEventSchedule>,
    TError,
    { data: CreateEventScheduleRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Delete an existing event schedule.
 */
export const deleteEventSchedule = (ruleName: string) => {
  return eventsClient<void>({ url: `/event_schedules/${ruleName}`, method: "delete" });
};

export const useDeleteEventSchedule = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof deleteEventSchedule>, TError, { ruleName: string }, TContext>;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof deleteEventSchedule>, { ruleName: string }> = (props) => {
    const { ruleName } = props || {};

    return deleteEventSchedule(ruleName);
  };

  return useMutation<AsyncReturnType<typeof deleteEventSchedule>, TError, { ruleName: string }, TContext>(
    mutationFn,
    mutationOptions,
  );
};
/**
 * Read an existing event schedule.
 */
export const readEventSchedule = (ruleName: string) => {
  return eventsClient<ReadEventScheduleResponseContent>({ url: `/event_schedules/${ruleName}`, method: "get" });
};

export const getReadEventScheduleQueryKey = (ruleName: string) => [`/event_schedules/${ruleName}`];

export const useReadEventScheduleInfinite = <
  TData = AsyncReturnType<typeof readEventSchedule>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  ruleName: string,
  options?: { query?: UseInfiniteQueryOptions<AsyncReturnType<typeof readEventSchedule>, TError, TData> },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadEventScheduleQueryKey(ruleName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readEventSchedule>> = () => readEventSchedule(ruleName);

  const query = useInfiniteQuery<AsyncReturnType<typeof readEventSchedule>, TError, TData>(queryKey, queryFn, {
    enabled: !!ruleName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useReadEventSchedule = <
  TData = AsyncReturnType<typeof readEventSchedule>,
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
>(
  ruleName: string,
  options?: { query?: UseQueryOptions<AsyncReturnType<typeof readEventSchedule>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getReadEventScheduleQueryKey(ruleName);

  const queryFn: QueryFunction<AsyncReturnType<typeof readEventSchedule>> = () => readEventSchedule(ruleName);

  const query = useQuery<AsyncReturnType<typeof readEventSchedule>, TError, TData>(queryKey, queryFn, {
    enabled: !!ruleName,
    staleTime: 10000,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Update an existing event schedule.
 */
export const updateEventSchedule = (
  ruleName: string,
  updateEventScheduleRequestContent: UpdateEventScheduleRequestContent,
) => {
  return eventsClient<UpdateEventScheduleResponseContent>({
    url: `/event_schedules/${ruleName}`,
    method: "put",
    data: updateEventScheduleRequestContent,
  });
};

export const useUpdateEventSchedule = <
  TError =
    | BadRequestResponseContent
    | UnauthorizedResponseContent
    | AccessDeniedResponseContent
    | ResourceNotFoundResponseContent
    | NotAcceptableResponseContent
    | ResourceConflictResponseContent
    | RequestTooLargeResponseContent
    | UnsupportedMediaTypeResponseContent
    | QuotaExceededResponseContent
    | InternalFailureResponseContent
    | ServiceUnavailableResponseContent
    | GatewayTimeoutResponseContent,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateEventSchedule>,
    TError,
    { ruleName: string; data: UpdateEventScheduleRequestContent },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateEventSchedule>,
    { ruleName: string; data: UpdateEventScheduleRequestContent }
  > = (props) => {
    const { data, ruleName } = props || {};

    return updateEventSchedule(ruleName, data);
  };

  return useMutation<
    AsyncReturnType<typeof updateEventSchedule>,
    TError,
    { ruleName: string; data: UpdateEventScheduleRequestContent },
    TContext
  >(mutationFn, mutationOptions);
};
