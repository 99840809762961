import { Control, Controller, Path } from "react-hook-form";
import { MonacoEditor, Select, space, Switch, TextField } from "@stedi/dls";

export function getWidget<T>(name: Path<T>, object: any, control: Control<T>) {
  if (object.enum) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Select
              onChange={(newValue) => {
                if (newValue) {
                  field.onChange(newValue.value);
                }
              }}
              options={object.enum.map((c: string) => ({ label: c, value: c }))}
              value={{ label: field.value, value: field.value }}
            />
          );
        }}
        rules={{ required: true }}
      />
    );
  }

  // nasty hack for functions
  if (object.type === "string" && name === "code") {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <MonacoEditor
              defaultValue={field.value as string}
              height={space.s8}
              language={"javascript"}
              onChange={(v) => field.onChange(v)}
            />
          );
        }}
        rules={{ required: true }}
      />
    );
  }

  // nasty hack for executions
  if (object.type === "string" && name === "payload") {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <MonacoEditor
              defaultValue={field.value as string}
              height={space.s8}
              language={"json"}
              onChange={(v) => field.onChange(v)}
            />
          );
        }}
        rules={{ required: true }}
      />
    );
  }

  if (object.type === "string") {
    return (
      <Controller
        control={control}
        name={name}
        render={() => {
          return <TextField contentEditable={false} label="" name={name} required={true} type="text" />;
        }}
        rules={{ required: true }}
      />
    );
  }

  if (object.type === "boolean") {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const { onChange, value } = field;
          return (
            <Switch
              isEnabled={value as boolean}
              onToggle={() => {
                onChange(!value);
              }}
            />
          );
        }}
      />
    );
  }

  if (object.type === "object" || (!object.$ref && !object.type)) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <MonacoEditor
              defaultValue={JSON.stringify(field.value, null, 2)}
              height={space.s8}
              language="json"
              onChange={(v) => field.onChange(JSON.parse(v ?? ""))}
            />
          );
        }}
        rules={{ required: true }}
      />
    );
  }

  return undefined;
}

export default getWidget;
