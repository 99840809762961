import * as jwt from "jsonwebtoken";
import { AWSAuth } from "../shared/AWSAuth";
import { sendMessage } from "../shared/utils/broadcast";
import { secondsSinceEpoch } from "../shared/utils/date";
import * as client from "./client/basic";
import * as config from "./config";

export interface StediUser {
  email: string;
  familyName?: string;
  givenName?: string;
  nickname?: string;
  username?: string;
  imageUrl?: string;
  id: string;
  isInternalUser: boolean;
}

export interface TokenResponse {
  id_token: string;
}

interface TenantTokenEntry {
  idToken: string;
  expiresAt: number;
}

const TenantTokenCache = new Map<string, Promise<TenantTokenEntry>>();
const ClockDriftExpiryWindow = 60 * 5;

const isValid = (token: TenantTokenEntry) => secondsSinceEpoch() < token.expiresAt;

export const getUserToken = async (): Promise<string> => {
  try {
    const session = await AWSAuth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (e) {
    await AWSAuth.signOut();
    await sendMessage({ messageType: "signed-out" });
  }
  return "";
};

export const signInTenant = async (accountId: string): Promise<TenantTokenEntry> => {
  const token = await getUserToken();
  const { id_token: idToken } = await client.request<TokenResponse>("/2021-07-20/authenticate/member", {
    host: config.TOKENS_HOST,
    body: { account_id: accountId },
    headers: { Authorization: `Bearer ${token}` },
  });
  const { exp } = jwt.decode(idToken) as { exp: number };

  return {
    idToken,
    expiresAt: exp - ClockDriftExpiryWindow,
  };
};

export const getTenantToken = async (accountId: string, expiringAt?: number): Promise<string> => {
  const existingTokenFetchProcess = TenantTokenCache.get(accountId);

  /* This separate check is intentional and required. The cache-populating flow cannot have `awaits` in its path.
   Javascript async works by yielding execution to other pending tasks when it finds an `await`.
   If we `await` the fetching promise before saving it on the cache, a competing "thread" has the
   opportunity to call this method and bypass the cache */
  if (existingTokenFetchProcess) {
    if (isValid(await existingTokenFetchProcess)) return (await existingTokenFetchProcess).idToken;
  }

  const tokenFetchProcess = signInTenant(accountId);
  TenantTokenCache.set(
    accountId,
    tokenFetchProcess.then((token) => ({
      idToken: token.idToken,
      expiresAt: expiringAt ?? token.expiresAt,
    })),
  );
  try {
    return (await tokenFetchProcess).idToken;
  } catch (error) {
    TenantTokenCache.delete(accountId);
    throw error;
  }
};

export const clearTenantTokenCache = () => {
  TenantTokenCache.clear();
};
